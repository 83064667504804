Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.multippartFormContentType = "multipart/form-data"
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.PutAPIMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.formContentType = "multipart/form-data"
exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCategoryEndPoint = "bx_block_categories/categories";
exports.getAllSubCategoryEndPoint = "/bx_block_categories/sub_categories";
exports.createPostEndPoint = 'bx_block_profile/courses';
exports.profileGetURL = "bx_block_profile/profiles/show_profile";
exports.Category = "Category";
exports.Description = "Description";
exports.Price = "Price";
exports.ProductName = "Product Name";
exports.CreatePost = "Create Post";
exports.UpdatePost = "Update Post";
exports.Error = "Error";
exports.FieldsErrorMassage = "Please enter all mandatory fields";
exports.Warning = "Warning";
exports.MessageForDelete = "Are you sure for delete this post?";
exports.PostName = "Post Name";
exports.Cost = "Cost";
exports.PostID = "Post ID";
exports.Delete = "Delete";
exports.Edit = "Edit";
exports.NewPost = "Add new Post";
exports.PostUpdated = "Post is updated!";
exports.PostCreated = "Post is created!";
exports.PostSteps = ['Course Setting', 'Curriculum', 'Media', 'Final Test', 'Preview', 'Client Feedback']
exports.tagColors = [
  { id: "selectedGreen", color: "#059669" }, 
  { id: "selectedOrange", color: "#D97706" }, 
  { id: "selectedGselectedRedeen", color: "#B00606" }
]
exports.courseSettingInitialValues = {
  downloaded: false,
  course_name: "",
  course_description: "",
  image: "",
  video: "",
  category_id: -1,
  sub_category_id: null,
  time_for_supervisor_to_review: "",
  start_date: "",
  end_date: "",
  tags: [],
  selected_tag_color: ""
}
exports.chapterInitialValues = {
  title: "",
}
exports.lessonInitialValues = {
  title: "",
  sub_lessons_attributes: []
}
exports.subLessonInitialValues = {
  title: "",
  content: "",
  lesson_type: "",
  files: null,
  lesson_timer: "",
  optional_text: ""
}
exports.postQuizURL = "bx_block_profile/quiz";
// Customizable Area End