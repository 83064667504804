import React from "react";
// Customizable Area Start
import {
  Typography,
  IconButton,
  Divider,
  Chip as MuiChip,
  Box,
  FormControlLabel,
  styled,
  Grid,
  CardContent,
  InputBase,
  Radio,
  Paper,
  LinearProgress,
  CircularProgress,
  Modal
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { lightTheme, darkTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import BreadcrumbNavigationPage from "../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { hamburger, flower, nextButton, bg, sortIcon } from "./assets";

const CustomFormControlLabel = styled(FormControlLabel)({
  '& .MuiRadio-colorSecondary.Mui-checked svg': {
    fill: 'blue',
    transform: 'scale(0.8)',
  },
  '& .MuiRadio-colorSecondary.Mui-checked span div': {
    left: 2
  },
  '& .MuiRadio-colorSecondary span div': {
    left: 2
  },
  '& .MuiRadio-colorSecondary.Mui-checked span div svg:first-child': {
    background: '#1A469C',
    borderRadius: '50%'
  },
  '& .MuiRadio-colorSecondary.Mui-checked span div svg:nth-child(2)': {
    fill: 'white',
    background: '#1A469C',
    borderRadius: '50%',
  },
})
// Customizable Area End

import FilteritemsController, {
  Props,
} from "./FilteritemsController";

export default class Filteritems extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start



  renderChipSection = (title: any, filters: any, selectedChips: any, filterType: any) => (
    <>
      <Text3 variant="h6" style={{ fontWeight: 500, color: "#0F172A", fontSize: 16, fontFamily: "Rubik" }}>{title}</Text3>
      <Box display="flex" flexWrap="wrap">
        {filters?.map((filter: any, index: any) => (
          <StyledChip
            key={index}
            label={this.formatStatusFilter(filter)}
            className={selectedChips?.includes(filter) ? "selected" : ""}
            onClick={() => {
              this.handleChipClick(filter, filterType);
              this.handleBreadcrumbClick(filter);
              
            }}
            onDelete={
              selectedChips?.includes(filter)
                ? () => this.handleRemoveFilter(filterType, filter)
                : undefined
            }
            data-test-id={`chip-${filter}`}
          />
        ))}
      </Box>
    </>
  );

  renderCheckboxSection = (title: any, filters: any, selectedChips: any, filterType: any) => (
    <>
      <Text3 variant="h6" style={{ fontWeight: 500, color: "#0F172A", fontSize: 16, fontFamily: "Rubik" }}>{title}</Text3>
      <Box maxHeight="120px" overflow="auto" marginBottom="1rem" >
        {filters?.map((option: any, index: any) => (
          <div key={index} data-test-id={`radio-${option}`}>
            <CustomFormControlLabel
              key={index}
              control={
                <Radio
                  checked={selectedChips[0] === option}
                  onChange={() => {
                    this.handleRadioboxChange(option, filterType);
                  }}
                  value={option}
                  name="radio-buttons-group"
                  data-test-id={`radio-control-${option}`}
                />
              }
              label={option}
              data-test-id={`radio-label-${option}`}
            />
          </div>
        ))}
      </Box>
    </>
  );

  Sidebar = () => (
    <SidebarContainer style={{ padding: "1rem", borderRadius: "15px", marginRight: "15px", height: 'calc(100vh - 115px)' }}>
      <Text3 variant="body1" style={{ fontSize: "16px", display: 'flex', justifyContent: 'space-between'}}>
        <div>
        <img src={hamburger} alt="hamburger" data-test-id="hamburger-icon" />
        <span style={{ marginLeft: '10px', color: "#334155", fontFamily: "Rubik", fontWeight: 500, fontSize: 16 }}>Filter Menu</span>
        </div>
        {this.state.FiltersSelectes.length !== 0 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextColor variant="subtitle1" style={{ marginLeft: 8, color: "#DC2626", marginRight: 8, fontSize: "12px",fontWeight:500 }}>
                  <div onClick={this.handleReset} data-test-id="reset-filters-button">Reset</div>
                </TextColor>
              </div>
           )} 
      </Text3>
      <Divider style={{ margin: "1rem 0" }} />
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <div style={{ height: 'calc(100vh - 235px)', overflowY: 'scroll' }}>
          {this.renderChipSection("Course", this.state.allFilters.course, this.state.selectedChips.course, "course")}
          {this.renderChipSection("Status", this.state.allFilters.status, this.state.selectedChips.status, "status")}
          {(this.state.roleId === "instructor" || this.state.roleId === "supervisor") &&
            this.renderChipSection("Sort by", this.state.allFilters.sort, this.state.selectedChips.sort, "sort")}
          {this.state.roleId === "worker" &&
            <>
              {this.renderCheckboxSection("Category", this.state.allFilters?.category, this.state.selectedChips.category, "category")}
              {this.renderCheckboxSection("Sub-Category", this.state.allFilters?.subCategory, this.state.selectedChips.subCategory, "subCategory")}
            </>}
        </div>
        <SaveButton data-test-id="apply-filters-button" onClick={() => this.filterCourses(this.state.searchQuery)}>Apply Filters</SaveButton>
      </div>
    </SidebarContainer>
  );

  CourseList = () => (
    <div>
      <Grid container spacing={2} style={{ gap: '1rem' }}>
        {this.state.courses.map((item, index) => (
          <Grid item xs={12} key={index} style={{ height: '126px' }} data-test-id={`course-card-${index}`}>
            <div key={index} style={{
              display: "flex",
              alignItems: "center",
              boxShadow: "none"
            }}>
              <div style={{ fontWeight: "bold" }}>{index + 1}</div>
              <Container style={{
                gap: "16px",
                borderRadius: "18px",
                display: "flex",
                padding: 0,
                width: "100%",
                marginLeft: "10px",
                marginRight: '1rem'
              }}>
                <img
                  style={{ borderRadius: "18px", width: "50%", height: "126px" }}
                  src={item.image || flower}
                  data-test-id={`course-image-${index}`}
                />
                <CardContent style={{
                  flex: 1, height: '126px'
                }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text1 data-test-id={`course-name-${index}`}>Course Name</Text1>

                    <Text2>{item.course}</Text2>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text1 data-test-id={`group-name-${index}`}>Group Name</Text1>
                    <Text2>{item.group}</Text2>
                  </div>
                  {(this.state.roleId === "instructor" || this.state.roleId === "supervisor") &&
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Text1 data-test-id={`category-name-${index}`}>Instructor Name</Text1>
                      <Text2>Instructor Name </Text2>
                    </div>
                  }
                  {this.state.roleId === "worker" &&
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Text1 data-test-id={`category-name-${index}`}>Course Category / Sub-Category</Text1>
                      <Text2>{item.category} / {item.subCategory} </Text2>
                    </div>
                  }
                  {Boolean(item.percentage) &&
                    <Box position="relative" display="flex" alignItems="center">
                      <Box width="100%">
                        {item.status !== "completed" && 
                        <LinearProgressBar variant="determinate" value={item.percentage || 0}>
                        </LinearProgressBar>
                        }
                        {this.state.roleId === "instructor" &&
                          <Box
                            position="absolute"
                            top={-20}
                            right={10}
                          >
                            <Text2 variant="body2" color="textSecondary">
                              {item.percentage || 0}%
                            </Text2>
                          </Box>
                        }
                      </Box>
                    </Box>
                  }
                </CardContent>

              </Container>
              {this.state.roleId === "supervisor" && item.status !== "completed" &&
                <Box position="relative" display="inline-flex">
                  <CircularProgress
                    variant="determinate"
                    value={90}
                    size={30}
                    thickness={4}
                    style={{ color: 'rgba(26, 70, 156, 1)', width: '70.17px', height: '76.23px', transform: 'rotate(90deg)', }}

                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="caption" color="textSecondary" style={{
                      fontFamily: "Rubik",
                      fontSize: "15px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      color: 'rgba(15, 23, 42, 1)',
                    }}>
                      {`90%`}
                    </Typography>
                  </Box>
                </Box>
              }
              <CardContent style={{
                height: "126px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                gap: '6px'
              }}>
                {this.renderStatus(item, index)}
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                  <div>
                    {this.state.roleId === "worker" && item.status !== "completed" && 
                      <Text2 variant="body2" color="textSecondary">
                        <>{item.percentage || 0}%</>
                      </Text2>
                    }
                    {this.state.roleId === "instructor" &&
                      <div style={{ display: "flex", flexDirection: "column", width: '150px' }}>
                        <CreationText1 data-test-id={`course-name-${index}`}>Creation date</CreationText1>
                        <CreationText2>06/12/2024</CreationText2>
                      </div>
                    }
                  </div>
                  <div  onClick={this.handleNavigate}>
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={nextButton}
                    data-test-id={`course-next-button-${index}`}
                  />
                  </div>
                </div>
              </CardContent>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
  renderStatus = (item: any, index: number) => {
    if (item.status === "completed")
      return (<div>
        {item.mostRelavent && <Typography variant="body2" style={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px", color: "#334155" }} data-test-id={`course-relevance-${index}`}>
          Most Relevant
        </Typography>}
        <Completed data-test-id="completed">COMPLETED</Completed>
        <Typography variant="body2" style={{ color: "black" }} data-test-id={`course-progress-${index}`}>
        </Typography>
      </div>)

    else if (item.status === "expired")
      return (<div><Expired data-test-id="expired">Expired</Expired>
        <Typography variant="body2" style={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px", color: "#334155" }} data-test-id={`course-progress-${index}`}>
          Expiration Date <br /> <span style={{ fontWeight: 700, color: "#0F172A" }}>{item.endTime}</span>
        </Typography>
      </div>)

    else return (<div>
      <Inprogress data-test-id="inprogressSavetest">{this.formatStatus(item.status)}</Inprogress>
    </div>)

  };
  renderContent = () => (
    <div>
      {this.state.courses.length === 0 ? (
        this.renderEmpty()
      ) : (
        this.renderCourses()
      )}
    </div>
  )

  renderCourses = () => (
    <Content style={{ padding: "1rem", borderRadius: "50px" }}>
      <div>
        {this.CourseList()}
      </div>
      <PaginationBox>
        <PaginationBoxContent>
          {this.state.totalPage >= 2 && (
            <Pagination
              count={this.state.totalPage}
              onChange={this.changePage}
              data-test-id="pagination"
            />
          )}
        </PaginationBoxContent>
        <Box>
          <PageSizeBox onClick={this.togglePageDropdown}>
            <PageSizeTypography>Page Size</PageSizeTypography>
            <KeyboardArrowDownIcon height="5.5px" width='9.5px' />
            {this.state.isPageDropdownOpen && this.renderPageSize()}
          </PageSizeBox>
        </Box>
      </PaginationBox>
    </Content>
  )
  renderEmpty = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <NoSearchContent data-test-id="empty-content">
        <NoSearchTypography data-test-id="empty-no-search">
          "No Search Found"
        </NoSearchTypography>
        <NoSearchDesc data-test-id="empty-explore-text">
          Start exploring and your searches will appear here!
        </NoSearchDesc>
      </NoSearchContent>
    </div>
  )

  renderSort = () => {
    const options = [
      { label: "SORT BY", value: "" },
      { label: "Ascending", value: "Ascending" },
      { label: "Descending", value: "Descending" },
    ];

    return (
      <Dropdown style={{ height: "118px", zIndex: 100, top: 0, right: 0 }} data-test-id="sort-dropdown">
        {options.map((option) => (
          <SortText
            key={option.value}
            onClick={() => this.handleSort(option.value)}
            style={{
              borderRadius: "8px",
              backgroundColor: this.state.sortOrder === option.value ? "#E8EDF5" : "",
              color: this.state.sortOrder === option.value ? "#1A469C" : "#475569",
            }}
          >
            {option.label}
          </SortText>
        ))}
      </Dropdown>
    );
  };

  renderPageSize = () => {
    const options = [10, 25, 50, 100];
    return (
      <Dropdown style={{ height: "160px", width: '55px', zIndex: 100, top: -225, right: 0 }} data-test-id="page-size-dropdown">
        {options.map((option) => (
          <SortText
            key={option}
            onClick={() => this.handlePageSizeChange(option)}
            style={{
              borderRadius: '8px',
              backgroundColor: this.state.selectedPageSize === option ? "#E8EDF5" : "",
              color: this.state.selectedPageSize === option ? "#1A469C" : "#475569",
            }}
          >
            {option}
          </SortText>
        ))}
      </Dropdown>
    );
  };

  renderLoadingModal = () => {
    return (
      <Modal
        open={this.state.loading}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <ModalStyle>
          <CircularProgress
            value={90}
            size={30}
            thickness={4}
            style={{ color: 'rgba(26, 70, 156, 1)', width: '161px', height: '161px', transform: 'rotate(90deg)', }}
          />
        </ModalStyle>
      </Modal>
    )
  }


  // Customizable Area End

  render() {
    return (
      <div>
        {/* Customizable Area Start */}
        <ThemeProvider theme={this.state.selectedTheme ? darkTheme : lightTheme}>
          <NavigationMenu navigation={this.props.navigation} id={""} open={this.state.open} filter="filter" theme={this.state.selectedTheme} />
          <Container>
            <div style={{ marginTop: "25px", display: "flex" }}>
              <div style={{ width: "20%", }}>
                {this.Sidebar()}
              </div>
              <SidebarContainer style={{ height: "100%", borderRadius: "15px", width: "80%" }}>
                <div style={{ display: "flex", height: '75px', alignItems: 'center', justifyContent: 'space-between', padding: '0 2rem 1rem 0' }}>
                  <div style={{ display: "flex", width: "25%", height: '100%', paddingTop: "10px", borderBottomRightRadius: "15px", backgroundColor: '#FFFFFF', alignItems: 'center' }}>
                    <BreadcrumbNavigationPage
                      breadcrumbs={['Dashboard', 'Current page']}
                      gotoPage={this.navigatetoPage.bind(this)}
                    />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%', width: '75%', alignItems: 'flex-end' }}>
                    <div>
                      <Search data-test-id="search-container">
                        <SearchIconWrapper data-test-id="search-icon-wrapper">
                          <SearchIcon data-test-id="search-icon" />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search"
                          inputProps={{ "aria-label": "search" }}
                          onChange={(event) => this.handleInputChange(event)}
                          data-test-id="search-input"
                          value={this.state.searchQuery}
                        />
                      </Search>
                    </div>
                    <div style={{ display: 'flex', alignItems: "center", gap: '2rem' }}>
                      <AdvanceSearch>
                        Advanced Search
                      </AdvanceSearch>
                      <AdvanceSearchWapper onClick={this.toggleDropdown}>
                        <img src={sortIcon} width="31.2px" height="31.2px" alt="hamburger" data-test-id="sort-icon" />
                        {this.state.isDropdownOpen && this.renderSort()}
                      </AdvanceSearchWapper>
                    </div>
                  </div>
                </div>
                {this.renderContent()}
              </SidebarContainer>
            </div>
          </Container>
          {this.renderLoadingModal()}
        </ThemeProvider>
        {/* Customizable Area End */}
      </div>
    );
  }
}

// Customizable Area Start

const Dropdown = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 220,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  textAlign: 'left',
  marginTop: "50px"
}));

const PageSizeBox = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: '95px',
  height: '29px',
  borderRadius: "30px",
  padding: '12px 8px',
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  textAlign: 'left',
  color: 'rgba(71, 85, 105, 1)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const PageSizeTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color: 'rgba(71, 85, 105, 1)'
}));

const StyledChip = styled(MuiChip)(({ theme }) => ({
  textTransform: "capitalize",
  margin: "10px 10px 10px 0px",
  "&.selected": {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.primary.main
  },
  "&:not(.selected)": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.action.selected,
  },
  "& .MuiChip-deleteIcon": {
    display: "none",
  },
  "&.selected .MuiChip-deleteIcon": {
    display: "block",
    color: theme.palette.primary.main
  },
}));

const Content = styled('div')(({ theme }) => ({
  // backgroundColor: theme.palette.primary.main,
}))

const TextColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.selected,
  cursor: "pointer"
}))

const StyledIconButton = styled(IconButton)({
  padding: 10,
});

const ModalStyle = styled('div')(({ theme }) => ({
  width: '212.44px',
  height: '212.44px',
  backgroundColor: 'rgba(249, 249, 249, 1)',
  borderRadius: '17px',
  display: 'flex',
  justifyContent:'center',
  alignItems: 'center',
}))

const Text1 = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "11px",
  color: theme.palette.info.light
}))

const TextStyle = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "21px",
  color: '#334155'
}))

const Text2 = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color: theme.palette.info.main
}))

const CreationText1 = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "13.8px",
  fontWeight: 500,
  lineHeight: "20.71px",
  color: theme.palette.info.light
}))

const CreationText2 = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16.1px",
  fontWeight: 700,
  lineHeight: "20.71px",
  color: theme.palette.info.main
}))

const Text3 = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}))

const SortText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  height: "40px",
  paddingLeft: "16px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  display: 'flex',
  alignItems: 'center',
  "&:hover": {
    borderRadius: '8px',
    backgroundColor: "#E8EDF5",
  },
  color: theme.palette.info.light
}))

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '0 36.45px 15.79px 33.5px',
}))

const SaveButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.action.active,
  border: "none",
  color: theme.palette.text.secondary,
  borderRadius: "30px",
  padding: "6px 10px 6px 10px",
  cursor: "pointer",
  width: "100%",
  height: "32px",
  fontSize: "16px",
  fontWeight: "bold",
  marginTop: '1rem',
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.primary.main,
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: "relative",
  borderRadius: "50px",
  backgroundColor: theme.palette.background.default,
  cursor: "pointer",
  "&:hover": {
    border: `2px solid ${theme.palette.action.selected}`,
  },
  marginRight: "16px",
  marginLeft: 0,
  width: "100%",
  "@media (min-width:600px)": {
    marginLeft: "24px",
    width: "auto",
  },
  color: "rgb(140, 140, 140)",
  border: "1px solid #CBD5E1",
}));

const SearchIconWrapper = styled("div")({
  padding: "0 16px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "gray"
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  paddingLeft: "50px",
  paddingTop: "4px",
  paddingBottom: "4px",
  '&::placeholder': {
    color: theme.palette.primary.main
  },
}));

const SidebarContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.action.focus,
}));

const CustomRadiobox = styled(Radio)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    width: "1em",
    height: "1em",
    borderRadius: "50%",
  },
  color: theme.palette.action.selected,
  "&.Mui-checked": {
    color: theme.palette.action.selected,
  },
}));

const PaginationBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "29px",
  marginTop: "2rem",
  marginBottom: "1rem",
  display: "flex",
  justifyContent: "center",
  opacity: "0px",
}));
const PaginationBoxContent = styled(Box)(({ theme }) => ({
  width: '80%',
  display: 'flex',
  justifyContent: 'center',
  '& .MuiPagination-root': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '18px'
  },
  '& .MuiPagination-ul': {
    gap: '1rem'
  },
  '& .MuiPaginationItem-root': {
    borderRadius: '8px',
    backgroundColor: 'rgba(241, 245, 249, 1)',
    color: 'rgba(67, 83, 106, 1)',
    minWidth: '18px',
    height: '18px',
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    borderRadius: '8px',
    backgroundColor: theme.palette.action.selected,
    color: 'rgba(248, 250, 252, 1)',
    minWidth: '18px',
    height: '18px',
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "18px",
  },

}));
const NoSearchContent = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 50px top 30px',
  width: "734.31px",
  height: "372px",
  gap: "10px",
  borderRadius: "4.97px",
  opacity: "0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "left",
  margin: "30px",
}));
const NoSearchTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Kallisto !important",
  fontSize: "32.25px",
  fontWeight: 700,
  lineHeight: "43px",
  letterSpacing: "-0.005em",
  textAlign: "left",
  color: theme.palette.text.primary,
  gap: "0px",
  opacity: "0px",
}))
const NoSearchDesc = styled(Typography)(({ theme }) => ({
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "21.1px",
  fontWeight: 400,
  lineHeight: "30.1px",
  textAlign: "left",
  color: theme.palette.text.primary
}))

const Inprogress = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FEF3C7',
  color: '#D97706',
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "13.55px",
  textAlign: "left",
  padding: '8px 12px',
  whiteSpace: 'nowrap',
  height: "34px",
  gap: "7.53px",
  borderRadius: "40px",
  opacity: "0px"
}))
const Completed = styled(Typography)(({ theme }) => ({
  backgroundColor: '#D1FAE5',
  color: '#059669',
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  width: "208x",
  height: "34px",
  padding: "8px 16px 8px 16px",
  gap: "7.53px",
  borderRadius: "40px",
  opacity: "0px"
}))

const Expired = styled(Typography)(({ theme }) => ({
  backgroundColor: '#FEE2E2',
  color: '#DC2626',
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  marginBottom: 10,
  lineHeight: "18px",
  textAlign: "left",
  width: "208x",
  height: "34px",
  padding: "8px 16px 8px 16px",
  gap: "7.53px",
  borderRadius: "40px",
  opacity: "0px"
}))

const AdvanceSearch = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "40px",
  textAlign: "center",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155"
}))
const AdvanceSearchWapper = styled('div')(({ theme }) => ({
  display: "flex", height: "50px", textAlign: "right", borderBottomRightRadius: "15px",
  justifyContent: "space-between",
  width: "auto",
  marginLeft: 'auto',
  gap: "40px",
  cursor: "pointer",
  alignItems: 'center',
  position: "relative",
}));
const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 4.4,
  borderRadius: 9.09,
  background: "#E2E8F0",
  [`& .MuiLinearProgress-colorPrimary`]: {
    backgroundColor: theme.palette.secondary.main,
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.action.selected,
  },
}));

// Customizable Area End