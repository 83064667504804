import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ApiCallInterface, AssessmentItemInterface, AttemptedAssessmentInterface } from './IAssessment'
import StorageProvider from "../../../framework/src/StorageProvider";
import { getStorageData } from "../../../framework/src/Utilities";
import React, { ChangeEvent } from "react";
import * as Yup from "yup";

interface Choice {
  id:string;
  content: string;
  is_correct: boolean;
}

interface MediaUrls{
  id:string;
  url:string;
}

interface Question {
  id:string;
  quiz_id:string;
  content: string;
  description: string;
  question_type: string;
  choices_attributes: Choice[];
  media_files_urls:MediaUrls[];
  media_files:File[];
}

interface Quiz {
  title: string;
  passing_grade: string;
  course_id: number;
  questions_attributes: Question[];
}

export const QuestionSchema = Yup.object({
  content: Yup.string().trim().min(1, 'This field is required'),
  description: Yup.string().nullable(),
  question_type: Yup.string().nullable().default(""),
  choices_attributes: Yup.mixed().default([])
});
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  courseId:number;
  onSaveAndClose:(values: any) => void; 
  onAddNewLesson:(values: any) => void; 
  quiz_id:string;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  assessmentList: AssessmentItemInterface[],
  assessmenttestList: AssessmentItemInterface[],
  isError: boolean;
  assessmentCategories: AssessmentItemInterface[];
  isSuccess: boolean;
  selectedCategory: string | undefined;
  selectedId: string | number | undefined;
  tabNum: number
  attemptedAssessment: AttemptedAssessmentInterface[];
  isLoading: boolean;
  darkTheme:boolean;
  openQuizSection:boolean;
  quizTabs:number;
  quizTitle:string;
  passingGrade:string;
  courseId:number;
  allQuestions:Question[];
  selectedQuestionType:string;
  fileData:{fileName:string,filesize:number,fileType:string};
  uploadCSV:Blob|null;
  expandedQuestionIndex: number | null;
  isEdittingQuestion: number | null;
  successModalOpen:boolean;
  showMedia:boolean;
  isRequired:boolean;
  randomQuestion:string;
  currentQuestionIndex:number;
  question_count:number;
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAssessmentCategories: string = "";
  apiGetAssessmentByCategory: string = "";
  apiAttemptedAssessment: string = "";
  addQuizPostAPICallId:string="";
  updateQuizPostAPICallId:string="";
  getQuizPostAPICallId:string="";
  addImportFileApiCallId:string="";
  quillRef:any="";
  fileRef:any="";
  questionFormikRef: any;
  deleteApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      assessmentList: [],
      assessmenttestList: [],
      isError: false,
      isLoading: true,
      isSuccess: false,
      assessmentCategories: [],
      selectedCategory: '',
      selectedId: '0',
      tabNum: 1,
      attemptedAssessment: [],
      darkTheme:false,
      openQuizSection:false,
      quizTabs: 2,
      quizTitle:"",
      passingGrade:"",
      courseId:0,
      allQuestions:[],
      selectedQuestionType:"Question Type",
      fileData:{
        fileName:"",
        filesize:0,
        fileType:""
      },
      uploadCSV:null,
      expandedQuestionIndex: null,
      isEdittingQuestion: null,
      successModalOpen:false,
      showMedia:false,
      isRequired:false,
      randomQuestion:"",
      currentQuestionIndex:0,
      question_count:0,
    }
    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    this.quillRef = React.createRef();
    this.fileRef = React.createRef();
    this.questionFormikRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


// Customizable Area Start
  async componentDidMount() {
    this.getAssessmentCategoriesApi()

    this.getAttemptedAssessmentApi()
    this.checkDarkMode()
    this.getQuiz(this.props.quiz_id)
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.assessmentCategories !== this.state.assessmentCategories) {
      this.setState({
        selectedId: `${this.state?.assessmentCategories![0]?.id}`
      })
    }

  }

  apiAssessmentCall = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = data;
    let token = await StorageProvider.get("USER_TOKEN")
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiStatement(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleApiStatement(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (responseJson && responseJson.data) {
      if (apiRequestCallId === this.apiGetAssessmentByCategory) {
        this.getAssessmentByCategorySuccesscallBack(responseJson.data);
      }
      else if (apiRequestCallId === this.apiGetAssessmentCategories) {
        this.getCatSuccesscallBack(responseJson.data,)
      }
      else if (apiRequestCallId === this.apiAttemptedAssessment) {
        this.getAttemptedAssessmentApiSuccesscallBack(responseJson.data)
      }
      else if (apiRequestCallId === this.addQuizPostAPICallId) {
        this.getAttemptedAssessmentApiSuccesscallBack(responseJson.data)
        let values = {
          title: "quiz", content: '', optional_text:responseJson.data.id,
        }
        if (this.props.onSaveAndClose) {
          this.props.onSaveAndClose(values);
        }
        this.handleQuizSection()
      }
      else if (apiRequestCallId === this.updateQuizPostAPICallId) {
        let values = {
          title: "quiz", content: '', optional_text:this.props.quiz_id,
        }
        if (this.props.onSaveAndClose) {
          this.props.onSaveAndClose(values);
        }
      }
      else if (apiRequestCallId === this.getQuizPostAPICallId) {
        const allQuestions: Question[] = this.transformQuestions(responseJson.data.attributes.questions.data);
        this.setState({
          allQuestions: allQuestions,
            openQuizSection: true,
            quizTabs: 0,
            passingGrade:responseJson.data.attributes.passing_grade,
            randomQuestion:responseJson.data.attributes.random_question,
            isRequired:responseJson.data.attributes.is_required,
            question_count:responseJson.data.attributes.question_count
        });
      }      
    }  else if (errorReponse) {
      this.setState({
        isLoading: false,
        
        isSuccess: false,
        isError: true,
      })
    } else if(responseJson && responseJson.message || responseJson.quiz){
        if (apiRequestCallId === this.deleteApiCallId) {
          this.setState({
            expandedQuestionIndex: null, allQuestions: [], fileData: {
              fileName: '',
              filesize: 0,
              fileType: '',
            }
          })
        }
        else if (apiRequestCallId === this.getQuizPostAPICallId) {
          this.setState({allQuestions:[]})
        }
        else if (apiRequestCallId === this.addImportFileApiCallId) {
          const allQuestions: Question[] = responseJson.question_attributes.flatMap((question: any) => 
            ([
              {
                media_files_urls:[],
                id: question.id || "", 
                content: question.content || "",
                description: question.description || "",
                question_type: question.question_type || "",
                choices_attributes: (question.choices_attributes || []).map((choice: any) => ({
                  id: choice.id || "",
                  content: choice.content || "",
                  is_correct: choice.is_correct || false,
                })), 
              }
            ])
          );
      
          this.setState(prevState => ({
            allQuestions: [...prevState.allQuestions, ...allQuestions], 
            openQuizSection: true,
            quizTabs: 0,
            expandedQuestionIndex: null,
          }));
        }      
      }    
  }

  transformQuestions(questions: any | any[]): Question[] {
    const questionArray = Array.isArray(questions) ? questions : [questions];
    return questionArray.map((question: any) => ({
      quiz_id: question.attributes.quiz_id,
      media_files_urls:question.attributes.media_files_urls?.map((file:any) => ({
        id:file.id,
        url:file.url
      })),
      media_files:[],
      id:question.attributes.id,
      content: question.attributes.content,
      description: question.attributes.description || "",
      question_type: question.attributes.question_type || "",
      choices_attributes: question.attributes.choices.data.map((choice: any) => ({
        id: choice.attributes.id,
        content: choice.attributes.content,
        is_correct: choice.attributes.is_correct,
      })),
    }));
  }

  getAssessmentCategoriesApi = async () => {
    this.apiGetAssessmentCategories = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAssessementTestCategoriesUrl,
    });
  };
  getAssessmentByCategoryApi = async () => {
    this.apiGetAssessmentByCategory = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAllAssessementTestUrl,
    });
  };
  getAttemptedAssessmentApi = async () => {
    this.apiAttemptedAssessment = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAttemptedAssessmentsUrl,
    });
  };

  getAssessmentByCategorySuccesscallBack =  (data: AssessmentItemInterface[]) => {
    let filterData = data.filter((item: AssessmentItemInterface) => {
      {
        return item.attributes?.assessment_type === this.state.selectedCategory
      }
    })
    this.setState({
      isLoading: false,
      isError: false,
      isSuccess: true,
      assessmentList: data,
      assessmenttestList: filterData
    })
  };

  getCatSuccesscallBack =  (data: AssessmentItemInterface[]) => {
    this.setState({
      assessmentCategories: data,
      selectedCategory: data[0].attributes?.name,
      selectedId: data[0].id
    },
      () => {
        this.getAssessmentByCategoryApi()
      }
    )
  };


  getAttemptedAssessmentApiSuccesscallBack =  (data: AttemptedAssessmentInterface[]) => {
    this.setState({
      attemptedAssessment: data
    })
  };

  
  apiCall = async (data: { [key: string]: any }) => {
    const { method2, endPoint2, body2, type2, contentType2 } = data;
    let apiBody = body2;
    if (type2 === '') {
      apiBody = JSON.stringify(body2);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": contentType2,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method2
    );
    body2 &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };


  handleDeleteQuiz =  async () =>{
    this.setState({
      expandedQuestionIndex: null, allQuestions: [], fileData: {
        fileName: '',
        filesize: 0,
        fileType: '',
      }
    })
  }


  handleDeleteQuestion =  async (quiz_id:string, id:string) =>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postQuizURL + "/" + `${quiz_id}` + "/delete_question/" + `${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteQuestion = (index: number, quiz_id:string, id:string, event:any) => {

    if (event) {
      event.stopPropagation();
    }

    if(id){
      this.handleDeleteQuestion(quiz_id,id)
    }else{
      this.setState(prevState => ({
        allQuestions: prevState.allQuestions.filter((_, i) => i !== index)
      })); 
    }
 
  }

  stopPropagration = (event:any) =>{
    if (event) {
      event.stopPropagation();
    }
  }

  getQuiz = async (id:string) => {
    const header = {
      token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuizPostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postQuizURL}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssessementMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  sendFile=async()=>{
     
    const token = await getStorageData("authToken"); 

    let data = new FormData();
    data.append("file",this.state.uploadCSV as Blob);
    data.append("course_id", this.props.courseId.toString());

    this.addImportFileApiCallId = await this.apiCall({
      method2: configJSON.postMethod,
      endPoint2: configJSON.postQuizImportURL,
      body2: data,
      headers2: { 
        token 
      },
    });
  }
  onSelected(item: AssessmentItemInterface) {
    this.props.navigation.navigate('AssessmentDetail', { id: item.id })
  }
  onAttemptedSelected(item: AttemptedAssessmentInterface) {
    this.props.navigation.navigate('AssessmentResult', { id: item.attributes?.assessment_id })
  }

  onReFetch() {
    this.setState({
      isLoading: true,
      isError: false,
    }, () => {
      this.getAssessmentCategoriesApi()
    }

    )
  }

  convertMinutesToHoursAndMinutes(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let result = hours + " hour";
    if (hours !== 1) {
      result += "s";

    }
    result += remainingMinutes + " minute";
    if (remainingMinutes !== 1) {
      result += "s";
    }
    return result;
  }


  onTabClick(numInput: number) {
    this.setState({
      tabNum: numInput
    })
  }
  handleCatSelected(item: AssessmentItemInterface) {
    this.setState({ selectedId: `${item?.id}` })
    let list = this.state.assessmentList?.filter((newItem: AssessmentItemInterface) => {
      return newItem?.attributes?.assessment_type === item.attributes?.name
    })

    this.setState({
      assessmenttestList: list
    })
  }

  handleSearch = (text: string) => {
    if (text.trim() === '') {
      let first = this.state.assessmentList.filter((item: AssessmentItemInterface) => {
        {
          return item?.attributes?.assessment_type === this.state.selectedCategory
        }
      })

      this.setState({
        assessmenttestList: first
      })
    } else {
      const filtered = this.state.assessmenttestList.filter((item: AssessmentItemInterface) => {
        const name = item.attributes?.name?.toLowerCase() ?? 'Unknown';
        return name?.includes(text.toLowerCase())
      });
      this.setState({
        assessmenttestList: filtered
      })
    }
  }
  checkDarkMode=()=>{
    let mode = JSON.parse(localStorage.getItem('darkMode')!);
    if(mode)
      this.setState({
        darkTheme:mode
      })
  }
  unCompletedListAssessment(){
    let unCompletedList: AssessmentItemInterface[] = this.state.assessmenttestList.filter((item) => item.attributes?.is_completed !== true)
    return unCompletedList
  }
  uploadCSVQuizFile=(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    if(this.fileRef.current){
      this.fileRef.current.click();
    }
  }
  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ fileData:{
        fileName:file.name,
        filesize:file.size,
        fileType:file.type
      },uploadCSV:file });
    }
  };
  handleQuizSection= () => {
    this.setState({
      openQuizSection: true,
      quizTabs: 0,
    },() => this.handleAddQuestions())
  }

  buildFormData = (values: Question[]) => {
    let formData = new FormData();
  
    formData.append("quiz[title]", "Quiz");
    formData.append("quiz[course_id]", this.props.courseId.toString());
    formData.append("quiz[is_required]", this.state.isRequired.toString());
    formData.append("quiz[random_question]", this.state.randomQuestion.toString());
    formData.append("quiz[passing_grade]", this.state.passingGrade);
  
    values.forEach((question, index) => {
      formData.append(`quiz[questions_attributes][${index}][id]`, question.id);
      formData.append(`quiz[questions_attributes][${index}][content]`, question.content);
      formData.append(`quiz[questions_attributes][${index}][description]`, question.description);
      formData.append(`quiz[questions_attributes][${index}][question_type]`, question.question_type);
  
      question.choices_attributes.forEach((choice, choiceIndex) => {
        formData.append(`quiz[questions_attributes][${index}][choices_attributes][${choiceIndex}][id]`, choice.id);
        formData.append(`quiz[questions_attributes][${index}][choices_attributes][${choiceIndex}][content]`, choice.content);
        formData.append(`quiz[questions_attributes][${index}][choices_attributes][${choiceIndex}][is_correct]`, choice.is_correct.toString());
      });
  
      if (question.media_files) {
        question.media_files.forEach((file,mediaIndex) => {
          formData.append(`quiz[questions_attributes][${index}][media_files][${mediaIndex}]`, file);
        });
      }
    });
    return formData;
  }
  
  handleCallBack = async () => {
    const formData = this.buildFormData(this.state.allQuestions);
    const token = await getStorageData("authToken");
  
    const apiCallConfig = {
      method2: this.props.quiz_id ? configJSON.putAssessementMethod : configJSON.postMethod,
      endPoint2: this.props.quiz_id ? `${configJSON.postQuizURL}/${this.props.quiz_id}` : configJSON.postQuizURL,
      body2: formData,
      headers2: {
        token
      },
    };
  
    const apiCallId = this.props.quiz_id ? "updateQuizPostAPICallId" : "addQuizPostAPICallId";
    this[apiCallId] = await this.apiCall(apiCallConfig);
  }
  
  handleCallBack2= () => {
    let values = {
      title: "quiz", content: '',
    }
    if (this.props.onAddNewLesson) {
      this.props.onAddNewLesson(values);
    }
  }
  handleQuizTabs = (num: number) => {
    this.setState({
      quizTabs:num,
      fileData: {
        fileName: '',
        filesize: 0,
        fileType: '',
      }
    })
  }
  handleAddQuestions = () => {
    const newQuestion: Question = {
      id:'',
      quiz_id:'',
      content: '',
      description: '',
      question_type: '',
      choices_attributes: [ ],
      media_files:[ ],
      media_files_urls:[ ],
    }
    this.setState(prevState => ({
      allQuestions: [...prevState.allQuestions, newQuestion],
      expandedQuestionIndex: prevState.allQuestions.length
    }))
  }
  handleChangeAccordionExpanding = (index: number) => (_event: ChangeEvent<{}>, newExpanded: boolean) => {
    this.setState({ expandedQuestionIndex: newExpanded ? index : null })
  };
  handleCopyQuestion = (index: number) => (event: { stopPropagation: () => void; }) => {
    event.stopPropagation();

    this.setState(prevState => {
      const copiedQuestion: Question = { 
        ...prevState.allQuestions[index], 
        id: "", 
        quiz_id:"",
        choices_attributes: prevState.allQuestions[index]?.choices_attributes?.map(choice => ({
          ...choice,
          id: "" 
        }))
      };
  
      return {
        allQuestions: [...prevState.allQuestions, copiedQuestion],
        expandedQuestionIndex: prevState.allQuestions.length
      };
    });
  }


  handleQuestionAddition = (event: any, index: number) => {
    let newQuestions = [...this.state.allQuestions];
    let updatedQuestion = event?.target?.value;
  
    if (newQuestions[index] && newQuestions[index].content !== updatedQuestion) {
      newQuestions[index].content = updatedQuestion;
      this.setState({ allQuestions: newQuestions });
    }
  }

  handlePassingGrades = (value:string) =>{
    this.setState({passingGrade:value})
  } 
  
  handleQuestionDescAddition=(value:string, index:number)=>{
    let newQuestions = this.state.allQuestions;
    let updatedQuestion = value;
    newQuestions[index].description=updatedQuestion;
    this.setState({
      allQuestions:newQuestions
    })
  }
  handleChoiceAddition = (value: string, index: number, content_index: number) => {
  let newContent = [...this.state.allQuestions];
  let updatedContent = value;

  if (newContent[index] && newContent[index].choices_attributes) {
    let updatedChoices = [...newContent[index].choices_attributes];

    if (updatedChoices[content_index]) {
      updatedChoices[content_index].content = updatedContent;
    }

    newContent[index].choices_attributes = updatedChoices;
    
    this.setState({
      allQuestions: newContent,
    });
  }
};

  handleAnswerAddition=(event:any,index:number,content_index:number)=>{
    let newAnswers = [...this.state.allQuestions];

  if (newAnswers[index] && Array.isArray(newAnswers[index].choices_attributes)) {

    if (newAnswers[index]?.question_type === "single_choice") {
      newAnswers[index].choices_attributes.forEach(choice => {
        choice.is_correct = false;
      });
    }

   if (newAnswers[index].choices_attributes[content_index]) {
      newAnswers[index].choices_attributes[content_index].is_correct = 
        !newAnswers[index].choices_attributes[content_index].is_correct;
      this.setState({
        allQuestions: newAnswers
      });
    }
  }
  }
  handleNewOptionAddition=(index:number)=>{
    this.setState((prevState)=>{
      let updatedOptions = [...prevState.allQuestions];
      updatedOptions[index]?.choices_attributes?.push({id:'',content:"",is_correct:false});
      return {allQuestions:updatedOptions}
    })
  }
  handleQuestionTypeChange=(event:any,index:number)=>{
    let newQuestionType = this.state.allQuestions;
    newQuestionType[index].question_type=event.target.value;
    this.setState({
      allQuestions:newQuestionType
    })
   
  }
  handleUpdateQuestion = () => {
    this.setState({expandedQuestionIndex: null})
  }

  handleRemoveFile = () => {
    this.setState({
      fileData: {
        fileName: '',
        filesize: 0,
        fileType: '',
      }
    });
  };
  closeSuccessModal = () => {
    this.setState({ successModalOpen: false })
  }
  closeSuccessModal2 = () => {
    this.setState({ successModalOpen: false, showMedia:true })

  }
  openSuccess = () =>{
    this.setState({ successModalOpen: true })
  }
  setFieldValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(event.target.files || []);
    const validFiles = files.filter(file => file.type.startsWith("image/") || file.type.startsWith("video/"));
  
    this.setState(prevState => {
      let totalFiles = 0;
      prevState.allQuestions.forEach(question => {
        totalFiles += question.media_files?.length;  
      });
  
      totalFiles += validFiles.length;  
  
      if (totalFiles > 4) {
        alert("You cannot select more than 4 files.");
        return { ...prevState };  
      }
  
     const updatedQuestions = prevState.allQuestions.map(question => {
        return {
          ...question,
          media_files: [...(question.media_files || []), ...validFiles],
        };
      });
  
      return { allQuestions: updatedQuestions };
    });
  
    event.target.value = "";  
  };
  
  handleBrowseClick = () => {
    const fileInput = document.getElementById("uploadedMedia");
    if (fileInput) {
      fileInput.click();
    }
  };

  handleRemoveMediaFile = (questionIndex: number, fileIndex: number) => {
    this.setState(prevState => {
      const updatedQuestions = [...prevState.allQuestions];
      const currentQuestion = updatedQuestions[questionIndex];
  
      if (currentQuestion.media_files && currentQuestion.media_files[fileIndex]) {
        currentQuestion.media_files = currentQuestion.media_files.filter((_, i) => i !== fileIndex);
      }
  
      if (currentQuestion.media_files_urls && currentQuestion.media_files_urls[fileIndex]) {
        currentQuestion.media_files_urls = currentQuestion.media_files_urls.filter((_, i) => i !== fileIndex);
      }
  
      return { allQuestions: updatedQuestions };
    });
  };
  
  

  requiredQuiz = (e:any) =>{
    this.setState({isRequired:e.target.checked})
  }

  handleRandomQuestionNumber = (value: string) => {
    this.setState({ randomQuestion: value });
  };

  // Customizable Area End
}
