// Customizable Area Start
import React, { Component, createRef } from 'react';

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  styled,
  InputAdornment,
  Grid, TextField, Badge, Paper, Divider,
  Modal,
  Menu, MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  IconButton,
  LinearProgress,
  Slider
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
  VideoLibrary as VideoIcon,
  Description as TextIcon,
  InsertDriveFile as PdfIcon,
  Image as MediaIcon,
} from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {AudioPauseButton, AudioPlayButton, PptImage, CommentButton, ExpandOut, profileImage, FileDownload, ImageNotes, Vector, playPauseButton, AutoplayOff, AutoplayOn, Replay, PauseButton, Volume, previousButton, nextButton, expand, minimiseIcon, courseVideo, playIcon, downloadIcon, videoRounded, audioButton, Audio, Pdf, textImage } from "./assets";
import { NotesData, Note } from "./ProjectnotesController";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PauseIcon from '@material-ui/icons/Pause';
import ReactQuill from 'react-quill';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import ProjectnotesController, {
  Props,
  configJSON,
} from "./ProjectnotesController";
import { borderRadius } from "react-select/src/theme";

export default class Projectnotes extends ProjectnotesController {
  constructor(props: Props) {
    super(props);
  }

   getVideoPlaying = () => {
    return this.state.isPlaying ? (
      <img src={PauseButton} style={{ width: '24px', height: '24px' }} />
    ) : (
      <img src={playPauseButton} style={{ width: '24px', height: '24px' }} />
    );
  };

  getCurrentVideoElement = (currentVideo: any, videoChapters: any, isExpanded: any) => {
    return   <video
    ref={this.videoRef}
    data-test-id= "currentVideo"
    src={currentVideo?.src}
    onTimeUpdate={this.handleTimeUpdate}
    onLoadedMetadata={this.handleLoadedMetadata}
    onEnded={() => this.handleVideoEnded(videoChapters)}
    controls={false}
    style = {{
      position: isExpanded ? "fixed" : "relative",
  top: isExpanded ? 0 : "auto",
  left: isExpanded ? 0 : "auto",
  width: isExpanded ? "100vw" : "100%",
  height: isExpanded ? "100vh" : "calc(100% - 100px)",
  objectFit: "cover",
  borderRadius: isExpanded ? "0px" : "18px",
  zIndex: isExpanded ? 1000 : "auto",
    }}
  />
  }

  getCurrentVideoBox = (currentTime: any, duration: any, isExpanded: any, showControls: any, videoChapters: any) => {
    return  <Box style={{
      position: isExpanded ? "fixed" : 'absolute',
      width: isExpanded ? "100%" : "calc(100% - 2rem)", 
      bottom: isExpanded ? "0" : "1rem", 
      left: isExpanded ? "0" : "1rem", 
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      display: showControls ? "flex" : "none",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottomLeftRadius: isExpanded ? "0px" : "18px", 
      borderBottomRightRadius: isExpanded ? "0px" : "18px", 
      zIndex: isExpanded ? 1001 : "auto", 
    }} >
    <input
      type="range"
      min="0"
      max="100"
      value={(currentTime / duration) * 100 || 0}
      onChange={this.handleProgressChange}
      style={{
        flexGrow: 1,
        margin: "0 15px",
        height: "4px",
        background: `linear-gradient(to right, rgba(26, 70, 156, 1) ${((currentTime / duration) * 100) || 0}%, rgba(255, 255, 255, 0.3) ${((currentTime / duration) * 100) || 0}%)`,
        cursor: "pointer",
        appearance: "none",
        width: '100%'
      }}
    />

    <Box style={{display: 'flex', justifyContent: 'space-between', width: '100%', height: '44px'}}>
      <Box style={{display: 'flex', alignItems: 'center'}}>
  {this.state.videoCompleted && <img onClick={this.handleReplay} src={Replay} style={{width: '24px', height: '24px'}}/>}  
    <img data-test-id="previousVideo" onClick={() => this.handlePreviousVideo(videoChapters)} src={previousButton} style={{width: '24px', height: '24px'}}/>
      <button
        onClick={this.togglePlay}
        data-test-id="togglePlay"
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          color: "white",
        }}
      >
    {this.getVideoPlaying()}
      </button>

      <img data-test-id= "nextVideo" onClick={() => this.handleNextVideo(videoChapters)} src={nextButton} style={{width: '24px', height: '24px'}}/>   
     
      <Box
    onClick={this.handleVolumeClick}
    onMouseEnter={this.handleVolMouseEnter}
  >
    <img src={Volume} style={{width: '18px', height: '18px', marginRight: '6px'}}/> 
  </Box>
      
  {this.state.showVolumeBar && (
    <>
    <input
      type="range"
      id="volume-slider"
      min="0"
      max="1"
      step="0.01"
      value={this.state.volume}
      onChange={this.handleVolumeChange}
      style={{
        width: "52px",
        height: "2px",
        background: `linear-gradient(to right, rgba(248, 250, 252, 1) ${this.state.volume * 100}%, rgba(249, 249, 249, 0.2) ${this.state.volume * 100}%)`,
        borderRadius: "4px",
        outline: "none",
        appearance: "none",
        cursor: "pointer",
      }}
    />
      <style>
{`
  /* Target specific slider using its ID */
  #volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 6px;
    height: 6px;
    background: rgba(248, 250, 252, 1);
    border-radius: 50%;
    border-color: rgba(248, 250, 252, 1);
    cursor: pointer;
  }

  #volume-slider::-moz-range-thumb {
    width: 6px;
    height: 6px;
    background: rgba(248, 250, 252, 1);
    border-radius: 50%;
    border-color: rgba(248, 250, 252, 1);
    cursor: pointer;
  }

  #volume-slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    background: transparent;
    border: none;
    border-radius: 4px;
  }

  #volume-slider::-moz-range-track {
    width: 100%;
    height: 2px;
    background: transparent;
    border: none;
    border-radius: 4px;
  }
`}
</style>
    </>
    
  )}

       <Typography style={{
                  fontFamily: "Rubik",
                  fontWeight: 500,
                  fontSize: '10px',
                  color: 'rgba(248, 250, 252, 1)',
                  paddingLeft: '6px'
                }}>
        {`${this.formatTime(currentTime)} / ${this.formatTime(duration)}`}
      </Typography>
      
      {/* Progress Bar */}
      <style>
        {`
        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 12px;
          height: 12px;
          background: rgba(26, 70, 156, 1);
          border-radius: 50%;
          color: rgba(26, 70, 156, 1)
        }
        input[type="range"]::-moz-range-thumb {
          width: 0;
          height: 0;
          background: rgba(26, 70, 156, 1);
          border-radius: 0%;
          color: rgba(26, 70, 156, 1)
        }
      `}
      </style>
      </Box>

      <Box style={{display: 'flex', alignItems: 'center'}}>
      <Typography style={{
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: '11px',
                  color: 'rgba(255, 255, 255, 1)'
                }}>
       Auto Play
      </Typography>
      <Box style={{marginLeft: this.state.autoplay ? '6px' : '0'}}>
      <img data-test-id= "autoplay" onClick={this.toggleAutoplay} src={this.state.autoplay ? AutoplayOn : AutoplayOff} style={{width: '24px', height: '24px'}}/>
      </Box>
      <img data-test-id= "expand" onClick={this.toggleExpand} src={expand} style={{width: '24px', height: '24px'}}/>
      </Box>
    </Box>

  </Box>
  }

  getAudioButtonImage = (index: any) => {
    return  <img
    data-testid = "AudioPlayPauseButton-2"
     onClick={() => this.toggleAudioPlayPause(index)}
     src={this.state.audioStates[index]?.isAudioPlaying ? AudioPauseButton  : AudioPlayButton}   
    style={{
        width: "9px",
        height: "9px",
        color: "rgba(248, 250, 252, 1)",
      }}
      alt="play"
    />
  };

  getCurrentAudioTime = (index: any) => {
    return this.formatAudioTime(this.state.audioStates[index]?.currentAudioTime || 0)
  };

  getAudioDuration = (index: any) => {
    return this.formatAudioTime(this.state.audioStates[index]?.audioDuration || 0)
  };

  renderVideoPlayer = (videoChapters: any) => {
    const {  currentTime, duration, currentVideoIndex} = this.state;
    const currentVideo = videoChapters[currentVideoIndex];

    return (
      <Box
        style={{
          position: "relative",
          width: "100%",
          margin: "0 auto",
          padding: '1rem'
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >

        {this.getCurrentVideoElement(currentVideo, videoChapters, this.state.isExpanded)}
        {this.getCurrentVideoBox(currentTime, duration, this.state.isExpanded, this.state.showControls, videoChapters)}
       
      </Box>
    );
    
  }
  renderAudioPlayer = (audioDetails: any) => {
    const {audioProgress } = this.state;
    const isRowLayout = audioDetails.length <= 3;

    return (
      <Box style={{ padding: '1rem' }}>
        {isRowLayout ?
          (<Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#ffffff",
              borderRadius: "12px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
              width: "100%",
              height: '641px',
              boxSizing: "border-box",
            }}
          >
            {audioDetails?.map((audio: any, index: number) => (
              <Box 
              style={{ display: "flex",
                flexDirection: isRowLayout ? "row" : "column",
                justifyContent: isRowLayout ? "space-between" : "center",
                alignItems: "center",
                width: "100%",
                flexWrap: "wrap",
                padding: '10px'}}>
                <audio
          controls={false}
          ref={(el) => (this.audioRefs[index] = el)} 
          src={audio.src}
          style={{ width: "100%" }}
          preload="metadata"
          onTimeUpdate={() => this.updateAudioProgress(index)}
          onLoadedMetadata={() => this.handleAudioLoadedMetadata(index)}
        />
               <Box width="100%">
                <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography 
                    style={{
                        fontFamily: "Rubik",
                        fontWeight: 400,
                        fontSize: '12px',
                        color: 'rgba(71, 85, 105, 1)'
                      }}>{this.getCurrentAudioTime(index)}</Typography>
                  <Typography  style={{
                        fontFamily: "Rubik",
                        fontWeight: 400,
                        fontSize: '12px',
                        color: 'rgba(71, 85, 105, 1)'
                      }}>{this.getAudioDuration(index)}</Typography>
                </Box>
                <LinearAudioProgressStyle 
                 variant="determinate"
                 value={audioProgress}
                 color="secondary"/></Box>
                <Box   style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '518px', borderRadius: '33.58px', backgroundColor: 'rgba(237, 241, 246, 1)', margin: '10px 0' }}>
                  <img
                    src={audioButton}
                    style={{
                      width: '43.18px',
                      height: '43.18px',
                      color: "rgba(26, 70, 156, 1)",
                    }}
                  />
                </Box>
                <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      style={{
                        fontFamily: "Rubik",
                        fontWeight: 400,
                        fontSize: '12px',
                        color: 'rgba(15, 23, 42, 1)'
                      }}>{audio.intro}</Typography>
                    <Typography
                      style={{
                        fontFamily: "Rubik",
                        fontWeight: 400,
                        fontSize: '10px',
                        color: 'rgba(71, 85, 105, 1)'
                      }}>{audio.time} KB</Typography>
                  </Box>
                  <Box>
                    <AudioPlayPauseButton>
                      {this.getAudioButtonImage(index)}

                    </AudioPlayPauseButton>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>)
          : (<AudioBoxStyle>
            {audioDetails?.map((audio: any, index: number) => (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  height: '62px',
                  padding: '10px',
                  backgroundColor: 'rgba(249, 249, 249, 1)',
                  borderRadius: "18px",
                }}
              >
                <Box style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '42px', height: '46px', borderRadius: '14px', backgroundColor: 'rgba(237, 241, 246, 1)', margin: '10px 0' }}>
                    <img
                      src={audioButton}
                      style={{
                        width: '18px',
                        height: '18px',
                        color: "rgba(26, 70, 156, 1)",
                      }}
                      alt="play"
                    />
                  </Box>
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      style={{
                        fontFamily: "Rubik",
                        fontWeight: 400,
                        fontSize: '12px',
                        color: 'rgba(15, 23, 42, 1)'
                      }}>{audio.intro}</Typography>
                    <Typography
                      style={{
                        fontFamily: "Rubik",
                        fontWeight: 400,
                        fontSize: '10px',
                        color: 'rgba(71, 85, 105, 1)'
                      }}>{audio.time} KB</Typography>
                  </Box>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                   <audio
                    data-testid={`audio-element-${index}`}
          controls={false}
          ref={(el) => (this.audioRefs[index] = el)} 
          src={audio.src}
          style={{ width: "100%" }}
          preload="metadata"
          onTimeUpdate={() => this.updateAudioProgress(index)}
          onLoadedMetadata={() => this.handleAudioLoadedMetadata(index)}
        />
                  <Box  width="184px">
                <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography  style={{
                        fontFamily: "Rubik",
                        fontWeight: 400,
                        fontSize: '12px',
                        color: 'rgba(71, 85, 105, 1)'
                      }}>{this.getCurrentAudioTime(index)}</Typography>
                  <Typography  style={{
                        fontFamily: "Rubik",
                        fontWeight: 400,
                        fontSize: '12px',
                        color: 'rgba(71, 85, 105, 1)'
                      }}>{this.getAudioDuration(index)}</Typography>
                </Box>
                <LinearAudioProgressStyle 
                 variant="determinate"
                value={audioProgress}
                 color="secondary"/>
                </Box>
                  <Box>
                  <AudioPlayPauseButton>
                      {this.getAudioButtonImage(index)}
                  </AudioPlayPauseButton>
                  </Box>
                </Box>
              </Box>
            ))}
          </AudioBoxStyle>)}
      </Box>
    )
  }
  renderPdfViewer = () => {
    return (
      <Box style={{ padding: '1rem' }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ffffff",
            borderRadius: "12px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
            width: "100%",
            height: '641px',
            boxSizing: "border-box",
          }}
        >
          <TextBoxStyle>
            <StyledText>
              Last Revised: December 16, 2013
              Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions contain legal obligations, please read them carefully. 
              <StyledMainText>1. YOUR AGREEMENT</StyledMainText>
                            By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.
              PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions. 
              <StyledMainText>2. PRIVACY</StyledMainText>
              Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.  
              <StyledMainText>3. LINKED SITES</StyledMainText>
This Site may contain links to other independent third-party Web sites ("Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites. 
<StyledMainText>4. FORWARD LOOKING STATEMENTS</StyledMainText>
              All materials reproduced on this site speak as of the original date of publication or filing. The fact that a document is available on this site does not mean that the information contained in such document has not been modified or superseded by events or by a subsequent document or filing. We have no duty or policy to update any information or statements contained on this site and, therefore, such information or statements should not be relied upon as being current as of the date you access this site. 
              <StyledMainText>5. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</StyledMainText>
                            A. THIS SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. WE DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE MATERIALS OR THE RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED OR DISTRIBUTED THROUGH THE SITE. YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (i) YOUR USE OF THE SITE, INCLUDING ANY RELIANCE ON ANY SUCH OPINION, ADVICE, STATEMENT, MEMORANDUM, OR INFORMATION CONTAINED HEREIN, SHALL BE AT YOUR SOLE RISK; (ii) THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS; (iii) EXCEPT AS EXPRESSLY PROVIDED HEREIN WE DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, TITLE AND NON-INFRINGEMENT; (iv) WE MAKE NO WARRANTY WITH RESPECT TO THE RESULTS THAT MAY BE OBTAINED FROM THIS SITE, THE PRODUCTS OR SERVICES ADVERTISED OR OFFERED OR MERCHANTS INVOLVED; (v) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK; and (vi) YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR FOR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
B. YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, ANY OF OUR SITES OR MATERIALS OR FUNCTIONS ON ANY SUCH SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
              <StyledMainText>6. EXCLUSIONS AND LIMITATIONS</StyledMainText>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, OUR LIABILITY IN SUCH JURISDICTION SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
              <StyledMainText>7. OUR PROPRIETARY RIGHTS</StyledMainText>
              This Site and all its Contents are intended solely for personal, non-commercial use. Except as expressly provided, nothing within the Site shall be construed as conferring any license under our or any third party's intellectual property rights, whether by estoppel, implication, waiver, or otherwise. Without limiting the generality of the foregoing, you acknowledge and agree that all content available through and used to operate the Site and its services is protected by copyright, trademark, patent, or other proprietary rights. You agree not to: (a) modify, alter, or deface any of the trademarks, service marks, trade dress (collectively "Trademarks") or other intellectual property made available by us in connection with the Site; (b) hold yourself out as in any way sponsored by, affiliated with, or endorsed by us, or any of our affiliates or service providers; (c) use any of the Trademarks or other content accessible through the Site for any purpose other than the purpose for which we have made it available to you; (d) defame or disparage us, our Trademarks, or any aspect of the Site; and (e) adapt, translate, modify, decompile, disassemble, or reverse engineer the Site or any software or programs used in connection with it or its products and services.
              The framing, mirroring, scraping or data mining of the Site or any of its content in any form and by any method is expressly prohibited.
                            <StyledMainText>8. INDEMNITY</StyledMainText>
                            By using the Site web sites you agree to indemnify us and affiliated entities (collectively "Indemnities") and hold them harmless from any and all claims and expenses, including (without limitation) attorney's fees, arising from your use of the Site web sites, your use of the Products and Services, or your submission of ideas and/or related materials to us or from any person's use of any ID, membership or password you maintain with any portion of the Site, regardless of whether such use is authorized by you.
              <StyledMainText>9. COPYRIGHT AND TRADEMARK NOTICE</StyledMainText> 
              Except our generated dummy copy, which is free to use for private and commercial use, all other text is copyrighted. generator.lorem-ipsum.info © 2013, all rights reserved
              <StyledMainText>10. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS</StyledMainText>
              It is our policy to respond expeditiously to claims of intellectual property infringement. We will promptly process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act ("DMCA") and other applicable intellectual property laws. Notices of claimed infringement should be directed to:
              generator.lorem-ipsum.info
              126 Electricov St.
              Kiev, Kiev 04176
              Ukraine
              contact@lorem-ipsum.info
              <StyledMainText>11. PLACE OF PERFORMANCE</StyledMainText> 
              This Site is controlled, operated and administered by us from our office in Kiev, Ukraine. We make no representation that materials at this site are appropriate or available for use at other locations outside of the Ukraine and access to them from territories where their contents are illegal is prohibited. If you access this Site from a location outside of the Ukraine, you are responsible for compliance with all local laws.
              <StyledMainText>12. GENERAL</StyledMainText>
              A. If any provision of these Terms and Conditions is held to be invalid or unenforceable, the provision shall be removed (or interpreted, if possible, in a manner as to be enforceable), and the remaining provisions shall be enforced. Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. These Terms and Conditions set forth the entire understanding and agreement between us with respect to the subject matter contained herein and supersede any other agreement, proposals and communications, written or oral, between our representatives and you with respect to the subject matter hereof, including any terms and conditions on any of customer's documents or purchase orders.
              B. No Joint Venture, No Derogation of Rights. You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of these Terms and Conditions or your use of the Site. Our performance of these Terms and Conditions is subject to existing laws and legal process, and nothing contained herein is in derogation of our right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by us with respect to such use.
            </StyledText>
        </TextBoxStyle>
       </Box>
     </Box>
 )
}

renderPPTViewer = () => {

  const slides = [PptImage, PptImage, PptImage];

  const currentSlide = slides[this.state.pageNumber - 1];

    return (
      <Box style={{ padding: '1rem' }} data-test-id="PPTViewer">
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ffffff",
            borderRadius: "12px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
            width: "100%",
            height: '641px',
            boxSizing: "border-box",
          }}>
             <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              flexWrap: "wrap",
              padding: '10px'
            }}
          >
             <Box style={{ display: 'flex',
               justifyContent: 'center', 
               alignItems: 'center', 
               width: '100%', 
               height: '560px', 
               borderRadius: '33.58px',
                backgroundColor: 'rgba(237, 241, 246, 1)',
                 margin: '10px 0',
                 overflow: "hidden"}}>  
                    <img
                src={currentSlide}
                alt={`Slide ${this.state.pageNumber}`}
                width="100%"
                height="100%"
                style={{
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
              />
            </Box>
             <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
               <Box style={{ display: 'flex', width: '100%', height: '37px', alignItems: 'center', borderRadius: '18px', backgroundColor: 'rgba(249, 249, 249, 1)' }}>
                 <Box style={{ padding: '0.5rem' }}>
                   <img
                    src={Vector}
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "rgba(26, 70, 156, 1)",
                    }}
                    alt="play"
                  />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    style={{
                      fontFamily: "Rubik",
                      fontWeight: 400,
                      fontSize: '12px',
                      color: 'rgba(15, 23, 42, 1)'
                    }}>File name/20Slides.PDF</Typography>
                  <Typography
                    style={{
                      fontFamily: "Rubik",
                      fontWeight: 400,
                      fontSize: '10px',
                      color: 'rgba(71, 85, 105, 1)'
                    }}>128 KB</Typography>
                </Box>
              </Box>
              <Box style={{
                width: '200px',
                display: 'flex',
                gap: '0.5rem',
                alignItems: 'flex-end',
                padding: '6px',
                justifyContent: 'center',
              }}>
                <ArrowBackIosIcon onClick={this.goToPreviousPage} style={{ height: '13px', width: '13px' }} />
                <Box style={{
                  height: '16px',
                  width: '9.61',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(26, 70, 156, 1)',
                  borderRadius: '5.61px'
                }}>
                  <Typography style={{
                    fontFamily: "Rubik",
                    fontSize: "7px",
                    fontWeight: 700,
                    color: 'rgba(248, 250, 252, 1)'
                  }}>{this.state.pageNumber}</Typography>
                </Box>
                <Typography style={{
                  fontFamily: "Rubik",
                  fontSize: "12px",
                  fontWeight: 500,
                  color: 'rgba(26, 70, 156, 1)'
                }} onClick={this.goToNextPage}>Next Slide</Typography>
                <ArrowForwardIosIcon onClick={this.goToNextPage} style={{ height: '13px', width: '13px' }} />
              </Box>
            </Box>
          </Box>
</Box>
    </Box>
  )

  }

  renderContent = (type: any, audioDetails: any, videoChapters: any) => {

    switch (type) {
      case "video":
        return this.renderVideoPlayer(videoChapters);
      case "audio":
        return this.renderAudioPlayer(audioDetails);
      case "pdf":
        return this.renderPdfViewer();
      case "ppt":
        return this.renderPPTViewer();
      default:
        return <Typography>Unsupported content type</Typography>;
    }
  };

imageContent = (img: any) => {
    return (
      <img
      src={img}
      style={{
        width: "24px",
        height: "24px",
        color: "rgba(26, 70, 156, 1)",
      }}
      alt="play"
    />
    )
  }

  renderImage = (type: any) => {
    switch (type) {
      case "video":
        return this.imageContent(videoRounded)
      case "audio":
        return this.imageContent(Audio)
      case "pdf":
        return this.imageContent(Pdf)
      case "ppt":
          return this.imageContent(textImage)
      default:
        return <Typography>Unsupported content type</Typography>;
    }
  };
  

  render() {
    const { selectedVideoIndex, selectedChapterIndex,Chapters, timerRunning, remainingTime, currentChapterIndex, isMediaClicked } = this.state;
    const selectedChapterType = Chapters[selectedChapterIndex].type;
    const audioDetails = this.state.Chapters.find(chapter => chapter.type === 'audio')?.audioDetails || [];
    const videoChapters = this.state.Chapters.filter(chapter => chapter.type === 'video');
    const selectedChapterName = Chapters[selectedChapterIndex].intro;
    const selectedChapterTime = Chapters[selectedChapterIndex].time;

    return (
      <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
        {/* <HamburgerMenu {...this.props} open={this.state.open} handleMenu={this.handleMenu}  themeDark={this.state.darkTheme} handleTheme={this.handleTheme}/> */}
        <MainContainer data-test-id="mainContainer" >
          <NavigationMenu navigation={this.props.navigation} id={""} theme={this.state.darkTheme} open={this.state.open} />
          <Grid style={{ display: 'flex', gap: '1rem', justifyContent: 'space-evenly', padding: '2rem', width: '100%' }}>
            {this.state.leftMinimise ?
              <Box style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'space-between', alignItems: 'center', height: '753px', width: '70px', backgroundColor: 'rgba(249, 249, 249, 1)', borderRadius: '18px' }}>
                <TableOfContentMin style={{ transform: 'translateY(-50%) rotate(270deg)' }}>
                  Table of contents
                </TableOfContentMin>
                <Button onClick={() => this.setState({ leftMinimise: !this.state.leftMinimise })} style={{ width: "34px", height: "34px", marginTop: '1rem'}} ><img src={ExpandOut} /></Button>

              </Box>
              :
              <Grid item style={{ maxWidth: "326px" }}>
                <TableBox>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <TableOfContent>
                      Table of contents
                    </TableOfContent>
                    <Button onClick={() => this.setState({ leftMinimise: !this.state.leftMinimise })}><img src={minimiseIcon} style={{ width: "34px", height: "34px" }} /></Button>
                  </Box>
                  <CourseTimeBox>
                    <CourseHeadingTypography>Course Name</CourseHeadingTypography>
                    <CourseTimeTypo>Category of the course - 1h 05m - 06/26/2024 1:45 PM</CourseTimeTypo>
                  </CourseTimeBox>
                  {!this.state.startCourse &&
                    <>
                      <Box style={{ paddingTop: '0.5rem' }}>
                        <CourseDetails>4 Chapter</CourseDetails>
                        <CourseDetails>20 Lesson</CourseDetails>
                      </Box>
                      <StartCourseButton data-test-id="StartCourseButton" onClick={() => this.setState({ startCourse: true })}>Start Course</StartCourseButton>
                    </>
                  }

                  {this.state.startCourse &&
                    <Box>
                      <Box style={{ display: 'flex', gap: '0.5rem', margin: '8px 0 6px' }}>
                        <AccordionChapterStyle style={{ width: '244px', height: '36px', borderRadius: '30px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'rgba(15, 23, 42, 1)' }} />}>
                          <Typography>Fire Pro</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: '0 16px 16px', marginTop: '-10px' }}>
                          <List style={{
                            backgroundColor: 'white',
                            width: '220px',
                            zIndex: 9,
                            height: '120px',
                            borderRadius: '18px',
                          }}>
                            <ListItemStyle button>
                              <ListItemText primary="Chapter Name 1" />
                            </ListItemStyle>
                            <ListItemStyle button>
                              <ListItemText primary="Chapter Name 2" />
                            </ListItemStyle>
                            <ListItemStyle button>
                              <ListItemText primary="Chapter Name 3" />
                            </ListItemStyle>
                          </List>
                        </AccordionDetails>
                      </AccordionChapterStyle>
                        <Box>
                          <Box position="relative" display="inline-flex">
                            <CircularProgress
                              variant="determinate"
                              value={90}
                              size={30}
                              thickness={5}
                              style={{ color: 'rgba(26, 70, 156, 1)' }}
                            />
                            <Box
                              top={0}
                              left={0}
                              bottom={0}
                              right={0}
                              position="absolute"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography variant="caption" color="textSecondary" style={{
                                fontFamily: "Rubik",
                                fontSize: "6.25px",
                                fontWeight: 400,
                                lineHeight: "7.41px",
                                color: 'rgba(15, 23, 42, 1)',
                              }}>
                                {`90%`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <AccordionStyle style={{ width: '266px', height: '36px', borderRadius: '30px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'rgba(15, 23, 42, 1)' }} />}>
                          <Typography>Lesson Name</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: '0 16px 16px', marginTop: '-10px' }}>
                          <List style={{
                            backgroundColor: 'white',
                            width: '220px',
                            height: '120px',
                            zIndex: 8,
                            borderRadius: '18px',
                          }}>
                            <ListItemStyle button>
                              <ListItemText primary="Lesson Name 1" />
                            </ListItemStyle>
                            <ListItemStyle button>
                              <ListItemText primary="Lesson Name 2" />
                            </ListItemStyle>
                            <ListItemStyle button>
                              <ListItemText primary="Lesson Name 3" />
                            </ListItemStyle>
                          </List>
                        </AccordionDetails>
                      </AccordionStyle>
                      <AllViewContainerBox>
                        <Box>
                          {Chapters?.map((q, index) => (
                            <EachVideoBox key={index}>
                              <SelectedVideoContainer
                                onClick={() => this.handleChapterSelect(index)}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  cursor: index <= this.state.unlockedChapterIndex ? "pointer" : "not-allowed",
                                  opacity: index <= this.state.unlockedChapterIndex ? 1 : 0.5,
                                  border:
                                    index === this.state.selectedChapterIndex || index === this.state.currentVideoIndex
                                      ? "2px solid rgba(26, 70, 156, 1)"
                                      : "2px solid transparent",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "6px",
                                  }}
                                >
                                 {this.renderImage(q.type)}
                                  <VideoNameTypo>{q.intro}</VideoNameTypo>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <VideoTimer>
                                  {
  this.state.remainingTime[index] === 0
    ? 'Completed'
    : this.state.remainingTime[index] !== undefined
    ? `${this.state.remainingTime[index]} sec`
    : `${q.time / 1000} sec`
}
                                  </VideoTimer>
                                </Box>
                              </SelectedVideoContainer>
                              <img
                                src={downloadIcon}
                                style={{ width: "20px", height: "20px" }}
                                alt="download"
                              />
                            </EachVideoBox>
                          ))}
                        </Box>
                      </AllViewContainerBox>
                    </Box>
                  }
                </TableBox>
              </Grid>
            }
            <Grid item style={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%", }}>
              <MainVideoCourseBox>
                <CourseDetailBox>
                  <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <HeaderStyle variant="h6">
                      {!this.state.startCourse ? 'Currently Selected Lesson Type Name' : selectedChapterName}
                      
                    </HeaderStyle >

                    {this.state.startCourse && (
  <Box>
    <TextButton
      onClick={this.handlePreviousChapter} 
      disabled={this.state.selectedChapterIndex === 0} 
    >
      <ArrowBackIosIcon style={{ height: '13px', width: '13px', marginRight: '1rem' }} />
    {this.state.selectedChapterIndex === 0 ?  <span>Previous Ch.</span> :  <span>Previous Lesson</span>}  
    </TextButton>


    <TextButton
      onClick={this.handleNextLesson} 
      disabled={
        this.state.remainingTime[this.state.selectedChapterIndex] > 0 || 
        this.state.selectedChapterIndex === this.state.Chapters.length - 1 
      }
    >
      <span>Next Lesson</span>
      <ArrowForwardIosIcon style={{ height: '13px', width: '13px', marginLeft: '1rem' }} />
    </TextButton>
  </Box>
)}

                   
                  </Box>
                  <Box width="100%" style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                    <DownloadCourse style={{ flexDirection: 'row-reverse' }}>
                      <DownloadCourseText>Download Course</DownloadCourseText>
                      <CloudDownloadOutlinedIcon style={{ fontSize: '11px' }} />
                    </DownloadCourse>
                  </Box>
                </CourseDetailBox>
                {this.state.startCourse ? <>
                  {this.renderContent(selectedChapterType, audioDetails, videoChapters)}
                  {timerRunning ? (
                    <Typography>Timer is running...</Typography>
                  ) : (
                    <button onClick={() => this.startChapterTimer(selectedChapterTime)} disabled={timerRunning}>
                      Start Timer for Current Chapter
                    </button>
                  )}

                </> :
                  <CourseVideoContainer>
                    <img src={courseVideo} style={{ width: "100%", height: "641px", borderRadius: "18px" }} />
                    <img src={playIcon} style={{
                      width: "54px", height: "40px", position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      padding: theme.spacing(1),
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }} />
                  </CourseVideoContainer>
                }
              </MainVideoCourseBox>
            </Grid>
            {this.state.rightMinimise ?
              <Box style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'space-between', alignItems: 'center', height: '753px', width: '70px', backgroundColor: 'rgba(249, 249, 249, 1)', borderRadius: '18px' }}>
                <CourseTabSection style={{ transform: 'translateY(-50%) rotate(270deg)', marginBottom: '100px' }}>
                  <SelectedFilter  onClick={this.handleAboutOpen} style={{backgroundColor : isMediaClicked ? '' : 'rgba(237, 241, 246, 1)'}}>
                    <SelectedFilterTypo style={{color : isMediaClicked ? 'rgba(71, 85, 105, 1)' : 'rgba(26, 70, 156, 1)'}}>About</SelectedFilterTypo>
                    </SelectedFilter>
                  <SelectedFilter  onClick={this.handleMediaOpen} style={{backgroundColor : isMediaClicked ? 'rgba(237, 241, 246, 1)' : ''}}>
                    <SelectedFilterTypo style={{color : isMediaClicked ? 'rgba(26, 70, 156, 1)' : 'rgba(71, 85, 105, 1)'}}>Media</SelectedFilterTypo>
                    </SelectedFilter>
                </CourseTabSection>
                <Button style={{ width: "34px", height: "34px", marginTop: '1rem'}} onClick={() => this.setState({ rightMinimise: !this.state.rightMinimise })}><img src={ExpandOut}  /></Button>
              </Box> :
              <Grid item style={{ maxWidth: "326px" }}>
                <AboutCoursesBox >
                  <>
                    <Box style={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center", width: "265px", gap: "5px" }}>
                      <CourseTabSection>
                        <SelectedFilter  onClick={this.handleAboutOpen} style={{backgroundColor : isMediaClicked ? '' : 'rgba(237, 241, 246, 1)'}}>
                          <SelectedFilterTypo style={{color : isMediaClicked ? 'rgba(71, 85, 105, 1)' : 'rgba(26, 70, 156, 1)'}}>About</SelectedFilterTypo>
                          </SelectedFilter>
                        <SelectedFilter onClick={this.handleMediaOpen} style={{backgroundColor : isMediaClicked ? 'rgba(237, 241, 246, 1)' : ''}}>
                          <SelectedFilterTypo style={{color : isMediaClicked ? 'rgba(26, 70, 156, 1)' : 'rgba(71, 85, 105, 1)'}}>Media</SelectedFilterTypo>
                          </SelectedFilter>
                      </CourseTabSection>
                      <Button onClick={() => this.setState({ rightMinimise: !this.state.rightMinimise })}><img src={minimiseIcon} style={{ width: "34px", height: "34px" }} /></Button>
                    </Box>
                    {!isMediaClicked ? 
                    <>
                     <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                      <ProfileImageNameBox style={{ justifyContent: "flex-start" }}>
                        <img data-test-id="profileImage" src={profileImage} style={{ width: "40px", height: "40px" }} />
                        <NameBox>
                          <NameTypography>Ibrahim M.</NameTypography>
                          <PositionTypography>Instructor</PositionTypography>
                        </NameBox>
                      </ProfileImageNameBox>
                      <img src={CommentButton} style={{width: "34px", height: "34px" }}/>
                    </Box>
                    <Box>
                      <InstructorTypo>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</InstructorTypo>
                    </Box> 
                    </>
                    : 
                    <MediaBoxStyle >
                    {this.state.Media.map((content, index) => (
     <Box key={index} style={{display: 'flex', flexDirection: 'column', gap: '12px',paddingBottom: '12px'}}>
       <Box>
    {content.fileContent1.map((file, fileIndex) => (
      <MediaFileBoxStyle key={fileIndex}>
        <Box style={{display: 'flex', gap: '8px'}}>
          <ImageNotesBox><img style={{ width: "18px", height: "18px" }}  src={ImageNotes}/></ImageNotesBox>   
        <Box>
        <EditFileNameTypography>{file.fileName}</EditFileNameTypography>
        <EditStorageTypography>{file.storage}</EditStorageTypography>
        </Box>
        </Box>
        <Typography>{file.download ? <img style={{ width: "24px", height: "24px" }}  src={FileDownload}/> : ''}</Typography>
      </MediaFileBoxStyle>
    ))}
  </Box>
  <Box>
    <MediaImageBox>
      <Box style={{ position: "relative", display: "inline-block"}}>
      <img style={{ width: "117px", height: "101px" }}  src={content.image1} alt={`Image ${index + 1} - 1`} />
    
    <MediaImageDownloadBox>
      <img
  style={{width: '17px', height: '17px'}}
  src={FileDownload}
  alt="Overlay"
/>
    </MediaImageDownloadBox>
      </Box>

      <Box style={{ position: "relative", display: "inline-block"}}>
      <img style={{ width: "117px", height: "101px" }}  src={content.image2} alt={`Image ${index + 1} - 1`} />
    
    <MediaImageDownloadBox>
      <img
  style={{width: '17px', height: '17px'}}
  src={FileDownload}
  alt="Overlay"
/>
    </MediaImageDownloadBox>
      </Box>
    </MediaImageBox>
  </Box>
 
  <Box>
    {content.fileContent2.map((file, fileIndex) => (
     <MediaFileBoxStyle key={fileIndex}>
     <Box style={{display: 'flex', gap: '8px'}}>
       <ImageNotesBox><img style={{ width: "18px", height: "18px" }}  src={ImageNotes}/></ImageNotesBox>   
     <Box>
     <EditFileNameTypography>{file.fileName}</EditFileNameTypography>
     <EditStorageTypography>{file.storage}</EditStorageTypography>
     </Box>
     </Box>
     <Typography>{file.download ? <img style={{ width: "24px", height: "24px" }}  src={FileDownload}/> : ''}</Typography>
   </MediaFileBoxStyle>
    ))}
  </Box>
  <Box>
    {content.fileContent3.map((file, fileIndex) => (
      <MediaFileBoxStyle key={fileIndex}>
      <Box style={{display: 'flex', gap: '8px'}}>
        <ImageNotesBox><img style={{ width: "18px", height: "18px" }}  src={ImageNotes}/></ImageNotesBox>   
      <Box>
      <EditFileNameTypography>{file.fileName}</EditFileNameTypography>
      <EditStorageTypography>{file.storage}</EditStorageTypography>
      </Box>
      </Box>
      <Typography>{file.download ? <img style={{ width: "24px", height: "24px" }}  src={FileDownload}/> : ''}</Typography>
    </MediaFileBoxStyle>
    ))}
  </Box>
</Box>
))}
</MediaBoxStyle>
} </>
                </AboutCoursesBox>
              </Grid>
            }
          </Grid>
        </MainContainer>
      </ThemeProvider>
    );
  }
}
const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
];

const Footer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const TextBoxStyle = styled(Box)(({ theme }) => ({
              display: "flex",
              alignItems: "center",
              width: "100%",
              flexWrap: "wrap",
              padding: '10px',
              height: '100%',
              overflowY: 'auto',
              "&::-webkit-scrollbar": {
                width: "13px",
              },
              "&::-webkit-scrollbar-track": {
                borderRadius: "30px 30px 90px 30px",
                backgroundColor: "transparent",
                border: "1px solid #CBD5E1",
              },
              "&::-webkit-scrollbar-thumb": {
                width: "13px",
                height: "43px",
                borderRadius: "30px 30px 90px 30px",
                backgroundColor: "#EDF1F6",
                border: "1px solid #94A3B8",
              },
}))

const MediaBoxStyle = styled(Box)(({ theme }) => ({
  height: '653px', 
  boxSizing: "border-box",
  overflowY: 'auto',
  "&::-webkit-scrollbar": {
    width: "13px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "transparent",
    border: "1px solid #CBD5E1",
  },
  "&::-webkit-scrollbar-thumb": {
    width: "13px",
    height: "43px",
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "#EDF1F6",
    border: "1px solid #94A3B8",
  },
}))

const AudioBoxStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column',
  alignItems: "center",
  background: "#ffffff",
  borderRadius: "18px",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  width: "100%",
  height: '641px',
  boxSizing: "border-box",
  padding: '1rem',
  gap: '1rem',
  overflowY: 'auto',
  "&::-webkit-scrollbar": {
    width: "13px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "transparent",
    border: "1px solid #CBD5E1",
  },
  "&::-webkit-scrollbar-thumb": {
    width: "13px",
    height: "43px",
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "#EDF1F6",
    border: "1px solid #94A3B8",
  },
}))

const AudioPlayPauseButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '26px', 
  height: '26px', 
  backgroundColor: 'rgba(26, 70, 156, 1)', 
  borderRadius: '20px', 
}))

const MediaFileBoxStyle = styled(Box)(({ theme }) => ({
 width: "245px", 
 height: "54px", 
 display: 'flex', 
 justifyContent: 'space-between', 
 alignItems: 'center',
 backgroundColor: 'rgba(255, 255, 255, 1)',
 padding: '6px',
 borderRadius: '6px'
}))

const EditFileNameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "18.15px",
  color: "rgba(15, 23, 42, 1)",
}))

const EditStorageTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "9px",
  fontWeight: 400,
  lineHeight: "18.15px",
  color: "rgba(71, 85, 105, 1)"
}))

const ImageNotesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: "42px",
  height: "42px",
  backgroundColor: "rgba(237, 241, 246, 1)",
  borderRadius: '6px',

}))

const MediaImageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: "245px",
}))

const MediaImageDownloadBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '21px', 
  height: '21px', 
  backgroundColor: 'rgba(255, 255, 255, 0.5)', 
  borderRadius: '12.88px',   
  position: "absolute",
  top: "5px",
  right: "5px",
}))

const DeleteDetailTypography = styled(Typography)(({ theme }) => ({
  width: "361px",
  height: "80px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: "#000000",
}))
const DeleteButton = styled(Button)({
  width: "318px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  borderRadius: "30px",
  backgroundColor: theme.palette.action.selected,
  cursor: "pointer",
  "& .MuiButton-label": {
    width: "52px",
    height: "24px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: "#F8FAFC",
  },
  "&:hover": {
    background: "#1A469C",
    color: "#F8FAFC"
  }
});
const TextColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.selected
}))
const PopupHeaderBox = styled(Box)(({ theme }) => ({
  width: "515px",
  height: "38px",
  gap: "16px",
  opacity: "0px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
}));
const SearchTypography = styled(Typography)(({ theme }) => ({
  width: "200px",
  height: "26px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "26.07px",
  textAlign: "left",
  color: theme.palette.info.contrastText
}))
const DeleteTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "32px",
  fontWeight: 500,
  lineHeight: "37.92px",
  textAlign: "center",
  width: "101px",
  height: "38px",
  gap: "0px",
  opacity: "0px",
  color: theme.palette.secondary.dark,
}))
const NoteHeadTypo = styled(Typography)(({ theme }) => ({
  width: "210px",
  height: "22px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "14.01px",
  fontWeight: 500,
  lineHeight: "21.01px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const NoteDetailTypo = styled(Typography)(({ theme }) => ({
  width: "210px",
  height: "36.77px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "12.26px",
  fontWeight: 400,
  lineHeight: "12.26px",
  textAlign: "left",
  color: theme.palette.text.primary
}))




const TimeTypo = styled(Typography)(({ theme }) => ({
  width: "232px",
  height: "19px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "19px",
  textAlign: "right",
  color: theme.palette.action.hover
}))

const SingleNoteBox = styled(Box)(({ theme }) => ({
  width: "260px",
  height: "155.69px",
  padding: "12px 12px 12px 16px",
  gap: "8px",
  borderRadius: "18px",
  border: "0px 0px 0px 4px",
  opacity: "0px",
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start"
}))
const ButtonDetailBox = styled(Box)(({ theme }) => ({
  width: "573px",
  height: "44px",
  justify: "space-between",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  margin: "20px",
}))
const ButtonBox = styled(Box)(({ theme }) => ({
  width: "183px",
  height: "44px",
  gap: "25px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
}))
const DoneButton = styled(Button)(({ theme }) => ({
  width: "529px",
  height: "46px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  backgroundColor: theme.palette.action.selected,
  "& .MuiButton-label": {
    width: "47px",
    height: "26px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
    color: "#F8FAFC"
  },
  ".MuiButton-root:hover": {
    textDecoration: "none",
    backgroundColor: "#1A469C",
  }
}))

const SubmittedTypo = styled(Typography)(({ theme }) => ({
  width: "480px",
  height: "40px",
  fontFamily: "Rubik",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "40px",
  letterSpacing: "-0.005em",
  textAlign: "center",
  color: theme.palette.secondary.dark
}))
const DownloadTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  width: "87px",
  height: "18px",
  gap: "0px",
  opacity: "0px",
  color: theme.palette.text.hint
}))
const AllViewContainerBox = styled(Box)(({ theme }) => ({
  height: "197px", gap: "4px",
}))
const VideoContainer = styled(Box)(({ theme }) => ({
  width: '234px',
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  opacity: "0px",
}))
const EachVideoBox = styled(Box)(({ theme }) => ({
  height: "44px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: '0.8rem',
  marginTop: '0.5rem'
}))

const VideoNameTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.action.active
}))
const VideoTimer = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.text.disabled
}))
const SelectedVideoContainer = styled(Box)(({ theme }) => ({
  width: '234px',
  height: "44px",
  borderRadius: '30px',
  padding: "10px 16px 10px 16px",
  gap: "8px",
  opacity: "0px",
}))

const CourseDetailBox = styled(Box)(({ theme }) => ({
  margin: "0.5rem 24px",
}))

const CourseHeadingTypography = styled(Typography)(({ theme }) => ({
  height: "26px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "26px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const CourseTimeBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "5px",
}))
const CourseTimeTypo = styled(Typography)(({ theme }) => ({
  width: "276px",
  height: "12px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "12px",
  textAlign: "left",
  color: theme.palette.text.disabled
}))
const CourseVideoContainer = styled(Box)(({ theme }) => ({
  borderRadius: "18px", position: "relative",
  margin: "30px 24px"
}))
const AddCourseButton = styled(Button)(({ theme }) => ({
  width: "180px",
  height: "24px",
  gap: "8px",
  opacity: "0px",
  border: "none",
  "& .MuiButton-label": {
    width: "178px",
    height: "22px",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: theme.palette.action.active
  }
}))
const AddNotesButton = styled(Button)(({ theme }) => ({
  width: "150px",
  height: "26px",
  gap: "8px",
  border: "none",
  marginLeft: "140px",
  "& .MuiButton-label": {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
    color: theme.palette.action.active,
    width: "130px",
    height: "26px"
  }
}))

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: theme.palette.background.default,
  height: 'inherit',
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.primary.main
  }
}))
const AboutCoursesBox = styled(Box)(({ theme }) => ({
  height: "753px",
  borderRadius: "18px",
  backgroundColor: 'rgba(249, 249, 249, 1)',
  gap: "20px",
  padding: "28px 34px",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
}))
const InstructorTypo = styled(Typography)(({ theme }) => ({
  width: "266px",
  height: "143px",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "12.96px",
  letterSpacing: "0.41111114621162415px",
  textAlign: "left",
  color: theme.palette.text.disabled
}))
const MainVideoCourseBox = styled(Box)(({ theme }) => ({
  height: "753px",
  left: "349px",
  borderRadius: "18px",
  backgroundColor: 'rgba(249, 249, 249, 1)',
}))
const TableBox = styled(Typography)(({ theme }) => ({
  height: "753px",
  borderRadius: "18px",
  backgroundColor: 'rgba(249, 249, 249, 1)',
  padding: "28px 34px",
}))
const NotesBox = styled(Box)(({ theme }) => ({
  width: "326px",
  height: "753px",
  borderRadius: "18px",
  backgroundColor: 'rgba(249, 249, 249, 1)',
  padding: "28px 34px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}))
const NavBar = styled(Box)(({ theme }) => ({
  width: "80%",
  height: "75px",
  padding: "15.79px 36.45px 15.79px 33.5px",
  borderRadius: "18px",
  border: "1px",
  background: theme.palette.background.paper,
  margin: "48px 26px",
}))
const MoreOptionsMenu = styled(Menu)(({ theme }) => ({
  width: "192px",
  height: "85px",
  padding: "4px 0px 0px 0px",
  gap: "2px",
  borderRadius: "8px",
  opacity: "0px",
  border: "1px solid #FFFFFF",
  boxShadow: "0px 2px 4px 0px #00000026",
  backgroundColor: theme.palette.background.default,
  top: "33% !important",
  left: "72% !important",
  "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded": {
    maxWidth: "192px",
    maxHeight: "85px",
    width: "192px",
    height: "85px",
    top: "0px !important",
    left: "0px !important",
    transition: "none",
    transformOrigin: "none",
    boxShadow: " 0px 2px 4px 0px #00000026 !important",
    backgroundColor: `${theme.palette.background.default} !important`,
    borderRadius: "8px"
  }
}))

const ModalPaper = styled(Paper)(({ theme }) => ({
  width: "662px",
  height: "583px",
  padding: "38px 25px",
  gap: "10px",
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.background.default}`,
  borderRadius: "18px",
}))
const SubmittedModalPaper = styled(Paper)(({ theme }) => ({
  width: "620px",
  height: "388px",
  gap: "24px",
  borderRadius: "30px",
  opacity: "0px",
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.background.default}`,
}))
const DeleteModalPaper = styled(Paper)(({ theme }) => ({
  width: "611px",
  height: "356px",
  top: "290px",
  left: "414px",
  padding: "48px",
  gap: "24px",
  borderRadius: "30px",
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.background.default}`,
}))
const CancelButton = styled(Button)(({ theme }) => ({
  width: "183px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  border: `1px solid ${theme.palette.action.active}`,
  textTransform: "capitalize",
  "& .MuiButton-label": {
    width: "55px",
    height: "24px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: theme.palette.action.active
  }
}))

const AddButton = styled(Button)(({ theme }) => ({
  width: "71px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  textTransform: "capitalize",
  backgroundColor: "#1A469C",
  "& .MuiButton-label": {
    width: "39px",
    height: "24px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: "#F8FAFC"
  },
  ".MuiButton-root:hover": {
    textDecoration: "none",
    backgroundColor: "#1A469C",
  }
}))
const EditTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  width: "67px",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  color: "#1A469C"
}))
const DeleteTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  width: "86px",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  color: "#DC2626"
}))
const CourseTabSection = styled(Box)(({ theme }) => ({
  width: "226px",
  height: "36px",
  padding: "4px",
  borderRadius: "50px",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: theme.palette.background.default
}))
const AddNoteBox = styled(Box)(({ theme }) => ({
  width: "612px",
  height: "467px",
  gap: "21px",
  opacity: "0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start"
}))
const AddNoteHeaderBox = styled(Box)(({ theme }) => ({
  width: "595px", height: "38px", gap: "12px",
  opacity: "0px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center"
}))
const AddNoteHeaderTypo = styled(Typography)(({ theme }) => ({
  width: "150px",
  height: "38px",
  left: "23px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "32px",
  fontWeight: 500,
  lineHeight: "37.92px",
  textAlign: "center",
  color: theme.palette.secondary.dark
}))
const AddHeaderDivider = styled(Divider)(({ theme }) => ({
  width: "387px",
  height: "2px",
  gap: "0px",
  opacity: "0px",
  background: "#D7D7D780"
}))
const NoteTitleTypo = styled(Typography)(({ theme }) => ({
  width: "120px",
  height: "22px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "left",
  color: theme.palette.text.primary
}))
const InputField = styled('input')(({ theme }) => ({
  width: "595px",
  height: "44px",
  padding: "10px 12px 10px 16px",
  gap: "8px",
  borderRadius: "8px",
  opacity: "0px",
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.info.dark}`,
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.text.hint,
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: '1px solid #D7D7D7'
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #D7D7D7'
  }
}))
const NavGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
}))
const SearchBar = styled(TextField)(({ theme }) => ({
  width: '257px',
  height: '44px',
  padding: '10px 16px',
  gap: '8px',
  borderRadius: '98px',
  border: `1px solid ${theme.palette.info.dark}`,
  backgroundColor: theme.palette.background.default,
  '& .MuiInputBase-root': {
    padding: 0,
    height: '100%',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputAdornment-root': {
    marginRight: '8px',
  },
}))
const NavLinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "12px",
  alignItems: "center",
  justifyContent: "center"
}))
const NavLinkTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.text.secondary,
  "& .active": {
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
    padding: "8px",
    gap: "10px",
    borderRadius: "0px 0px 8px 8px"
  }
}))
const NavLinkActiveTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.action.selected,
  borderBottom: `2px solid ${theme.palette.action.selected}`,
  padding: "8px",
  gap: "10px",
  borderRadius: "0px 0px 8px 8px"
}))
const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex", flexDirection: "row", gap: "8px"
}))
const ProfileImageNameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px"
}))
const BadgeContainer = styled(Badge)(({ theme }) => ({
  width: "44px",
  height: "44px",
  padding: "10px",
  gap: "4px",
  borderRadius: "8px",
  opacity: "0px",
}))
const NameBox = styled(Box)(({ theme }) => ({

}))
const NameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.main
}))
const PositionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.light,
  textTransform: "capitalize",
}))
const SelectedFilter = styled(Box)(({ theme }) => ({
  width: "72.67px",
  height: "28px",
  gap: "6px",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: 'center',
  cursor: 'pointer',
}))

const TableOfContentMin = styled(Box)(({ theme }) => ({
  width: "218px",
  padding: "6px 12px 6px 12px",
  borderRadius: "50px",
  backgroundColor: theme.palette.action.focus,
  display: "flex",
  alignItems: "center",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  color: theme.palette.action.active,
  justifyContent: 'center',
  marginBottom: '100px'
}))

const TableOfContent = styled(Box)(({ theme }) => ({
  width: "218px",
  height: "28px",
  padding: "6px 12px 6px 12px",
  gap: "6px",
  borderRadius: "50px",
  backgroundColor: theme.palette.action.focus,
  display: "flex",
  alignItems: "center",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  color: theme.palette.action.active,
  justifyContent: 'center',
}))
const SelectedFilterTypo = styled(Typography)(({ theme }) => ({
  width: "42px",
  height: "22px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
}))

const CourseDetails = styled(Button)(({ theme }) => ({
  width: "266px",
  height: "36px",
  borderRadius: '30px',
  margin: '6px',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  justifyContent: 'flex-start',
  padding: '16px',
  textTransform: 'none',
  "& .MuiButton-label": {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "12px",
    textAlign: "left",
    color: "rgba(71, 85, 105, 1)",
  },
}))



const CourseName = styled(Button)(({ theme }) => ({
  width: "244px",
  height: "36px",
  gap: "6px",
  borderRadius: "60px",
  backgroundColor: 'rgba(232, 237, 245, 1)',
  display: "flex",
  alignItems: "center",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  color: 'rgba(26, 70, 156, 1)',
  justifyContent: 'space-between',
  textTransform: 'none'
}))

const StartCourseButton = styled(Button)(({ theme }) => ({
  width: "266px",
  height: "36px",
  borderRadius: '30px',
  margin: '6px',
  backgroundColor: 'rgba(26, 70, 156, 1)',
  justifyContent: 'center',
  padding: '16px',
  textTransform: 'none',
  "& .MuiButton-label": {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    color: "rgba(248, 250, 252, 1)",
  },
  "&:hover": {
    backgroundColor: 'rgba(26, 70, 156, 1)', 
    boxShadow: 'none', 
  },
}))

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  width: "266px",
  height: "36px",
  borderRadius: '30px',
  textTransform: 'none',
  "& .MuiAccordionSummary-root": {
    height: "36px",
    minHeight: 0,
  },
  "& .MuiTypography-body1": {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: "rgba(71, 85, 105, 1)",
  },
  "&.MuiAccordion-root.Mui-expanded": {
    margin: 0
  },
  "&::before": {
    display: "none",
    content: "none",
  },
}))

const AccordionChapterStyle = styled(Accordion)(({ theme }) => ({
  width: "244px",
  height: "36px",
 
  textTransform: 'none',
  "& .MuiAccordionSummary-root": {
    backgroundColor: 'rgba(232, 237, 245, 1)',
    height: "36px",
    minHeight: 0,
    borderRadius: '60px',
  },
  "& .MuiTypography-body1": {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: 'rgba(26, 70, 156, 1)',
  },
  "&.MuiAccordion-root.Mui-expanded": {
    margin: 0
  },
  "&::before": {
    display: "none",
    content: "none",
  },
}))

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  paddingTop: 3,
  paddingBottom: 3,
  "& .MuiTypography-body1": {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "rgba(71, 85, 105, 1)",
  },
  "& .MuiTypography-body1:hover": {
    color: 'rgba(26, 70, 156, 1)'
  },
  "&:hover": {
    textDecoration: 'none',
    backgroundColor: 'rgba(232, 237, 245, 1)',
  },
}))

const HeaderStyle = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: '12.25px',
  color: 'rgba(15, 23, 42, 1)',
  lineHeight: '26px'
}))

const DownloadCourseText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: '9px',
}))

const DownloadCourse = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: 'rgba(26, 70, 156, 1)',
  gap: '4px'
}))

const TextButton = styled(IconButton)(({ theme }) => ({
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: '12px',
  color: 'rgba(26, 70, 156, 1)',
}))

const StyledText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "21px",
  color: 'rgba(15, 23, 42, 1)',
}))

const StyledMainText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "21px",
  color: 'rgba(15, 23, 42, 1)',
  paddingTop: '1rem'
}))

const LinearAudioProgressStyle = styled(LinearProgress)(({ theme }) => ({
  height: "3.63px",
  borderRadius: "4.85px",
  backgroundColor: "rgba(226, 232, 240, 1)",
  "& .MuiLinearProgress-barColorSecondary": {
    backgroundColor: 'rgba(26, 70, 156, 1)'
  },
}))

const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
      light: "010101"
    },
    secondary: {
      main: '#FFFFFF',
      light: "#D1FAE5",
      dark: "#0F172A"
    },
    background: {
      default: '#FFFFFF',
      paper: 'rgba(238,238,238,1)'
    },
    text: {
      primary: '#334155',
      secondary: '#475569',
      hint: '#0F172A',
      disabled: "#475569",
    },
    action: {
      active: '#1A469C',
      selected: '#1A469C',
      focus: '#EDF1F6',
      disabled: '#475569',
      hover: "#475569"
    },
    info: {
      main: "#21272A",
      light: '#848484',
      dark: '#CBD5E1',
      contrastText: '#334155',
    },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#161616',
      light: "#BDCFF4"
    },
    secondary: {
      main: '#2F2F2F',
      light: "#074525",
      dark: "#F2F2F4"
    },
    background: {
      default: '#0D0D0D',
      paper: 'rgba(26,26,26,1)',
    },
    text: {
      primary: '#909EB2',
      secondary: '#93A1B5',
      hint: '#D5DDF0',
      disabled: "#99A7BB",
    },
    action: {
      active: '#70A1FF',
      selected: '#12326F',
      focus: '#121B29',
      disabled: '#D0DBEB',
      hover: "#96A4B8"
    },
    info: {
      main: "#D5DDF0",
      light: '#99A7BB',
      dark: '#273444',
      contrastText: '#D5DDF0',
    },
  },
  typography: FontStyle
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

// Customizable Area End








