import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  IGroup,
  IAccount,
  IGroupAccount,
  ITask,
  Status,
  Priority,
  ISubTask,
} from "./types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment from "moment";
import { RadioChangeEvent } from "antd";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  title: string;
  description: string;
  priority: string;
  status: string;
  status1: string;
  assign_to: string;
  assign_to_type: string;
  editMode: boolean;
  token: string;
  taskList: ITask[];
  taskList1: ITask[];
  isVisibleModal: boolean;
  isVisibleAssignModal: boolean;
  dropdownGroup: boolean;
  dropdownStatus: boolean;
  dropdownAccount: boolean;
  dropdownPriority: boolean;
  groupList: IGroup[];
  statusList: Status[];
  priorityList: Priority[];
  selectedAccounts: IGroupAccount[];
  accountsData: IAccount[];
  selectedTheme: boolean;
  openMenu: any;
  inProgressStatus: boolean;
  completedStatus: boolean;
  inReviewStatus: boolean;
  onHoldStatus: boolean;
  cancelledStatus: boolean;
  subTask: ISubTask[];
  enableSubTask: boolean;
  taskDueDate: string;
  anchorOpen: null | HTMLElement;
  selectedId: number;
  selectedItem: ITask;
  anchorSubOpen: null | HTMLElement;
  expirationDate: string;
  expanded: boolean;
  apiStatus: string;
  accordionOpen: boolean;
  userProfile: IAccount;
  isLoading: boolean;
  createCheckStatus: string;
  importantCheck: boolean;
  allocate_type: string;
  companiesModal: boolean;
  activeStep: number;
  activeAssign: boolean;
  companySearchKey: string;
  userSearchKey: string;
  companiesNames: { "attributes": { [key: string]: string | Number | boolean } }[];
  selectedCompany: string;
  companyEmployeeModal: boolean;
  filterRole: string;
  filterGroup: string;
  companyEmployee: { attributes: { [key: string]: string | Number | boolean } }[];
  selectedEmployeesIds: number[];
  recipientError: boolean;
  getRole: string;
  dropdownOpen: boolean;
  groupdropdownOpen: boolean;
  isAssignModal:boolean;
  isAssignCourseModal:boolean;
  courseData:any;
  assignedCourses:number;
  task:number[];
  assignCourseCatogory:string;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTasksApiCallId = "";
  getTasksApiCallId1 = "";
  getTaskProgressCallId = "";
  postTaskApiCallId = "";
  putTaskApiCallId = "";
  putMarkAskApiCallId = "";
  deleteTaskApiCallId = "";
  deleteSubTaskApiCallId = "";
  getGroupsApiCallId = "";
  getAllCompaniesApiCallId = "";
  getCompanyEmployeeApiCallId = "";
  assignGroupApiCallId = "";
  getAccountsApiCallId = "";
  getProfileApiCallId = "";
  getAllUsersSearchApiCallId="";
  gettingRoleDataAPICallId="";
  getSupervisorCompanyEmployeeApiCallId="";
  getCourseApiCallId=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      title: "",
      description: "",
      priority: "",
      status: "in_progress",
      status1: "in_review",
      assign_to: "",
      assign_to_type: "",
      editMode: false,
      token: "",
      taskList: [],
      taskList1: [],
      isVisibleModal: false,
      isVisibleAssignModal: false,
      dropdownGroup: false,
      dropdownStatus: false,
      dropdownAccount: false,
      dropdownPriority: false,
      groupList: [],
      selectedAccounts: [],
      accountsData: [],
      statusList: [
        { id: 1, name: "important" },
        { id: 2, name: "in_progress" },
        { id: 3, name: "completed" },
      ],
      priorityList: [
        { id: 1, name: "low" },
        { id: 2, name: "medium" },
        { id: 3, name: "high" },
      ],
      selectedTheme: false,
      openMenu: localStorage.getItem('open'),
      inProgressStatus: true,
      inReviewStatus: true,
      completedStatus: false,
      onHoldStatus: false,
      cancelledStatus: false,
      subTask: [{ name: "", is_completed: false }],
      enableSubTask: false,
      taskDueDate: moment().toString(),
      anchorOpen: null,
      selectedId: 0,
      anchorSubOpen: null,
      expirationDate: "",
      expanded: false,
      selectedItem: {
        id: "",
        isSelected: false,
        attributes: {
          id: 1,
          title: "",
          account_id: 1,
          status: "in_progress",
          description: "",
          created_at: new Date,
          updated_at: new Date,
          priority: "high",
          assigned_to: {
            id: '',
            isSelected: false,
            type: "profile",
            attributes: {
              "id": 83,
              "last_name": "",
              "first_name": "",
              "date_of_birth": "",
              "email": "",
              "role_id": "",
              "preferred_name": "",
              "employee_number": 1,
              "full_phone_number": "",
              "company_reference": "",
              "location": "",
              "account_id": 1,
              "completed_course": "",
              "group_id": "",
              "image": "",
              "company_id": 1,
              "group_name": "",
              "phone_number_verified": false,
              "company_name": "",
              "selected_theme": "",
              "font_size": ""
            },
          },
          due_date: moment().toString(),
          expiration_date: "",
          task_lists: [],
          completion_percentage: 0,
          is_important: false,
          is_assigned:false,
          assign_ids:[],
        }
      },
      apiStatus: 'in_progress',
      accordionOpen: false,
      userProfile: {
        id: '',
        isSelected: false,
        type: "profile",
        attributes: {
          "id": 83,
          "first_name": "",
          "last_name": "",
          "email": "",
          "date_of_birth": "",
          "preferred_name": "",
          "role_id": "",
          "full_phone_number": "",
          "employee_number": 1,
          "location": "",
          "company_reference": "",
          "completed_course": "",
          "account_id": 1,
          "image": "",
          "group_id": "",
          "group_name": "",
          "company_id": 1,
          "company_name": "",
          "phone_number_verified": false,
          "font_size": "",
          "selected_theme": "",
        },
      },
      isLoading: false,
      createCheckStatus: 'in_progress',
      importantCheck: false,
      allocate_type: "all_companies",
      companiesModal: false,
      activeStep: 0,
      activeAssign: false,
      companySearchKey: "",
      userSearchKey: "",
      companiesNames: [],
      selectedCompany: "",
      companyEmployeeModal: false,
      filterRole: "",
      filterGroup: "",
      companyEmployee: [],
      selectedEmployeesIds: [],
      recipientError: false,
      getRole:"",
      dropdownOpen: false,
      groupdropdownOpen: false,
      isAssignModal:false,
      isAssignCourseModal:false,
      assignedCourses:-1,
      task:[],
      assignCourseCatogory:"",
      courseData:""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let menuState:any= localStorage.getItem('open');
    if(menuState!=null){
     this.setState({openMenu:JSON.parse(menuState)})

    }
    let darkMode = await getStorageData('darkMode');
    if (darkMode === 'true') {
      this.setState({ selectedTheme: true })
    }
    const token = await getStorageData('authToken');
    this.setState({ token: token });
    this.getTasks(token);
    this.getProfile(token)
    this.gettingRoleActive()
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.gettingRoleDataAPICallId) {
          this.setState({ getRole: responseJson.role_id });
        }
      }
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      }
      // Condition to fetch group list.
      else if (apiRequestCallId === this.getGroupsApiCallId) {
        this.setState({ groupList: responseJson.data});
      }

      // Condition to assign task to the groups/accounts.
      else if (apiRequestCallId === this.assignGroupApiCallId) {
        this.getTasks(this.state.token);
        this.setState({ isVisibleAssignModal: false });
      }
    
      else if (responseJson) {
        this.checkTaskResponses(message, apiRequestCallId)
      }
    }
  }

  checkTaskResponses = (message: Message, apiRequestCallId: string) => {
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    // Condition for fetching tasks list.
    if (apiRequestCallId === this.getTasksApiCallId) {
      // this.getTasks1(this.state.token)
      if (responseJson.data) {
        this.setState({
          taskList: responseJson.data.sort(
            (a: ITask, b: ITask) => parseInt(a.id) - parseInt(b.id)
          ),
        });
      } else {
        this.setState({ taskList: [] });
      }
    }
    // Condition for fetching tasks list1.
    else if (apiRequestCallId === this.getTasksApiCallId1) {
     this.handleGetTask(responseJson);
    }
    // Condition to create new task.
    else if (apiRequestCallId === this.postTaskApiCallId) {
     this.handlePost()
    }
    // Condition to edit task details.
    else if (apiRequestCallId === this.putTaskApiCallId) {
      this.getTasks(this.state.token);
      this.setState({ isVisibleModal: false, isLoading: false, enableSubTask: false, createCheckStatus: 'in_progress',isAssignModal:false });
    }
    else if (apiRequestCallId === this.putMarkAskApiCallId) {
      toast.success(`Your task has been marked as ${responseJson.task.status} successfully`, { position: "top-center" })
      this.getTasks(this.state.token);
    }

    // Condition to delete task.
    else if (apiRequestCallId === this.deleteTaskApiCallId) {
      toast.success('Your task has been deleted successfully', { position: "top-center", containerId: "B" })
      this.getTasks(this.state.token);
    }

    else if (apiRequestCallId === this.getProfileApiCallId) {
      this.setState({ userProfile: responseJson.data })
    }

    else if (apiRequestCallId === this.getAllCompaniesApiCallId) {
      this.setState({ isLoading: false, companiesNames: responseJson.data })
    }

    else if (apiRequestCallId === this.getCompanyEmployeeApiCallId) {
      this.setState({ isLoading: false, companyEmployee: responseJson.data })
    }

    else if (apiRequestCallId === this.getSupervisorCompanyEmployeeApiCallId) {
      this.setState({ isLoading: false, companyEmployee: responseJson.data })
    }  else if(this.getCourseApiCallId===apiRequestCallId){ 
      this.setState({courseData:responseJson.data.data})
    }
  }
 handleGetTask=(responseJson:any)=>{
  if (responseJson.data) {
    this.setState({
      taskList1: responseJson.data
    });
  } else {
    this.setState({ taskList1: [] });
  }
 }
  handlePost=()=>{
    
    if(this.state.selectedEmployeesIds.length>0){
      this.changeReviewStatus(true, false, false, 'in_review')
    }else{
      this.changeInprogress(true, false, 'in_progress')
    }
    this.setState({ isLoading: false,isAssignModal:false });
    this.getTasks(this.state.token)
    toast.success("Task added successfully ")
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hideAssignModal = () => {
    this.setState({ isVisibleAssignModal: false });
  };

  handleInputTitle = (title: string) => {
    this.setState({ title });
  };

  handleInputDescription = (description: string) => {
    this.setState({ description });
  };

  toggleAssignModal = () => {
    this.getAccounts(this.state.token);
    this.getGroups(this.state.token);
    this.setState({
      dropdownAccount: false,
      dropdownGroup: false,
      isVisibleAssignModal: !this.state.isVisibleAssignModal,
      editMode: true,
    });
  };

  showAddModal = () => {
    if(this.state.isAssignCourseModal){
      this.setState({isAssignCourseModal:false,
        assignedCourses:-1,
        assignCourseCatogory:""
      })
     
    }else  if (this.state.enableSubTask) {
      this.setState({ enableSubTask: false, activeStep: 0 })
    } else {
      this.setState({
        title: "",
        description: "",
        isVisibleModal: !this.state.isVisibleModal,
        isAssignModal:false,
        editMode: false,
        enableSubTask: false,
        taskDueDate: moment().toString(),
        subTask: [{ name: "", is_completed: false }],
        createCheckStatus: 'in_progress',
        importantCheck: false,
        activeAssign: false,
        selectedEmployeesIds: [],
        anchorOpen: null
      });
    }
   
  };

  goToSubTask = () => {
    if (this.state.activeAssign && this.state.selectedEmployeesIds.length === 0) {
      this.setState({ enableSubTask: false, activeStep: 0, recipientError: true })
    } else {
      this.setState({ enableSubTask: true, activeStep: 1, recipientError: false })
    }
  }

  checkImportant = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      this.setState({ importantCheck: true })
    } else {
      this.setState({ importantCheck: false })
    }
  }

  addSubTask = () => {
    let values = this.state.subTask;
    values.push({ name: "", is_completed: false });
    this.setState({ subTask: values });
  }

  removeField = (index: number, item: ISubTask) => {
    let value = this.state.subTask;
    value.splice(index, 1);
    this.setState({ subTask: value })
    if (this.state.editMode) {
      this.deleteSubTask(item)
    }
  }

  changeSubTask = (value: string, indexId: number) => {
    let newValue = this.state.subTask;
    this.state.subTask.forEach((element, index) => {
      if (index === indexId) {
        newValue[index].name = value
      }
      this.setState({ subTask: newValue })
    })
  }

  handleFocus = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      (event.target as HTMLInputElement).blur()
      this.addSubTask()
    }
  }

  handleOptionMenu = (event: React.MouseEvent<HTMLElement>, item: ITask) => {
    this.setState({ anchorOpen: event.currentTarget, selectedId: item.attributes.id, selectedItem: item, createCheckStatus: item.attributes.status, importantCheck: item.attributes.is_important });
  };

  handleSubOptionMenu = (event: React.MouseEvent<HTMLElement>, key: number) => {
    this.setState({ anchorSubOpen: event.currentTarget, selectedId: key });
  };

  changeStatus = (item: ITask, status: string) => {
    this.setState({ anchorOpen: null, anchorSubOpen: null })
    if (status === 'important') {
      this.setState({ importantCheck: true }, () => this.editTask(String(this.state.selectedItem.attributes.id)))
    } else {
      if (status === "completed" || status === 'in_progress') {
        this.setState({ status: status })
      } else {
        this.setState({ status1: status, status: "in_progress" })
      }
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token
      };
      const httpBody = {
        "status": status
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putMarkAskApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint + "/" + `${this.state.selectedItem.attributes.id}` + '/mark_status'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  changeInprogress = (value1: boolean, value2: boolean, status: string) => {
    this.setState({
      status: status,
      inProgressStatus: value1,
      completedStatus: value2,
    }, () => this.getTasks(this.state.token))
  }

  changeTaskProgress = (event: CheckboxChangeEvent, item: ISubTask) => {
    const newObj = new FormData();
    newObj.append(`[task_list][is_completed]`, JSON.stringify(event.target.checked))
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putTaskListApiEndPoint + "/" + `${item.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      newObj
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changeReviewStatus = (value1: boolean, value2: boolean, value3: boolean, status: string) => {
    this.setState({
      status1: status,
      inReviewStatus: value1,
      onHoldStatus: value2,
      cancelledStatus: value3
    })
  }
  showAssignModal = async () => {
    this.setState({ isAssignModal: true }, () => {
      this.showEditModal();
    });
  };
  showEditModal = () => {
    this.setState({
      selectedId: this.state.selectedItem.attributes.id,
      title: this.state.selectedItem.attributes.title,
      description: this.state.selectedItem.attributes.description,
      taskDueDate: this.state.selectedItem.attributes.due_date ? this.state.selectedItem.attributes.due_date : moment().toString(),
      expirationDate: this.state.selectedItem.attributes.expiration_date ? this.state.selectedItem.attributes.expiration_date : "",
      anchorOpen: null,
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
      subTask: this.state.selectedItem.attributes.task_lists ? this.state.selectedItem.attributes.task_lists : [{ name: "", is_completed: false }],      
      activeAssign:this.state.isAssignModal?true: this.state.selectedItem.attributes.is_assigned,
      selectedEmployeesIds: this.state.selectedItem.attributes.assign_ids.map(id => Number(id)),
      assignedCourses:-1,assignCourseCatogory:"",
    });
    this.getSupervisorEmployeesDetails();
    this.getEmployeesDetails()
  };

  expandGroupView = () => {
    this.setState({
      dropdownGroup: !this.state.dropdownGroup,
    });
  };

  expandStatusView = () => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus,
    });
  };

  expandPriorityView = () => {
    this.setState({
      dropdownPriority: !this.state.dropdownPriority,
    });
  };

  expandAccountsView = () => {
    this.setState({ dropdownAccount: !this.state.dropdownAccount });
  };

  handleAccountSelect = (dataId: string) => {
    let newData = this.state.accountsData.map((account: IAccount) => {
      if (account.id === dataId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ accountsData: newData });
  };

  handleSelectPriority = (item: Priority) => {
    this.setState({ priority: item.name, dropdownPriority: false });
  };

  handleSelectStatus = (item: Status) => {
    this.setState({ status: item.name, dropdownStatus: false });
  };

  handleAssignToModal = (item: ITask) => {
    this.setState({
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.description,
      priority: item.attributes.priority,
      status: item.attributes.status,
    });
    if (item.attributes.assigned_to) {
      this.setState({
        assign_to:
          item.attributes.assigned_to.type === "group"
            ? item.attributes.assigned_to.attributes.name
            : item.attributes.assigned_to.attributes?.first_name || "",
        assign_to_type: item.attributes.assigned_to.type,
      });
    }
    this.toggleAssignModal();
  };

  handleEditTask = (item: ITask) => {
    this.setState({
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.description,
      priority: item.attributes.priority,
      status: item.attributes.status,
    });
    this.showEditModal();
  };

  getProfile = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Function to fetch tasks list from the API
  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTasks1 = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallId1 = requestMessage1.messageId;

    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}?status=${this.state.status1}`
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  };

  // Function to add new task and send it to API
  addTask = async () => {
    this.setState({ isVisibleModal: false })
    this.setState({enableSubTask:false})
    let token = await getStorageData('authToken')

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const filterTask=this.state.subTask.filter(task => task.name !== "");
  this.setState({subTask:filterTask})
   if(this.state.assignedCourses!==-1){
    const flag=this.state.assignedCourses
    const arr=[flag]
    this.setState({task:arr})
   }
    const httpBody = {
      "task": {
        title: this.state.title,
        description: this.state.description,
        // priority: this.state.priority,
        due_date: this.state.taskDueDate,
        task_lists_attributes: this.state.subTask,
        status: this.state.createCheckStatus,
        is_important: this.state.importantCheck,
        is_assigned: this.state.activeAssign,
        assign_ids: this.state.selectedEmployeesIds,
        ...(this.state.selectedEmployeesIds && this.state.selectedCompany ? { company_ids: [this.state.selectedCompany] } : {}),
        course_id:this.state.task[0]
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({assignedCourses:-1,assignCourseCatogory:"",task:[]})
  };

  // Function to update task details and send it to API
  editTask = async (taskId: string) => {
    let token = await getStorageData('authToken')
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
      'Cache-Control': 'no-cache, no-store, must-revalidate', // Prevents caching
      'Pragma': 'no-cache',  // HTTP 1.0 cache prevention
      'Expires': '0',        // Forces expiration
    };
    
    let filterTask =  this.state.subTask.filter(task => task.name !== "");
    this.setState({ subTask: filterTask })
    const httpBody = {
      "task": {
        title: this.state.title,
        description: this.state.description,
        status: this.state.createCheckStatus,
        priority: this.state.priority,
        due_date: this.state.taskDueDate,
        task_lists_attributes: this.state.subTask,
        is_open: this.state.accordionOpen,
        is_important: this.state.importantCheck,
        is_assigned: this.state.activeAssign,
        assign_ids: this.state.selectedEmployeesIds,
        ...(this.state.selectedEmployeesIds && this.state.selectedCompany ? { company_ids: [this.state.selectedCompany] } : {})
      }
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksApiEndPoint + "/" + `${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to delete task and send it to API
  deleteTask = (taskId: string) => {
    this.setState({ anchorOpen: null })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksApiEndPoint + "/" + `${this.state.selectedItem.attributes.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteSubTask = (task: ISubTask) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteSubTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putTaskListApiEndPoint + "/" + `${task.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Function to fetch groups list from the API
  getGroups = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupNameListingEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to assign task to groups/accounts and sent it to the API
  handleAssignTo = (
    taskId: string,
    assignToType: string,
    assignToId: string
  ) => {
    if (
      this.isStringNullOrBlank(taskId) ||
      this.isStringNullOrBlank(assignToId)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        assigned_to_type: assignToType,
        assigned_to_id: assignToId,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.assignGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint + "/" + `${taskId}` + "/assign"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to fetch accounts list from the API
  getAccounts = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksAccountsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAccordion = (value: ITask) => {
    this.setState({
      title: value.attributes.title,
      description: value.attributes.description,
      priority: value.attributes.priority,
      taskDueDate: value.attributes.due_date ? value.attributes.due_date : moment().toString(),
      subTask: value.attributes.task_lists ? value.attributes.task_lists : [{ 'name': "", is_completed: false }],
      accordionOpen: !value.attributes.is_open,
      createCheckStatus: value.attributes.status,
      importantCheck: value.attributes.is_important,
      dropdownOpen:!this.state.dropdownOpen
    }, () => this.editTask(String(value.attributes.id))
    )
  }

  changeTheme = () => {
    this.setState({ selectedTheme: !this.state.selectedTheme })
    setStorageData('darkMode', JSON.stringify(!this.state.selectedTheme))
  }

  toggleMenu = () => {
    this.setState({ openMenu: !this.state.openMenu })
    setStorageData('open', JSON.stringify(!this.state.openMenu))
  }

  calculateWeek = (createdDate: string) => {
    const currentDate = moment();
    const startDate = moment(createdDate);
    // Calculate the number of weeks
    const weeksDifference = startDate.diff(currentDate, "weeks");
    return weeksDifference
  }

  openCompaniesModal = () => {
    if(this.state.getRole === "instructor"){
      this.setState({
      companiesModal:!this.state.companiesModal,
      isVisibleModal:!this.state.isVisibleModal
    }, () => this.getAllCompanies())
    }
    else{
      this.setState({
        companiesModal:this.state.companiesModal,
        isVisibleModal:!this.state.isVisibleModal,
        companyEmployeeModal: true
      },() => this.getSupervisorEmployeesDetails())
    }
  }

  openCompanyEmployeeModal = () => {
    this.getGroups(this.state.token)
    this.setState({companiesModal: !this.state.companiesModal})
    this.setState({companyEmployeeModal: true }, () => this.getEmployeesDetails())
  }

  closeCompanyEmployeeModal = () => {
    if(this.state.getRole === "instructor"){
      this.setState({ companyEmployeeModal: false,companiesModal: true, selectedEmployeesIds: [], userSearchKey: "",companySearchKey: ""},() => this.getAllCompanies())}
    else{
      this.setState({companyEmployeeModal: false, isVisibleModal: true ,selectedEmployeesIds: [], userSearchKey: ""})
    } 
  }

  activeAssignTask = () => {
    this.setState({ activeAssign: true })
  }

  closeCompaniesModal = () => {
    this.setState({ companiesModal: false, isVisibleModal: true, selectedCompany: "", companySearchKey: ""})
  }

  changeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ companySearchKey: event.target.value }, () => this.getAllCompanies())
  }

  selectCompany = (event: RadioChangeEvent) => {
    this.setState({ selectedCompany: event.target.value })
  }

  getAllCompanies = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCompaniesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companiesApiEndPoint}?query=${this.state.companySearchKey}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getEmployeesDetails = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompanyEmployeeApiCallId = requestMessage1.messageId;
    const company = this.state.companiesNames.find(item=>item.attributes.id === this.state.selectedCompany)
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeeDetailsEndPoint}?group_name=${this.state.filterGroup}&company_name=${company?.attributes.company_name}&role_id=${this.state.filterRole}&query=${this.state.userSearchKey}`
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }
  
  getSupervisorEmployeesDetails = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSupervisorCompanyEmployeeApiCallId = requestMessage1.messageId;
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.supervisorEmployeeDetailsEndPoint}?role_id=${this.state.filterRole}&query=${this.state.userSearchKey}`

    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }

  roleFilter = (value:any) => {
    if(this.state.getRole === "instructor"){
      this.setState({filterRole: value}, () => this.getEmployeesDetails())
    }else{
    this.setState({filterRole: value}, () => this.getSupervisorEmployeesDetails())
    }
  }

  groupFilter = (groupValue: any) => {
    this.setState({ filterGroup: groupValue }, () => this.getEmployeesDetails())
  }

  selectEmployee = (event: CheckboxChangeEvent, Value: number) => {
    let newArray = this.state.selectedEmployeesIds;
    if (event.target.checked) {
      newArray.push(Number(Value))
      this.setState({ selectedEmployeesIds: newArray })
    } else {
      newArray = newArray.filter((item) => item != Value)
      this.setState({ selectedEmployeesIds: newArray })
    }
  }

    changeUserSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(this.state.getRole === "instructor"){
        this.setState({ userSearchKey: event.target.value }, () => this.getEmployeesDetails())
      }
      else{
        this.setState({ userSearchKey: event.target.value }, () => this.getSupervisorEmployeesDetails())
      }
    }

    gettingRoleActive = () => {
      const header = {
        "Content-Type": configJSON.notificationApiContentType,
        token: localStorage.getItem("authToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.gettingRoleDataAPICallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.validateRoleEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    resetFilter = () => {
      if(this.state.getRole === "instructor"){
      this.setState({
        filterRole: "", filterGroup: ""
      }, () => this.getEmployeesDetails())
    }else{
      this.setState({
        filterRole: ""
      }, () => this.getSupervisorEmployeesDetails())
    }
    }
    isShowAssignCourseModal=()=>{
      let flag=this.state.isAssignCourseModal
      if(!flag){
        this.getCourse()
      }
      this.setState({
        isAssignCourseModal:!flag
      })
    }
    getCourse=()=>{
      const header = {
        "Content-Type": configJSON.notificationApiContentType,
        token: localStorage.getItem("authToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getCourseApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_profile/courses`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleCourseAssign=(Id:number,name:string)=>{
      if(this.state.assignedCourses!==-1){
        this.setState({assignedCourses:-1,assignCourseCatogory:""})
      }else{
        this.setState({assignedCourses:Id,assignCourseCatogory:name})
      }
    }
    removeAssignCourse=()=>{
      this.setState({assignedCourses:-1,assignCourseCatogory:""})
    }
  // Customizable Area End
}
