import React from "react";
// Customizable Area Start
import {
  ScrollView,
  SafeAreaView,
  StyleSheet,
} from "react-native";
import PostCreationController, { Chapter, ChapterSchema, ITitleProps, Lessons, LessonSchema, LessonTypes, Props, SubLesson, SubLessonSchema, validationSchema } from "./PostCreationController.web";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { ArrowRight, ArrowRightAlt, ArrowUpward, DragIndicator, Edit } from "@material-ui/icons";
import { Box, Grid, Typography, styled, FormControlLabel, MenuItem } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import { All, allBlack, group, groupBlack, lightVerify, darkVerify, iIcon, copyIcon, addIcon,smallBin,smallEdit, handbrakeIcon, addFileIcon, clipIcon, qrCodeIcon } from "../src/assets";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { BackButton, NextButton, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { DatePicker, Radio } from "antd";
import { EditHeading, FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import Loader from "../../../components/src/Loader.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import moment from "moment";
import { deleteIcon } from "../../customisableuserprofiles/src/assets";
import TextInput from "../../utilities/src/TextInput";
import RichTextEditor from "../../utilities/src/RichTextEditor";
import FileUpload from "../../utilities/src/FileUpload.web";
import MuiSelect from "../../utilities/src/MuiSelect";
import Button from "../../utilities/src/Button";
import CustomLabel from "../../utilities/src/Label";
import IconButton from "../../utilities/src/IconButton";
import AssessmentTest from "../../assessmenttest/src/AssessmentTest.web";
import CustomSwitch from "../../utilities/src/Switch";
import ContextMenu from "../../utilities/src/ContextMenu";

import { Checkbox as CustomCheckbox } from "../../../components/src/CustomCheckBox.web";

const ConfigJSON = require('./config.js')
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  publishModal = () => (
    <FieldModal
      open={this.state.publishModal}
      onCancel={this.closeModals}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">Publish</ModalHeading>
          <ModalLine style={{ width: '150px' }} />
          <ModalSubHead variant="subtitle2">Publish info</ModalSubHead>
          <ModalLine style={{ width: '150px' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={769}
      style={{ paddingBottom: '40px' }}
    >
      <Radio.Group
        data-test-id='changePublishType'
        value={this.state.publishType}
        onChange={this.changePublishType}
        style={{ width: "100%" }}
      >
        <div style={{ display: 'flex' }}>
          <ArrowBackIosIcon style={{ marginRight: "200px", marginLeft: '20px' }} onClick={this.closeModals} />
          <ModalHeading variant="h5">where to publish</ModalHeading>
        </div>
        <FormControlLabel
          value='all_companies'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? allBlack : All} />
            <div>
              <RadioOptionHead>All</RadioOptionHead>
              <RadioOptionSubHead>The reminder will be sent to all companies “worker”.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
        <FormControlLabel
          value='all_groups'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>All Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to all groups of all worker.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
        <FormControlLabel
          value='specific_groups'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>Specific Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to specific groups.</RadioOptionSubHead>
              <RadioOptionSubHead>Ibrahim, Ibrahim1 and 3 others</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
      </Radio.Group>
      <BackButton data-test-id="closeModalButton" onClick={this.closeModals}>Back</BackButton>
      <NextButton
        data-test-id="addCourseButton"
        style={{ width: '65%', marginLeft: '10px', color: '#FFF' }}
        onClick={this.handleCreateCourse}
        disabled={!this.state.publishType}
      >
        Send
      </NextButton>
    </FieldModal>
  )
  successModal = () => (
    <FieldModal
      data-test-id="successModal"
      open={this.state.successModalOpen}
      onCancel={this.closeSuccessModal}
      centered
      closable={false}
      footer={null}
      destroyOnClose
      width={696}
      style={{ height: '430px' }}
    >
      <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
        <img src={this.state.darkTheme ? darkVerify : lightVerify} alt="verificationModal" style={{ height: '176px' }} />
        <EditHeading data-test-id="successfullMessage" style={{ textAlign: 'center', fontSize: "28px", marginTop: "15px" }}>
          Chapter Has Been Created Successfully
        </EditHeading>
      </div>
      <NextButton
        className="publishBg"
        data-test-id="newChapterAddedButton"
        onClick={this.closeSuccessModal}
        fullWidth
        style={{ color: '#F8FAFC' }}
      >
        Done
      </NextButton>
    </FieldModal>
  )
  checkMedia = () => {
    const open = Boolean(this.state.anchorEl);
    if (this.state.imagePreview && this.state.videoPreview && this.state.uploadDoc) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <div style={{ display: "flex", maxHeight: "106px" }}>
          <img style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.imagePreview} />
          <video style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.videoPreview} />
        </div>
        <FileViewDiv >
          <MenuLines />
          <div>
            <FileNameTxt>{this.state.uploadDoc.name}</FileNameTxt>
            <FileSizeTxt>{(this.state.uploadDoc.size / 1024).toFixed(0)}{' KB'}</FileSizeTxt>
          </div>
          <CloseIcon data-test-id="removeFile" style={{ width: '16px' }} onClick={() => this.setState({ uploadDoc: null })} />
        </FileViewDiv>
      </div>
    } else if (this.state.imagePreview && this.state.videoPreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <div style={{ display: "flex", maxHeight: "106px" }}>
          <img style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.imagePreview} />
          <video style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.videoPreview} />
        </div>
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.imagePreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <img style={{ width: '90%', height: '70%', borderRadius: '20px' }} src={this.state.imagePreview} />
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.videoPreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <video style={{ width: '90%', height: '70%', borderRadius: '20px' }} src={this.state.videoPreview} />
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.uploadDoc) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <FileViewDiv >
          <MenuLines />
          <div>
            <FileNameTxt>{this.state.uploadDoc.name}</FileNameTxt>
            <FileSizeTxt>{(this.state.uploadDoc.size / 1024).toFixed(0)} {" KB"}</FileSizeTxt>
          </div>
          <CloseIcon style={{ width: '16px' }} onClick={() => this.setState({ uploadDoc: null })} />
        </FileViewDiv>
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div >
    } else {
      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'end' }}
        data-test-id="uploadMenu"
        aria-controls={open ? 'basic-menu' : undefined}
        id="basic-button"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
      >
        <CloudUploadOutlinedIcon style={{ marginTop: '100px' }} />
        <UploadText>Upload media</UploadText>
      </div>
    }
  }
  renderSuggestionBox = () => {
    const { suggestions, showSuggestions, suggestionBoxPosition } = this.state;

    if (!showSuggestions) return null;

    return (
      <div
        style={{
          position: 'absolute',
          top: suggestionBoxPosition.top,
          left: suggestionBoxPosition.left,
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
          zIndex: 1000
        }}
      >
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          {suggestions.map((sugg: any, index: any) => (
            <>
              {sugg?.suggestions.map((suggestion: any, i: any) => (
                <li
                  key={index}
                  style={{
                    padding: '8px 12px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #eee'
                  }}
                  onMouseDown={() => this.handleSuggestionClick(suggestion)}
                >
                  {suggestion}</li>
              ))}
            </>
          ))}
        </ul>
      </div>
    );
  }
  renderStepper = () => (
    <StepperContainer>
      {ConfigJSON.PostSteps.map((label: string, index: number) => (
        <StepperItem
          key={index}
          style={{
            borderBottom: this.state.step >= index ? "2px solid #1A469C" : this.state.darkTheme ? "2px solid #D0DBEB" : "2px solid #475569",
            color: this.state.step >= index ? "#1A469C" : this.state.darkTheme ? '#D0DBEB' : '#475569',
          }}
        >
          {label}
        </StepperItem>
      ))}
    </StepperContainer>
  )
  renderCourseInfoSection = ({ heading, subHeading, enableOfflineDownload = false, actionButton }: ITitleProps) => (
    <> 
      <CourseInfoSection style={{ justifyContent: enableOfflineDownload ? 'space-between' : 'flex-start', alignItems: "center", gap: '12px' }}>
        <RowStack style={{ gap: '12px', alignItems: 'center' }}>
          <CourseInfoTitle>{heading}</CourseInfoTitle>
          <hr style={{ color: "#D7D7D780", width: '310px', height: "2px" }} />
        </RowStack>
        {enableOfflineDownload && (
          <ColStack style={{ alignItems: "center" }}>
            <CourseTitleDescription style={{ width: "100%" }}>Offline Download</CourseTitleDescription>
            <CustomSwitch />
          </ColStack>
        )}
      </CourseInfoSection>
      <RowStack style={{ justifyContent: 'space-between', alignItems: "flex-end", paddingRight: "10px" }}>
        {subHeading && subHeading !== "" && (
          <CourseTitleSection>
            <RowStack style={{ width: "24px", height: "24px", alignItems:"center", justifyContent:"center" }}>
              <img src={iIcon} style={{ width:"17px", height: "17px" }} />
            </RowStack>
            <AddTypo>{subHeading}</AddTypo>
          </CourseTitleSection>
        )}
        {actionButton}
      </RowStack>
    </>
  )
  renderCourseSetting = () => (
    <Formik
      initialValues={ConfigJSON.courseSettingInitialValues}
      validationSchema={validationSchema}
      onSubmit={this.handleCreateCourse}
      data-test-id="create-course-formik"
      innerRef={f => this.formikRef.current = f}
    >
      {({ dirty, isValid, touched, values, errors, handleChange, handleSubmit, setFieldValue }) => {
        return (
          <form data-test-id="form" onSubmit={handleSubmit} style={{ flex: 1 }}>
            <Grid container style={{ height: '100%', width: "100%", flexWrap: "nowrap" }}>
              <LeftSection item xs={8} md={8} lg={8} >
                {this.renderStepper()}
                <ContainerOuter>
                  <ContainerWithBg>
                    <CourseInfoSection>
                      <RowStack style={{ gap: '12px', alignItems: 'center' }}>
                        <CourseInfoTitle>Course Info</CourseInfoTitle>
                        <hr style={{ color:"#D7D7D780", width: '310px', height:"1px" }} />
                      </RowStack>
                      <ColStack style={{ alignItems: "center" }}>
                        <CustomLabel style={{ fontWeight: 700 }}>Offline Download</CustomLabel>
                        <CustomSwitch 
                          data-test-id="downloadedSwitch"
                          value={values.downloaded} 
                          onChange={value => setFieldValue("downloaded", value)} 
                        />
                      </ColStack>
                    </CourseInfoSection>
                    <CourseDetailsSection>
                      <Box mb="22px">
                        <TextInput 
                          data-test-id="txtCourseTitle" 
                          name="course_name" 
                          required
                          label="Course Title" 
                          labelStyle={{ fontWeight: 700 }} 
                          value={values.course_name} 
                          onChange={handleChange} 
                          errorText={touched?.course_name && errors?.course_name ? errors?.course_name.toString() : ""}
                        />
                      </Box>
                      <Box mb="51px">
                        <RichTextEditor
                          data-test-id="txtDescription" 
                          label="Course Description" 
                          required
                          name="course_description"
                          labelStyle={{ fontWeight: 700 }} 
                          value={values.course_description} 
                          onChange={(e) => {
                            if (this.isEmptyRichText(e)) {
                              setFieldValue('course_description', '');
                            } else {
                              setFieldValue('course_description', e);
                            }
                          }}
                          errorText={touched?.course_description && errors?.course_description ? errors?.course_description.toString() : ""}
                        />
                      </Box>
                      <CoverUploadSection>
                        <FileUpload 
                          data-test-id="mainImageTest"
                          required
                          label="Cover Image" 
                          labelStyle={{ fontWeight: 700 }}
                          supportText="Drag and drop image , or"
                          fileType="image/*" 
                          value={values.image} 
                          onChange={e => setFieldValue('image', e)} 
                          uploadingFileIcon={addFileIcon}
                        />
                        <FileUpload 
                          data-test-id="mainVideoTest"
                          label="Promotional Video" 
                          labelStyle={{ fontWeight: 700 }} 
                          supportText="Drag and drop video , or"
                          fileType="video/*" 
                          value={values.video} 
                          onChange={e => setFieldValue('video', e)} 
                          uploadingFileIcon={addFileIcon}
                        />
                      </CoverUploadSection>
                    </CourseDetailsSection>
                  </ContainerWithBg>
                </ContainerOuter>
              </LeftSection>
              <RightSection item xs={4} md={4} lg={4}>
                <ColStack style={{ padding: '54.33px 12.46px 0', gap: "18px" }}>
                  <ColStack style={{ gap: "2px" }}>
                    <CustomLabel required style={{ fontWeight: 700 }}>Category</CustomLabel>
                    <MuiSelect
                      id="category_id"
                      name="category_id"
                      placeholder="select your category"
                      data-test-id="txtCategory"
                      value={values.category_id}
                      onChange={this.handleCategoryChange}
                      items={this.state.allCategories?.map(item => ({ value: item.attributes.id.toString(), label: item.attributes.name as string }))}
                    />
                    <CustomLabel style={{ fontWeight: 700 }}>Sub-Category</CustomLabel>
                    <MuiSelect
                      id="sub_category_id"
                      name="sub_category_id"
                      placeholder="select your sub-category"
                      data-test-id="txtSubCategory"
                      value={values.sub_category_id}
                      onChange={handleChange}
                    >
                      {this.state.allSubCategories?.map((item, index) => <MenuItem key={index} value={item.attributes.id.toString()}>{item.attributes.name}</MenuItem>)}
                    </MuiSelect>
                    <TextInput 
                      data-test-id="txtReviewTime" 
                      name="time_for_supervisor_to_review" 
                      type="number"
                      required
                      label="Days For Supervisor To Review" 
                      labelStyle={{ fontWeight: 700 }} 
                      value={values.time_for_supervisor_to_review}
                      onChange={handleChange}
                    />
                  </ColStack>
                  <ColStack style={{ gap: "6px" }}>
                    <GroupLabel>Course Duration</GroupLabel>
                    <Box>
                      <CustomLabel style={{ fontWeight: 700 }}>Start Date</CustomLabel>
                      <DateField
                        name="start_date"
                        data-test-id='start_date'
                        placement="bottomRight"
                        format="MM-DD-YYYY"
                        size="large"
                        showToday={false}
                        disabledDate={this.isDateDisabled}
                        onChange={handleChange}
                        defaultValue={values.start_date !== "" ? moment(values.start_date) : undefined}
                      />
                    </Box>
                    <Box>
                      <CustomLabel style={{ fontWeight: 700 }}>End Date</CustomLabel>
                      <DateField
                        name="end_date"
                        data-test-id='end_Date'
                        placement="bottomRight"
                        format="MM-DD-YYYY"
                        size="large"
                        showToday={false}
                        onChange={handleChange}
                        defaultValue={values.end_date !== "" ? moment(values.end_date) : undefined}
                        disabledDate={this.isDateDisabled}
                      />
                    </Box>
                  </ColStack>
                  <ColStack style={{ gap: "11px" }}>
                    <TagInputSection>
                      <CustomLabel style={{ flexGrow: 1, padding: "5px 12px", whiteSpace: "nowrap" }}>Tag Name</CustomLabel>
                      <TextInput
                        name="tags"
                        data-test-id="txtTags"
                        placeholder="Enter tag name"
                        style={{ height: "24px", borderRadius: "98px" }}
                        value={values.tags}
                        onChange={handleChange}
                      />
                    </TagInputSection>
                    <TagInputSection>
                      <CustomLabel style={{ flexGrow: 1, padding: "5px 12px", whiteSpace: "nowrap" }}>Tag Color</CustomLabel>
                      <RowStack style={{ alignItems: "center", justifyContent: "space-evenly" }}>
                        {ConfigJSON.tagColors.map(({ id, color }: { id: string; color: string }, index: number) => (
                          <div 
                            key={index} 
                            className={values.selected_tag_color === color ? "selected" : ""} 
                            style={{ borderRadius: "50%", margin: "5px" }}
                          >
                            <TagColor
                              data-test-id={id}
                              style={{ background: color }}
                              onClick={() => setFieldValue("selected_tag_color", color)}
                            />
                          </div>
                        ))}

                      </RowStack>
                    </TagInputSection>
                  </ColStack>
                  <CustomButton 
                    data-test-id='btnContinueCourse' 
                    type="submit" 
                    className="publishBg" 
                    disabled={!dirty || !isValid}
                  >Continue</CustomButton>
                </ColStack>
              </RightSection>
            </Grid>
          </form>
        )
      }}
    </Formik>
  )
  renderChapterList = () => {
    if (this.state.courseDetail.course_content_attributes.length <= 0) {
      return (
        <ColStack style={{ flex: 1, gap: "18px" }}>
          <Box>
            {this.renderCourseInfoSection({ 
              heading: "Add Chapter", 
              subHeading: "Don’t worry you can easily rearranged the order of your content by clicking the lesson and dragging it into your desired location",
            })}
          </Box>
          <RowStack style={{ height: "100vh", justifyContent: "center", alignItems: "center" }}>
            <CreateChapterButton 
              data-test-id="createChapterButton" 
              variant="outline" 
              iconRight={<img src={addIcon} style={{ width:"24px", height:"24px" }} />}
              onClick={() => this.setState({ isAddingChapter: true })}
            >
              Create Chapter 
            </CreateChapterButton>
          </RowStack>
        </ColStack>
      ) 
    }

    return (
      <ColStack style={{ flex: 1, gap: "32px" }}>  
        <Box>
          {this.renderCourseInfoSection({ 
              heading: "Chapter list", 
              subHeading: "Don’t worry you can easily rearranged the order of your content by clicking the lesson and dragging it into your desired location",
              actionButton: (
                <CustomButton 
                  size="sm"
                  style={{ width: "175px", }}
                  iconLeft={<AddCircleOutlineRoundedIcon />}
                  onClick={() => this.setState({ isAddingChapter: true })}
                >
                  <Typography style={{fontSize: "14px", fontWeight: 700, lineHeight: "22px" }}>Add Chapter</Typography></CustomButton>
              )
          })}
        </Box>
        <ScrollableSection style={{ marginTop: "-10px", flex: 1, maxHeight: "500px", paddingRight: "8px" }}>
          <ColStack style={{ gap: "10px" }}> 
            {this.state.courseDetail.course_content_attributes.map((chapter, index) => (  
              <ChapterCard key={index}>
                <ChapterHeaderBox>
                  <RowStack style={{ alignItems: "center", gap: "5px"}}>
                    <Box style={{ cursor: "grab", color: "#64748B" }}>
                      <DragIndicator />
                    </Box>
                    <ChapterTitleTypo data-test-id="chapterTitleTest" style={{ fill: "#64748B" }}>{chapter.title}</ChapterTitleTypo>
                  </RowStack>
                  <RowStack style={{ marginRight: "10px", gap: "3px"}}>
                    <TextOnWhiteButton
                      data-test-id="editChapterTest"
                      variant="ghost"
                      style={{height: '22.6px', padding: "0 8px", backgroundColor: "#fff", fontSize: '10px', lineHeight: "14px" }}
                      iconLeft={<Edit style={{ fill: "#1A469C" }} />}
                      onClick={() => this.handleEditChapterClick(index)}
                    >Edit</TextOnWhiteButton>
                    <TextOnWhiteButton 
                      data-test-id="deleteChapterTest"
                      variant="ghost"
                      style={{height: '22.6px', padding: "0 8px", color: '#DC2626', fontSize: '10px', lineHeight: "14px" }}
                      onClick={() => this.removeChapter(index)}
                      iconLeft={<img src={deleteIcon} style={{ height: '16px' }} />}
                    >Delete</TextOnWhiteButton>
                  </RowStack>
                </ChapterHeaderBox>
                <ChapterBody>
                  {chapter.lessons_attributes?.length === 0 && <NoLessonTypo data-test-id="noLessonsTest">No lessons yet</NoLessonTypo>}
                  <ScrollableSection>
                    {chapter.lessons_attributes?.map((lesson, lesson_index) => {
                        const isExpanded = this.state.expandedList[`${index}-${lesson_index}`];
                        return (
                          <Box style={{ maxHeight: "188px" }}>
                          <React.Fragment key={lesson_index}>
                            <AccordionSummary>
                              <RowStack style={{paddingLeft: '8px'}}>
                                <ExpandIcon 
                                  data-test-id="expandBtn"
                                  style={{ transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)", fill: this.state.darkTheme ? '#FFFFFF' : '#1F1F1F'}} 
                                  onClick={() => this.toggleExpandedList(index, lesson_index)}
                                />
                                <Box style={{ color: '#334155' }} onClick={() => this.toggleExpandedList(index,lesson_index)} data-test-id="lessonNameTest">{lesson.title}</Box>
                              </RowStack>  
                              {isExpanded && (
                                <RowStack>
                                  <IconButton 
                                    data-test-id="lessonEditTest" 
                                    style={{ padding: 0, height: "auto" }}
                                    onClick={() => this.handleEditLessonClick(index, lesson_index)}
                                  >
                                    <img src={smallEdit} style={{ width: "16px", height: "16px" }} />
                                  </IconButton>
                                  <IconButton 
                                    data-test-id="lessonDeleteTest" 
                                    style={{ padding: 0, height: "auto" }}
                                    onClick={() => this.removeLesson(index, lesson_index)}
                                  >
                                    <img src={smallBin} style={{ width: "16px", height: "16px" }} />
                                  </IconButton>
                                </RowStack>)}
                            </AccordionSummary>
                            {isExpanded && (
                                  <AccordionDetails>
                                    {lesson.sub_lessons_attributes.map((sub, sub_index) => (
                                          <RowStack key={sub_index} style={{ gap: "1px", alignItems: "center" }}>
                                            <Box style={{ cursor: "grab", color: "#64748B" }}>
                                              <DragIndicator fontSize="small" />
                                            </Box>
                                            <span data-test-id="subLessonNameTest">{sub.title}</span>
                                            <RowStack style={{ gap: "1px" }}>
                                              <IconButton 
                                                data-test-id="subLessonEditTest" 
                                                style={{ padding: 0, height: "auto" }}
                                                onClick={() => this.handleEditSubLessonClick(index, lesson_index, sub_index)}
                                              >
                                                <img src={smallEdit} style={{ width: "16px", height: "16px" }} />
                                              </IconButton>
                                              <IconButton 
                                                data-test-id="subLessonDeleteTest" 
                                                style={{ padding: 0, height: "auto" }}
                                                onClick={() => this.removeSubLesson(index, lesson_index, sub_index)}
                                              >
                                                <img src={smallBin} style={{ width: "16px", height: "16px" }} />
                                              </IconButton>
                                            </RowStack>
                                          </RowStack>
                                    ))}
                                    <Button
                                      data-test-id='addLesson'
                                      variant="ghost"
                                      size="sm"
                                      iconLeft={<AddCircleOutlineRoundedIcon fontSize="small" />}
                                      style={{ width: "fit-content", height: "22px", padding: "revert" }}
                                      onClick={() => this.handleAddSubLessonClick(index, lesson_index)}
                                    >
                                      Add Lesson
                                    </Button>
                                  </AccordionDetails>
                            )}
                          </React.Fragment>
                          </Box>
                        )
                    })}
                  </ScrollableSection>
                  <RowStack style={{ justifyContent: "end", padding: "0 0 20px", gap: "5px" }}>
                    <CustomButton
                      data-test-id='addMainLesson'
                      onClick={() => this.handleAddLessonClick(index)}
                      iconLeft={<AddCircleOutlineRoundedIcon />}
                      variant="outline"
                      size="sm"
                      style={{ width: "147px", backgroundColor: this.state.darkTheme ? '#0D0D0D' : '#FFFFFF'}}
                    >Add Lesson</CustomButton>
                    <CustomButton
                      data-test-id='copyLessonBtn'
                      iconLeft={<img src={copyIcon} />}
                      variant="ghost"
                      size="sm"
                    >
                      Copy lesson from
                    </CustomButton>
                  </RowStack>
                </ChapterBody>
              </ChapterCard>
            ))}
          </ColStack>
        </ScrollableSection>
        <RowStack style={{ justifyContent: "flex-end" }}>
          <CustomButton 
            iconRight={<ArrowRightAlt />}
            style={{ width: "294px", gap: '12px'}} 
            onClick={this.handleUpdateCurriculum}
          >Next</CustomButton>
        </RowStack>
      </ColStack>
    )
  }
  renderChapterForm = (editable?: boolean, values?: Chapter) => (
    <>
      {this.renderCourseInfoSection({ 
        heading: !editable ? "Add Chapter" : "Edit Chapter", 
        subHeading: "Don’t worry you can easily rearranged the order of your content by clicking the lesson and dragging it into your  desired location",
      })} 
      <NewChapterBox>
        <CourseInfoSection style={{ padding: 0 }}>
          <RowStack style={{ gap: '12px', alignItems: 'center' }}>
            <CourseInfoTitle>{!editable ? 'New chapter' : values?.title}</CourseInfoTitle>
            <hr style={{ color:"#D7D7D780", width: '310px', height:"1px" }} />
          </RowStack>
        </CourseInfoSection>
        <Formik
          initialValues={ConfigJSON.chapterInitialValues}
          validationSchema={ChapterSchema}
          onSubmit={this.handleChapter}
          data-test-id="create-chapter-formik"
          innerRef={f => this.chapterFormikRef.current = f}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ flex: 1 }}>
              <TextInput
                name="title"
                data-test-id="txtChapterTitle"
                label="Title"
                labelStyle={{ fontWeight: 700 }}
                placeholder="Enter chapter title"
                onChange={handleChange}
                value={values.title}
              />
              <RowStack style={{ alignItems: "center", justifyContent: "center", gap: "63px", width: "70%", marginRight: "auto", marginLeft: "auto", marginTop: '31px' }}>
                <Button
                  className="cancelChapterTitle"
                  data-test-id="cancelChapterTitle1"
                  onClick={this.handleCancelChapterFormClick}
                  variant="outline"
                  style={{ width: "100%", borderRadius: "30px" }}
                >
                  Cancel
                </Button>
                {editable ?
                  <Button
                    className="publishBg"
                    type="submit"
                    data-test-id='editChapterButton'
                    style={{ width: "100%", borderRadius: '30px' }}
                  >
                    Save
                  </Button>
                  : 
                  <Button
                    type="submit"
                    className="publishBg"
                    data-test-id='addChapterTitle'
                    style={{ width: "100%", borderRadius: '30px' }}
                  >
                    Create
                  </Button>
                }
              </RowStack>
            </form>
          )}
        </Formik>
      </NewChapterBox>

    </>
  )
  renderLessonForm = (editable?: boolean, values?: Lessons) => (
    <ColStack style={{ gap: "5px" }}>
      {this.renderCourseInfoSection({ 
        heading: this.state.courseDetail.course_content_attributes[this.state.editableIndex].title || '', 
        enableOfflineDownload: true
      })} 
      <Formik
        initialValues={!editable ? ConfigJSON.chapterInitialValues : values}
        validationSchema={LessonSchema}
        onSubmit={values => {if (editable){ this.updateLesson(values) }}}
        data-test-id="create-lesson-formik"
        innerRef={f => this.lessonFormikRef.current = f}
      >
        {({ values, errors, touched, handleChange }) => {
          const isDisabled = values.title === "";
          return (
            <ColStack style={{ gap: "32px" }}>
              <TextInput
                name="title"
                data-test-id="txtLessonTitle"
                label="Title"
                placeholder="Add Lesson Title"
                labelStyle={{ fontWeight: 700 }}
                value={values.title}
                onChange={handleChange}
                error={!!errors.title && !!touched.title}
                errorText={errors.title as string}
              />
              {!editable || this.state.isSelectingSubLessonType ? (
                <LessonTypeSelect 
                  style={{ 
                    opacity: isDisabled ? "0.2": "1.0",
                    pointerEvents: isDisabled ? "none" : "auto" 
                  }}
                >
                  <Grid container spacing={2}>
                    {LessonTypes.map(({ name, icon }: { name: string; icon: string }) => (
                      <Grid item key={name}>
                        <LessonTypeDiv data-test-id={`${name}Lesson`} onClick={() => this.handleLesson(values.title, name)}>
                          <img src={icon} />
                          <LessonTypeText>{name}</LessonTypeText>
                        </LessonTypeDiv>
                      </Grid>
                    ))}
                  </Grid>
                  <CustomCheckbox label="Mark this chapter as pre-requisite*"/>
                </LessonTypeSelect>)
                : (
                  <ButtonGroup>
                    <Button
                      className="cancelChapterTitle"
                      data-test-id="cancelChapterTitle1"
                      onClick={() => this.setState({ isEditingLesson: false })}
                      variant="outline"
                      style={{ width: "100%", borderRadius: "30px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="publishBg"
                      data-test-id='editLessonButton'
                      onClick={() => this.updateLesson({ title: values.title })}
                      style={{ width: "100%", borderRadius: '30px' }}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                )
              }
            </ColStack>
          )
        }}
      </Formik>
    </ColStack>
  )
  renderCopySubLessonForm = () => (
    <ColStack style={{ gap: "16px" }}>
      <RowStack style={{ alignItems: "flex-end", gap: "10px" }}>
        <ColStack style={{ width: "66%", gap: "16px" }}>
          <RowStack style={{ gap: "4px" }}>
            <img src={copyIcon} />
            <CustomLabel style={{ fontWeight: 700 }}>Copy Lesson From</CustomLabel> 
          </RowStack>
          <RowStack style={{ gap: "10px" }}>
            {/* <ContextMenu 
              onChange={value => console.log(value)} 
              defaultValueObject={{
                label: "",
                value: ""
              }} 
            /> */}
            <MuiSelect
              name="course_id"
              placeholder="Select course"
              data-test-id="courseSelect"
              value={this.state.courseId}
            >
              <MenuItem value={this.state.courseId.toString()}>New course (this course)</MenuItem>
            </MuiSelect>
            <MuiSelect
              name="sub_lesson_id"
              placeholder="Select lesson"
              data-test-id="subLessonSelect"
              value={-1}
            ></MuiSelect>
          </RowStack>
        </ColStack>
        <ColStack>
          <TextInput data-test-id="lessonTime" label="Lesson Timer in Minutes and Seconds" type="time" />
        </ColStack>
      </RowStack>
      <TextInput
        data-test-id="optionalText"
        placeholder="Add text (optional)"
        style={{ border: "none" }}
      />
    </ColStack>
  )
  renderSubLessonForm = (editable?: boolean, values?: SubLesson) => {
    const currentChapterIndex = this.state.editableIndex;
    const currentLessonIndex = this.state.editableLessonIndex;
    const currentSubLessonIndex = this.state.editableSubLessonIndex;
    const type = this.state.courseDetail.course_content_attributes[currentChapterIndex].lessons_attributes[currentLessonIndex].sub_lessons_attributes[currentSubLessonIndex].lesson_type;
    
    return (
      <>
        {this.renderCourseInfoSection({ 
          heading: this.state.courseDetail.course_content_attributes[this.state.editableIndex].lessons_attributes[this.state.editableLessonIndex].title || '', 
          enableOfflineDownload: true
        })} 
        <ScrollableSection style={{ marginTop: '22px', paddingRight: '18px' }}>
          {type !== "quiz"
            ? (
              <Formik
                initialValues={!editable ? { ...ConfigJSON.subLessonInitialValues, lesson_type: type } : values}
                validationSchema={SubLessonSchema}
                onSubmit={this.updateSubLessonAndContinue}
                data-test-id="create-sublesson-formik"
                innerRef={f => this.subLessonFormikRef.current = f}
              >
                {({ values, dirty, isValid, handleChange, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit} style={{ flex: 1 }}>
                    <FormLayout>
                      <ColStack style={{ gap: '16px' }}>
                        <SubLessonFormTitle>{type} <span>lesson</span></SubLessonFormTitle>
                        <TextInput 
                          data-test-id="textLessonTitle" 
                          name="title" 
                          label="Lesson Title" 
                          labelStyle={{ fontWeight: 700 }}
                          value={values.title} 
                          onChange={handleChange}
                        />
                        {type === "video" && (
                          <>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }} >Videos From Your Library</CustomLabel>
                              <FileUpload 
                                withoutPreview
                                fileType="video/*"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }} >Upload Video File</CustomLabel>
                              <FileUpload 
                                withoutPreview
                                fileType="video/*"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            <CustomLabel>
                              Max size GB. we suggest compressing your video using Handbrake<a href="https://handbrake.fr/">{" Handbrake"}<img src={handbrakeIcon} style={{ width:"20px",height:"20px" }} /></a>
                            </CustomLabel>
                          </> 
                        )}
                        {type === "text" && (
                          <RichTextEditor 
                            data-test-id="textLessonContent" 
                            name="content" 
                            label="Content" 
                            labelStyle={{ fontWeight: 700 }} 
                            placeholder="Add your content here"
                            value={values.content}
                            onChange={handleChange}
                          />)}
                        {type === "pdf" && (
                          <>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }} >Upload Pdf File</CustomLabel>
                              <FileUpload 
                                withoutPreview
                                fileType="application/pdf"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            <CustomLabel>You can upload files with the extension :pdf</CustomLabel>
                          </> 
                        )}
                        {type === "audio" && (
                          <>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }}>Audio from your library </CustomLabel>
                              <FileUpload 
                                withoutPreview
                                fileType="audio/*"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }}>Upload Audio File</CustomLabel>
                              <FileUpload 
                                withoutPreview
                                fileType="audio/*"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                          </> 
                        )}
                        {type === "multimedia" && (
                          <>
                            <ColStack style={{ gap: "2px" }}>
                              <CustomLabel style={{ fontWeight: 700 }}>Content</CustomLabel>
                              <CustomLabel>Use externally hosted content </CustomLabel>
                            </ColStack>
                            <TextInput 
                              data-test-id="multimediaURLText" 
                              name="multimediaURL" 
                              label="URL (to your webinar tool link)"
                              labelStyle={{ fontWeight: 700 }}
                              type="url"
                              placeholder="Ex:"
                              value={values.multimediaURL} 
                              onChange={handleChange}
                            />
                            <RowStack>
                              <Link>Learn more about how they can be used in your Multimedia Lesson</Link>
                              <ArrowUpwardIcon />
                            </RowStack>
                          </> 
                        )}
                        {type === "presentation" && (
                          <>
                            <CustomLabel style={{ fontWeight: 700 }}>Upload PPT or PPTX file</CustomLabel>
                            <FileUpload 
                              withoutPreview
                              fileType="application/ppt"
                              value={values.files} 
                              onChange={e => setFieldValue('files', e)} 
                            />
                            <CustomLabel>
                              You can upload files with the extension :PPT or PPTX
                            </CustomLabel>
                            <CustomLabel>
                              For increased student engagement, we recommend keeping your presentation under 20 slides.
                            </CustomLabel>
                          </> 
                        )}
                        {type === "interactive training" && (
                          <>
                            <CustomLabel>Upload file</CustomLabel>
                            <FileUpload 
                              withoutPreview
                              fileType="*"
                              value={values.files} 
                              onChange={e => setFieldValue('files', e)} 
                            />
                          </> 
                        )}
                         {type === "image" && (
                            <>
                              <CustomLabel>Upload Image</CustomLabel>
                              <FileUpload 
                                fileType="*"
                                uploadingFileIcon={addFileIcon}
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                          </> 
                        )}
                      </ColStack>
                      {this.renderCopySubLessonForm()}
                      <RowStack style={{ justifyContent: "space-evenly" }}>
                        <CustomButton
                          data-test-id="editCloseButton"
                          variant="outline"
                          onClick={() => this.updateSubLessonAndClose(values)}
                          disabled={!dirty || !isValid}
                          style={{ width: '40%' }}
                        >Save and Close</CustomButton>
                        <CustomButton
                          data-test-id="saveAddButton"
                          type="submit"
                          disabled={!dirty || !isValid}
                          style={{ width: '40%' }}
                        >Save and Add New Lesson Type</CustomButton>
                      </RowStack>
                    </FormLayout>
                  </form>
                )}
              </Formik>
            )
            : <AssessmentTest navigation={this.props.navigation} quiz_id={this.state.quiz_id} onSaveAndClose={this.updateSubLessonAndClose} onAddNewLesson={this.updateSubLessonAndContinue} courseId={this.state.courseId} id="AssessmentTest" />
          } 
        </ScrollableSection>
      </>
    )
  }
  renderCurriculum = () => {
    const currentChapterIndex = this.state.editableIndex;
    const currentLessonIndex = this.state.editableLessonIndex;
    const currentSubLessonIndex = this.state.editableSubLessonIndex;
    const currentChapterList = this.state.courseDetail.course_content_attributes;

    return (
      <>
        <ColStack style={{ width: '95%', minHeight: "100vh", gap: '20px' }}>
          {this.renderStepper()}
          <ColStack style={{ flex: 1 }}>
            {!this.state.isAddingChapter && !this.state.isEditingChapter && !this.state.isSelectingSubLessonType && !this.state.isAddingLesson
              && !this.state.isEditingLesson && !this.state.isAddingSubLesson && !this.state.isEditingSubLesson && this.renderChapterList()}
            {this.state.isAddingChapter && this.renderChapterForm()} 
            {this.state.isEditingChapter && this.renderChapterForm(true, currentChapterList[currentChapterIndex])}
            {this.state.isAddingLesson && this.renderLessonForm()} 
            {(this.state.isEditingLesson || this.state.isSelectingSubLessonType)
              && this.renderLessonForm(
                true, 
                currentChapterList[currentChapterIndex].lessons_attributes[currentLessonIndex]
            )} 
            {this.state.isAddingSubLesson && this.renderSubLessonForm()}
            {this.state.isEditingSubLesson && this.renderSubLessonForm(
              true,
              currentChapterList[currentChapterIndex].lessons_attributes[currentLessonIndex].sub_lessons_attributes[currentSubLessonIndex]
            )} 
  
          </ColStack>
        </ColStack>
        {this.publishModal()}
        {this.successModal()}
      </>
    )
  }

  renderMedia = () => (
    <>
      {this.renderStepper()}
      <Grid container spacing={2} style={{ marginTop: "27px" }}>
        <Grid item xs={8}>
          <FocusableContainer style={{ padding: "34px 20px" }}>
            <ScrollableSection style={{ padding: "10px" }}>
              {this.renderCourseInfoSection({ heading: "Add file&media", enableOfflineDownload: true })}
              <Box style={{ marginTop: "23px" }}>
                <CustomLabel style={{ fontWeight: 700 }}>Upload Media</CustomLabel>
                <Box style={{ marginTop: "4px" }}>
                  <FileUpload 
                    fileType="image/*"
                    uploadingFileIcon={addFileIcon}
                  />
                </Box>
                <Box style={{ marginTop: "17px" }}>
                  <FileUpload 
                    fileType="image/*"
                    uploadingFileIcon={addFileIcon}
                  />
                </Box>
                <AddFileBox style={{ marginTop: "30px" }}>
                  <CustomLabel style={{ fontWeight: 700 }}>Attach file</CustomLabel>
                  <img src={clipIcon} style={{ width: "24px", height: "24px" }}/>
                </AddFileBox>
                <RowStack style={{ marginTop: "28px", alignItems: "center", justifyContent: "flex-end" }}>
                  <CustomButton style={{ width: "50%" }}>Save</CustomButton>
                </RowStack>
              </Box>
            </ScrollableSection>
          </FocusableContainer>
        </Grid>
        <Grid item xs={4}>
          <FocusableContainer style={{ padding: "34px 20px" }}>
            <ColStack style={{ height: '100%' }}>
              <Box style={{ flex: 1 }}>

              </Box>
              <ColStack style={{ gap: "4px", alignItems: "center", justifyContent: "center" }}>
                <CustomButton
                  variant="ghost"
                  iconRight={<img src={qrCodeIcon} style={{ width: "24px",height: "24px" }} />}
                  fullWidth
                >Generate QR Code</CustomButton>
                <CustomButton fullWidth>Next</CustomButton>
              </ColStack>
            </ColStack>
          </FocusableContainer>
        </Grid>
      </Grid>
    </>
  )
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView style={styles.fullHeight}>
          {/* Customizable Area Start */}
          <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
            <MainLayout>
              <Loader loading={this.state.loading} />
              <HamburgerMenu navigation={this.props.navigation} handleMenu={this.leftMenuHandle} open={this.state.openLeftMenu} handleTheme={this.handleTheme} themeDark={this.state.darkTheme} />
              <NavigationMenu id="NavigationMenu" navigation={this.props.navigation} open={this.state.openLeftMenu} theme={this.state.darkTheme} />
              <MainContainer style={{ paddingLeft: this.state.openLeftMenu ? 273 : 150 }}>
                {this.state.step === 0 && this.renderCourseSetting()}
                {this.state.step === 1 && this.renderCurriculum()}
                {this.state.step === 2 && this.renderMedia()}
              </MainContainer>
            </MainLayout>
          </ThemeProvider >
          {/* Customizable Area End */}
        </ScrollView>
      </SafeAreaView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    alignSelf: "center",
    width: "100%",
  },
  fullScreen: {
    height: "100vh"
  },
  fullHeight: {
    height: "100vh",
    width: "100%",
  }
})
const RowStack = styled(Box)({ display: 'flex' })
const ColStack = styled(Box)({ display: 'flex', flexDirection: 'column' })
export const ScrollableSection = styled(Box)({
  overflowX: "hidden",
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '13px'
  },
  '&::-webkit-scrollbar-track': {
    border: '0.94px solid #CBD5E1'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '28.34px 28.34px 85.01px',
    backgroundColor: '#EDF1F6',
    border: '0.94px solid #94A3B8'
  }
})
const CustomButton = styled(Button)({
  borderRadius: "30px"
})
const TextOnWhiteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.info.light,
  "&:disabled": { backgroundColor: "#fff", color: "#000" }
}))
const Link = styled('a')(({ theme }) => ({
  lineHeight: "22px",
  color: theme.palette.info.contrastText,
  textDecorationLine: "none",
  textDecorationColor: theme.palette.info.contrastText,
  "&:hover": { 
    color: theme.palette.info.contrastText,
    textDecorationLine: "underline",
    textDecorationColor: theme.palette.info.contrastText,
  }
}))
const ArrowUpwardIcon = styled(ArrowUpward)({
  transform: "rotate(24.43deg)",
  fill: "#1A469C !important",
  color: "#1A469C"
})
const MainLayout = styled(Box)({
  height: "100vh",
  width: "100%",
  overflowX: 'hidden'
})
export const FocusableContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  borderRadius: "18px",
  backgroundColor: theme.palette.action.focus,
}))
const CreateChapterButton = styled(Button)({
  width: "294px", 
  borderRadius: "30px",
  border: "1px solid #1A469C",
  backgroundColor: "#FFF"
})
const MainContainer = styled(Box)(({ theme }) => ({
  paddingTop: '10px',
  height: 'fit-content',
  minHeight: '100vh',
  background: theme.palette.background.default,
  paddingRight: 48,
  paddingBottom: 50,
  marginLeft:"40px",
  transition: 'all 100ms linear',
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline::after': {
    borderBottom: 'transparent'
  },
  "& .MuiInput-underline::before": {
    borderBottom: 'transparent'
  },
  // '& .MuiSvgIcon-root': {
  //   fill: '#94A3B8'
  // },
  "& .ant-select": {
    width: "auto",
    height: '38px',
    fontSize: '14px',
    fontFamily: 'Rubik',
    lineHeight: '18px',
    fontWeight: 400,
    color: theme.palette.secondary.main,
  },
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    minWidth: '91px',
    borderRadius: "8px",
    padding: '4px 12px',
    height: '38px',
    borderColor: theme.palette.info.dark,
    background: theme.palette.background.default
  },
  "& .ant-select-dropdown": {
    fontFamily: 'Rubik',
    width: 'auto !important',
    fontSize: '12px',
    fontWeight: 400,
    background: theme.palette.action.focus,
    minWidth: "200px",
  },
  "& .ant-select-arrow": {
    color: theme.palette.info.main
  },
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.primary.main,
  },
}))
const LeftSection = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  borderRadius: '18px',
  marginRight: '10px',
})
const ContainerWithBg = styled(Box)(({ theme }) => ({
  height: '100%',
  marginRight: '10px',  
  overflowY: 'scroll',
  paddingRight: '22px',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.action.focus,
  '&::-webkit-scrollbar': {
    width: '13px'
  },
  '&::-webkit-scrollbar-track': {
    border: '0.94px solid #CBD5E1'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '28.34px 28.34px 85.01px',
    backgroundColor: '#EDF1F6',
    border: '0.94px solid #94A3B8'
  }
}))
const ContainerOuter = styled(Box)(({ theme }) => ({
  borderRadius: '18px',
  padding: '43px 14px',
  flex: 1,
  background: theme.palette.action.focus,
}))
const ChapterCard = styled(Box)(({ theme }) => ({
  minHeight: "109px",
  border: `1px solid ${theme.palette.info.dark}`,
  borderRadius: "8px",
}))
const RightSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  flexDirection: 'column',
  "& .publishBg:hover": {
    background: theme.palette.info.contrastText
  },
  "& .selected": {
    border: `1.5px solid ${theme.palette.info.contrastText}`
  }
}))
const TagInputSection = styled(RowStack)(({ theme }) => ({
  borderRadius: "18px", 
  alignItems: "center", 
  justifyContent: "space-between", 
  backgroundColor: theme.palette.background.default, 
  padding: "9px 8px"
}))
const CourseInfoSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingLeft: "6px"
})
const CourseTitleSection = styled(RowStack)({
  alignItems: 'center',
  gap: "8px"
})
const CourseTitleDescription = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '21px',
  textAlign: 'left',
  color: theme.palette.info.main,
}))
const AddTypo = styled(Typography)(({theme})=>({
  fontFamily: "Rubik",
  fontSize: "12px",
  lineHeight: "21px",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: theme.palette.text.primary,
}))
const CourseInfoTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  lineHeight: '38px',
  fontWeight: 500,
  color: theme.palette.secondary.main
}))
const NoLessonTypo = styled(Typography)({
  paddingLeft: "13px",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "21px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155"
})
const TagColor = styled(Box)({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  margin: "3px",
  padding: "10px"
})
const LessonTypeSelect = styled(ColStack)(({ theme }) => ({
  width: "100%",
  padding: "40px 20px",
  gap: "12px",
  borderRadius: "20px",
  backgroundColor: theme.palette.action.focus
}))
const LessonTypeDiv = styled(ColStack)(({ theme }) => ({
  margin: "8px",
  width: "157px",
  height: "135px",
  borderRadius: "12px",
  backgroundColor: theme.palette.action.active,
  alignItems: "center",
  justifyContent: "center",
  cursor: 'pointer',
}))
const ChapterHeaderBox = styled(RowStack)(({ theme }) => ({
  padding: "8px 4px",
  borderRadius: "7px",
  backgroundColor: theme.palette.action.focus,
  justifyContent: "space-between",
  alignItems: "center",
  height: '42px'
}))
const ChapterBody = styled(ColStack)(({ theme }) => ({
  // gap: "32px",
  padding: "8px",
  borderRadius: "0 0 8px 8px",
  backgroundColor: theme.palette.background.default,
}))
const AccordionSummary = styled(RowStack)({
  alignItems: "center", 
  gap: "5px"
})
const AccordionDetails = styled(ColStack)({
  marginTop: "5px",
  paddingLeft: "27px",
  gap: "4px"
})
const ExpandIcon = styled(ArrowRight)({
  fill: "#1F1F1F",
  transition: "transform 0.2s ease",
  cursor: "pointer"
})
const AddFileBox = styled(RowStack)({
  padding: "10px",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  backgroundColor: "#FFF",
  justifyContent: "space-between",
  alignItems:"center",
})
const CourseDetailsSection = styled(Box)({
  marginTop: '12px',
  width: "100%",
  height:"376px",
  gap: "10px",
  borderRadius: "18px",
  '& .modalTextEditor .ql-container.ql-snow': {
    height: '100%',
    border: 'none'
  }
})
const FormLayout = styled(ColStack)(({ theme }) => ({
  padding: "34px 18px",
  width: "100%",
  borderRadius: "18px",
  gap: "16px",
  backgroundColor: theme.palette.action.focus,
  '& .modalTextEditor .ql-container.ql-snow': {
    height: '100%',
    border: 'none'
  }
}))
const NewChapterBox = styled(ColStack)(({theme}) => ({
    padding: "38px 25px 143px",
    gap: "10px",
    borderRadius: "18px",
    backgroundColor: theme.palette.action.focus
}))
const CoverUploadSection = styled(RowStack)({
  width: "100%",
  maxHeight: "271px",
  gap: '28px'
})
const UploadText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '26px',
  color: theme.palette.text.secondary
}))
const GroupLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: "26px",
  color: theme.palette.info.main
}))
const ChapterTitleTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  color: theme.palette.info.main
}))
const LessonTypeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '30px',
  textTransform: "capitalize",
}))
export const RadioDiv = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
})
export const RadioOptionHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.41px',
  color: theme.palette.info.main,
  marginLeft: '10px'
}))
export const RadioOptionSubHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  color: theme.palette.info.light,
  marginLeft: '10px'
}))
const SubLessonFormTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  textTransform: "capitalize",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "24px",
  color: theme.palette.info.main
}))
export const FileNameTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
}))
export const FileSizeTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}))
export const FileViewDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'row',
  padding: '8px',
  justifyContent: 'space-around',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  alignItems: 'center'
}))
export const MenuLines = styled(MenuIcon)(({ theme }) => ({
  height: '42px',
  width: '42px',
  background: theme.palette.background.paper,
  padding: '12px',
  borderRadius: "6px"
}))
const DateField = styled(DatePicker)(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100%',
  padding: '10px 12px',
  borderRadius: '8px',
  border: `0.94px solid ${theme.palette.info.dark}`,
  margin: '5px auto',
  '& .ant-picker-input > input': {
    color: theme.palette.info.light,
    fontFamily: 'Rubik',
    fontSize: '13.22px',
    fontWeight: 700,
    lineHeight: '20.78px',
    textAlign: 'left',
    "&:placeholder-shown": { fontWeight: 400 }
  },
  '& .ant-picker-suffix': {
    color: theme.palette.info.light
  },
  '& .ant-picker-input:hover .ant-picker-clear': {
    opacity: 1,
    display: 'none !important'
  },
  '& .ant-picker-focused': {
    boxShadow: 'none'
  }
}))
const StepperContainer = styled(RowStack)({ width: "100%" })
const StepperItem = styled(Box)({
  width: '97px',
  paddingBottom: '12px',
  fontSize: '12px',
  fontWeight: 'bolder',
  lineHeight: '18px',
  textAlign: 'center',
})
const ButtonGroup = styled(RowStack)({
  width: "70%", 
  marginRight: "auto", 
  marginLeft: "auto", 
  marginTop: '31px',
  alignItems: "center", 
  justifyContent: "center", 
  gap: "63px"
})
// Customizable Area End
