import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { IExam, IStudent } from './types/types';
import { Moment } from 'moment';

import { setStorageData } from "../../../framework/src/Utilities";
export type FieldValue = string | number | boolean | Date | null | Moment;

export interface CourseResponse {
  data: {
    data: Course[];
  };
  meta: MetaData;
}

export interface CategoryData {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}



export interface Course {
  id: number;
  type: string;
  attributes: CourseAttributes;
  course_contents: CourseContentsData;
}

interface CourseAttributes {
  course_name: string;
  category_name: string;
  course_description: string;
  category: string | null;
  sub_category: string | null;
  sub_sub_category: string | null;
  language: string | null;
  price: string;
  level: string | null;
  tags: string | null;
  duration: string;
  year: string;
  is_assigned: boolean;
  is_completed: boolean;
  profile_id: number;
  role_id: string;
  preferred_name: string | null;
  media_type: string | null;
  publish_type: string | null;
  group_names: string | null;
  status: string;
  curriculum_outline: string;
  tag_color: string;
  due_date: string | null;
  folder_ids: number[];
  user_library_ids: number[];
  course_contents_count: number;
  image: string | null;
  video_url: string | null;
  document_url: string | null;
  upload_media: UploadMediaData;
  quizzes: QuizData;
  folders: FolderData;
  user_libraries: UserLibraryData;
}

interface CategoryAttributes {
  id: number;
  name: string;
  activated: boolean;
  dark_icon: string | null;
  dark_icon_active: string | null;
  dark_icon_inactive: string | null;
  light_icon: string | null;
  light_icon_active: string | null;
  light_icon_inactive: string | null;
  rank: number | null;
  created_at: string;
  updated_at: string;
  selected_sub_categories: string | null;
}

interface UploadMediaData {
  data: UploadMedia[];
}

interface UploadMedia {
  id: string;
  type: string;
  attributes: UploadMediaAttributes;
}

interface UploadMediaAttributes {
  title: string;
  file_name: string;
  file_size: string;
  file_type: string;
  media_type: string | null;
  related_to: string | null;
  course_id: number;
  media_url: string;
  profile_id: string;
}

interface QuizData {
  data: any[];
}

interface FolderData {
  data: any[];
}

interface UserLibraryData {
  data: any[];
}

interface CourseContentsData {
  data: CourseContent[];
}

interface CourseContent {
  id: number;
  type: string;
  attributes: CourseContentAttributes;
}

interface CourseContentAttributes {
  course_id: number;
  title: string;
  lesson_name: string;
  lesson_number: number;
  video: string | null;
  document: string | null;
  duration: string | null;
  offline_download: boolean;
  file: string | null;
}

interface MetaData {
  message: string;
  current_page: number;
  total_pages: number;
}

// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loginToken: string;
  courseExams: IExam[];
  createTestModalVisible: boolean;
  addTestScoreModalVisible: boolean;
  courseId: string;
  testName: string;
  credits: string;
  passPercentage: string;
  studentList: IStudent[];
  currentStudent: IStudent | null;
  newTestScore: string;
  currentTest: IExam | null;
  currentStudentId: string;
  darkTheme:boolean;
  open:boolean;
  totalPage:number;
  currentPage:number;
  selectedRadio: string[];
  anchorSort: null | HTMLElement;
  enableSearchBar: boolean;
  noofCourse: boolean;
  noofCertificates: boolean;
  selectedCompany: string;
  selectedGroup: string;
  selectedCourse: string;
  selectedStatus: string;
  allGroupName: { [key: string]: FieldValue }[];
  sortType: string;
  isLoading:boolean;
  enableExpDate: boolean;
  enableRedLabel: boolean;
  searchValue: string;
  overallProgress:boolean;
  courseAllData:CourseResponse;
  currentPageData:Course[];
  isPageDropdownOpen: boolean;
  selectedPageSize: number,
  SelectedCourseTab: string,
  isDownloadDropdownOpen: boolean;
  dropdownOpen: boolean
  DownloadDropdownOption: string;
  openDropdownId:  number | null;
  searchQuery: string;
  categoryAllData: {
    data: CategoryData[];
  }
  categorySelectedOption: number | null;
  is_assigned: boolean,
  downloaded: boolean,
  is_completed: boolean,
  roleId: string,
  isCategoryReset: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PtTestsForCourseScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCourseExamsApiCallId: string = '';
  deleteTestApiCallId: string = '';
  createTestApiCallId: string = '';
  getStudentListApiCallId: string = '';
  changeTestScoreApiCallId: string = '';
  getAllCoursesAPIId:string='';
  getAllCategoryAPIId:string='';
  apiProfileGETCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loginToken: '',
      courseExams: [],
      createTestModalVisible: false,
      addTestScoreModalVisible: false,
      courseId: '',
      currentTest: null,
      currentStudent: null,
      testName: '',
      credits: '',
      passPercentage: '',
      studentList: [],
      newTestScore: '',
      currentStudentId: '',
      darkTheme:false,
      open:false,
      totalPage:1,
      currentPage:1,
      selectedCompany: "",
      selectedCourse: "",
      selectedGroup: "",
      selectedStatus: "",
      enableExpDate: false,
      allGroupName: [],
      enableRedLabel: false,
      searchValue: "",
      anchorSort: null,
      enableSearchBar: false,
      noofCourse:false,
      noofCertificates:false,
      sortType: "",
      isLoading:false,
      selectedRadio:[],
      overallProgress:false,
      courseAllData: {
        data: {
          data: [],
        },
        meta: {
          message: "",
          current_page: 1,
          total_pages: 1
        }
      },
    currentPageData:[],
      isPageDropdownOpen: false,
      selectedPageSize: 5,
      SelectedCourseTab: 'is_assigned',
      isDownloadDropdownOpen: false,
      dropdownOpen: false,
      DownloadDropdownOption: '',
      openDropdownId: null,
      searchQuery: "",
      categoryAllData: {
        data: [],
      },
      categorySelectedOption: null,
      is_assigned: true,
      downloaded: false,
      is_completed: false,
      roleId: '',
      isCategoryReset: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();
    this.fetchAllCourses(this.state.searchQuery);
    this.getUserProfileData()
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(message);
  };

  getUserProfileData = () => {
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileGETCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileCourseGetURL
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  // GET tests for course by id
  viewCourseExams = (courseId: number | string) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getCourseExamsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCourseExamsByCourseIdApiEndpoint.replace(
        ':course_id',
        courseId,
      ),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // GET student list in the course
  getStudentList = (courseId: number | string) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getStudentListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentListByCourseIdApiEndpoint.replace(
        ':course_id',
        courseId,
      ),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAllCourses = (query: string) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: localStorage.getItem("authToken")
    };

    let page: any = this.state.currentPage;
    let per_page: any = this.state.selectedPageSize;
    let category_id: any;
    let downloaded: any;
    let is_completed: any;
    
    if (this.state.downloaded) {
      downloaded = this.state.downloaded;
    }
    if (this.state.is_completed) {
      is_completed = this.state.is_completed;
    }
    if(this.state.categorySelectedOption !== null){
      category_id= this.state.categorySelectedOption
    }
    
    const queryParams: string[] = [`per_page=${per_page}`, `page=${page}`, `search=${query}`,];
    
    if (downloaded !== undefined) {
      queryParams.push(`downloaded=${downloaded}`);
    }
    if (is_completed !== undefined) {
      queryParams.push(`is_completed=${is_completed}`);
    }
    if(category_id !== undefined &&  this.state.isCategoryReset === false) {
      queryParams.push(`category_id=${category_id}`);
    }
    
    const endpoint = `${configJSON.getAllCourses}?${queryParams.join('&')}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getAllCoursesAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  fetchAllCategory = () => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getAllCategoryAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCategory
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  // POST test score
  changeTestScore = (
    account_id: number,
    test_id: string | number,
    scorePercentage: string,
  ) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const body = {
      performance_test_id: Number(test_id),
      score_percentage: Number(scorePercentage),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.changeTestScoreApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTestScoreApiEndpoint.replace(':account_id', account_id),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createUpdateMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // POST create test
  createTest = (
    course_id: string,
    nameInput: string,
    creditsInput: string,
    passPercentage: string,
  ) => {
    if (
      !course_id ||
      !nameInput ||
      !creditsInput ||
      !passPercentage ||
      course_id === '' ||
      creditsInput === '' ||
      passPercentage === ''
    ) {
      this.showAlert('Please fill in all fields', '');
      this.hideCreateTestModal();
      return;
    }
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const body = {
      performance_test: {
        name: nameInput,
        performance_tracker_course_id: Number(course_id),
        credits: Number(creditsInput),
        pass_percentage: Number(passPercentage),
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.createTestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTestApiEndpoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createUpdateMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // DELETE test
  deleteTest = (test_id: number) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.deleteTestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteTestApiEndpoint.replace(':test_id', test_id),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const loginToken: string = message.getData(
        getName(MessageEnum.SessionResponseToken),
      );
      runEngine.debugLog('TOKEN', loginToken);
      this.setState({ loginToken });
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const courseData = message.getData(
        getName(MessageEnum.SessionResponseData),
      );
      const courseId = courseData?.courseId;
      if (courseId) {
        this.setState({ courseId }, () => this.viewCourseExams(courseId));
        this.getStudentList(courseId);
      }
    }
    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
    // GET course exams
    if (
      restApiDataCondition &&
      this.getAllCoursesAPIId === apiRequestCallId
    ) {
      if(this.state.roleId === "instructor") {
        this.setState({
          courseAllData: responseJson,
        },()=>{this.calculateTotalPages();this.paginatedinstructorData()});
      }
      else{
        this.setState({
          courseAllData: responseJson,
        },()=>{this.calculateTotalPages();this.paginatedData()}); 
      }
    }
    if (
      restApiDataCondition &&
      this.apiProfileGETCallId === apiRequestCallId
    ){
      this.setState({
        roleId: responseJson.role_id,
      }, () => { this.fetchAllCourses(this.state.searchQuery);});
    }
    if (
      restApiDataCondition &&
      this.getAllCategoryAPIId === apiRequestCallId
    ){
      this.setState({categoryAllData: responseJson});
    }
  }
  showCreateTestModal = () => {
    this.setState({ createTestModalVisible: true });
  };

  hideCreateTestModal = () => {
    this.setState({ createTestModalVisible: false });
  };
  showAddTestScoreModal = (exam: IExam) => {
    this.setState({ addTestScoreModalVisible: true, currentTest: exam });
  };

  hideAddTestScoreModal = () => {
    this.setState({ addTestScoreModalVisible: false });
  };

  handleTestNameChange = (testName: string) => this.setState({ testName });

  handleCreditsChange = (credits: string) => this.setState({ credits });

  handlePassPercentageChange = (passPercentage: string) =>
    this.setState({ passPercentage });

  handleCurrentStudentChange = (currentStudent: IStudent) => {
    if (currentStudent) this.setState({ currentStudent });
  };

  handleCurrentStudentIdChange = (currentStudentId: string) => {
    if (currentStudentId) this.setState({ currentStudentId });
  };

  handleNewTestScoreChange = (newTestScore: string) =>
    this.setState({ newTestScore });

  handleMenu=()=>{
    this.setState({open:!this.state.open});
    localStorage.setItem('open',JSON.stringify(!this.state.open));
  }

  handleTheme=()=>{
    this.setState({darkTheme:!this.state.darkTheme})
  }
  calculateTotalPages=()=>{
    let length = this.state.courseAllData?.meta?.total_pages || 1;
    this.setState({
      totalPage:length
    })
  }
  changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value }, () => this.fetchAllCourses(this.state.searchQuery))
  }
  paginatedinstructorData=()=>{
    const _coureses = this.state.courseAllData?.data?.data ?? [];
    this.setState({
      currentPageData: _coureses,
      totalPage: this.state.courseAllData?.meta?.total_pages || 1,
      currentPage: this.state.courseAllData?.meta?.current_page || 1
    });
  }


  paginatedData = () => {
    const _courses = this.state.courseAllData?.data?.data?.map((item: any) => {
      const { attributes } = item || {};
      const { course } = attributes || {};
      const { data: courseData } = course || {};
      const { attributes: courseAttributes } = courseData || {};
    
      const defaultValues = {
        year: new Date().getFullYear().toString(),
        is_assigned: false,
        is_completed: false,
        profile_id: 0,
        role_id: "",
        folder_ids: [],
        user_library_ids: [],
        course_contents_count: 0,
        upload_media: {},
      };
    
      return {
        id: item?.id || 0,
        type: item?.type || "",
        attributes: {
          course_name: courseAttributes?.course_name,
          category_name: courseAttributes?.category_name,
          course_description: courseAttributes?.course_description,
          category: courseAttributes?.category,
          sub_category: courseAttributes?.sub_category,
          sub_sub_category: courseAttributes?.sub_sub_category,
          language: courseAttributes?.language,
          price: courseAttributes?.price,
          level: courseAttributes?.level,
          tags: courseAttributes?.tags,
          duration: courseAttributes?.duration,
          year: courseAttributes?.year || defaultValues.year,
          is_assigned: courseAttributes?.is_assigned ?? defaultValues.is_assigned,
          is_completed: courseAttributes?.is_completed ?? defaultValues.is_completed,
          profile_id: courseAttributes?.profile_id ?? defaultValues.profile_id,
          role_id: courseAttributes?.role_id ?? defaultValues.role_id,
          preferred_name: courseAttributes?.preferred_name,
          media_type: courseAttributes?.media_type,
          publish_type: courseAttributes?.publish_type,
          group_names: courseAttributes?.group_names,
          status: courseAttributes?.status,
          curriculum_outline: courseAttributes?.curriculum_outline,
          tag_color: courseAttributes?.tag_color,
          due_date: courseAttributes?.due_date,
          folder_ids: courseAttributes?.folder_ids ?? defaultValues.folder_ids,
          user_library_ids: courseAttributes?.user_library_ids ?? defaultValues.user_library_ids,
          course_contents_count: courseAttributes?.course_contents_count ?? defaultValues.course_contents_count,
          image: courseAttributes?.image,
          video_url: courseAttributes?.video_url,
          document_url: courseAttributes?.document_url,
          upload_media: courseAttributes?.upload_media ?? defaultValues.upload_media,
          quizzes: courseAttributes?.quizzes,
          folders: courseAttributes?.folders,
          user_libraries: courseAttributes?.user_libraries,
        },
        course_contents: item?.attributes?.course_contents || [],
      };
    }) ?? [];
    

    this.setState({
      currentPageData: _courses,
      totalPage: this.state.courseAllData?.meta?.total_pages || 1,
      currentPage: this.state.courseAllData?.meta?.current_page || 1,
    });
  };


  renderCategoryName = (category:any) => {
    return typeof category === 'object' && category !== null ? category.name : category;
  };
  setCourseIdProjectPage=async(courseId:number)=>{
    await setStorageData("courseId",courseId).then(this.goToProjectNotes);
  }
  goToProjectNotes=()=> {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Projectnotes');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  togglePageDropdown = () => {
    this.setState((prevState) => ({
      isPageDropdownOpen: !prevState.isPageDropdownOpen,
    }));
  };

  toggleDownloadDropdown  = (id: any) => {
    this.setState((prevState) => ({
      openDropdownId: prevState.openDropdownId === id ? null : id,
    }));
  };

  handleDownloadDropdown = (value: any) => {
    this.setState({ DownloadDropdownOption: value });
  };

  handlePageSizeChange = (size: number) => {
    this.setState({ selectedPageSize: size }, () => {
      this.fetchAllCourses(this.state.searchQuery);
    });
  };

  handleFilterClick = (filter: string) => {
    this.setState(
      {
        SelectedCourseTab: filter,
        is_assigned: filter === "is_assigned",
        downloaded: filter === "downloaded",
        is_completed: filter === "is_completed",
      },
      () => {
        this.fetchAllCourses(this.state.searchQuery);
      }
    );
  };


  handleInputChange = (event: any) => {
    const value = event.target.value;
    this.setState({ searchQuery: value, currentPage: 1 }, () => this.fetchAllCourses(value));
  }

  handleCategoryChange = () => {
    this.fetchAllCategory();
  }

  handleCategorySelect = (selectedValue: any) => {
    this.setState({ categorySelectedOption: selectedValue }, () => {
      this.fetchAllCourses(this.state.searchQuery);
    });
  };

  handleCategoryReset = () =>{
    this.setState({ isCategoryReset: true, selectedGroup: ""}, () => {
        this.fetchAllCourses(this.state.searchQuery);
    });
  }

  // Customizable Area End
}
