import { Box, styled } from "@material-ui/core";
import { Close, Pause, PlayArrow } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import React, { CSSProperties, useRef, useState, ChangeEvent, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Button from "./Button";
import PreviewFileCard from "./Card";
import IconButton from "./IconButton";
import CustomLabel from "./Label";

interface IFileUploadProps {
    id?: string;
    required?: boolean;
    label?: string;
    labelStyle?: CSSProperties;
    supportText?: string;
    fileType?: "video" | "image" | "pdf" | string;
    multiple?: boolean;
    withoutPreview?: boolean;
    uploadingFileIcon?: string;
    value?: File;
    onChange?: (files: File[] | File | null) => void;
}

const FileUpload = (props: IFileUploadProps) => {
    const { id, required, label, labelStyle, supportText, fileType = "image", withoutPreview = false, multiple = false, uploadingFileIcon, onChange } = props;

    const fileRef = useRef<HTMLInputElement>(null);
    const videoPreviewRef = useRef<HTMLVideoElement>(null);

    const onDrop = useCallback((acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];
        setPreviewUrl(URL.createObjectURL(file));
        if (onChange) {
            if (multiple) {
                onChange([file]);
            }
            onChange(file);
        }
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const [previewUrl, setPreviewUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState('');
    const [previewVideoPlaying, setPreviewVideoPlaying] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files?.[0];
            if (!withoutPreview) {
                setPreviewUrl(URL.createObjectURL(file));
            } else {
                setFileName(file.name);
                setFileSize(file.size.toString());
            }
            if (onChange) {
                if (multiple) {
                    onChange([file]);
                }
                onChange(file);
            }
        }
    }
    const handleFileUpload = () => {
        if (fileRef && fileRef.current) {
            fileRef.current.click();
        }
    };
    const removePreviewMedia = () => {
        if (!withoutPreview) {
            setPreviewUrl("");
        } else {
            setFileName("");
            setFileSize("");
        }
        if (onChange) {
            onChange(null);
        }
    }
    const handlePlayPauseVideo = () => {
        if (previewVideoPlaying) {
            videoPreviewRef.current?.pause();
        } else {
            videoPreviewRef.current?.play();
        }
        setPreviewVideoPlaying(!previewVideoPlaying);
    }

    if (withoutPreview) {
        return (
            <Container>
                <FileUploadWithoutPreviewZone>
                    <CustomLabel>No file selected</CustomLabel>
                    <label htmlFor="fileFromLocal">
                        <input
                            id="fileFromLocal"
                            type="file"
                            accept={fileType}
                            ref={fileRef}
                            style={{ display: "none" }}
                            onChange={handleChange}
                        />
                        <BrowseButton onClick={handleFileUpload}>Browse file</BrowseButton>
                    </label>
                </FileUploadWithoutPreviewZone>
                {fileName !== "" 
                    && (
                        <PreviewFileCard 
                            thumbnailSrc="" 
                            title={fileName} 
                            subtitle={fileSize} 
                            actionButton={<IconButton onClick={removePreviewMedia}><CloseIcon /></IconButton>} 
                            style={{ marginTop: "5px", width: "70%" }}
                        />
                    )}
            </Container>
        )
    }

    return (
        <Container>
            {label && (
                <Label 
                    htmlFor={id} 
                    role="alert" 
                    style={labelStyle}
                >
                    {label} {required && <span style={{ fontSize: '8px' }}>(Required <span style={{ color: "#DC2626" }}>*</span>)</span>}
                </Label>)}
            {previewUrl !== ""
                ? (
                    <FilePreview>
                        {fileType.includes("image") 
                            ? <ImagePreview src={previewUrl} />
                            : <VideoPreview src={previewUrl} ref={videoPreviewRef} />
                        }
                        {!fileType.includes("image") && (
                            <PlayButton variant="ghost" onClick={handlePlayPauseVideo}>
                                {!previewVideoPlaying ? <PlayArrow /> : <Pause />}
                            </PlayButton>)}
                        <RemoveButton style={{ height: "fit-content" }} onClick={removePreviewMedia}>
                            <Close />
                        </RemoveButton>
                    </FilePreview>)
                : <label htmlFor="uploadedImage" className="profile-upload-btn">
                    <input
                        id="uploadedImage"
                        type="file"
                        accept={fileType}
                        ref={fileRef}
                        style={{ display: "none" }}
                        onChange={handleChange}
                        {...getInputProps}
                    />
                    <FileUploadZone {...getRootProps()}>
                        <img src={uploadingFileIcon} />
                        <RowStack>
                            <SupportText>{isDragActive ? "Is dragging file" : supportText}</SupportText>
                            <Button variant="ghost" size="sm" style={{ fontSize: "12px", lineHeight: "18px" }} onClick={handleFileUpload}>browse</Button>
                        </RowStack>
                    </FileUploadZone>
                </label>}
        </Container>
    )
}

export default FileUpload;

const Container = styled(Box)({
    width: '100%',
    maxWidth: '100%'
})
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const FilePreview = styled(Box)(({ theme }) => ({
    position: "relative",
  display: 'flex',
  alignItems: 'center',
  height: '239px',
  borderRadius: '22px',
  border: `1px solid ${theme.palette.primary.main}`,
  flexDirection: 'column'
}))
const ImagePreview = styled('img')({
    width: "100%", 
    height: "100%", 
    borderRadius: "15px",
})
const VideoPreview = styled('video')({
    width: "100%", 
    height: "100%", 
    borderRadius: "15px",
})
const PlayButton = styled(Button)({
    position: "absolute",
    bottom: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "48px",
    height: "48px",
    padding: 0,
    "& svg": { fill: "#F8FAFC" }
})
const RemoveButton = styled(IconButton)(({ theme }) => ({
    position: "relative",
    bottom: "85%",
    left: "40%",
    backgroundColor: "#D7D7D780", 
    padding: "3px", 
    borderRadius: 0,
    "& svg": { 
        height: "15px",
        width: "15px",
        fill: theme.palette.secondary.main 
    }
}))
const FileUploadZone = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "22px",
    height: "239px",
    border: `1px dashed ${theme.palette.secondary.contrastText}`,
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer"
}))
const RowStack = styled(Box)({
    display: "flex",
    alignItems: "center"
})
const FileUploadWithoutPreviewZone = styled(RowStack)(({ theme }) => ({
    height: '90px',
    width: "100%",
    borderRadius: '8px',
    border: `1px solid ${theme.palette.info.dark}`,
    justifyContent: 'space-between',
    padding: '18px 16px',
}))
const SupportText = styled('span')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '10px',
    lineHeight: '12px',
}))
const BrowseButton = styled(Button)({
    paddingLeft: "60px", 
    paddingRight: "60px", 
    borderRadius: "30px"
})