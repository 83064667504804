import React from "react";
import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  styled,
  MenuItem,
  Radio,
  RadioGroup,
  Accordion,AccordionSummary,AccordionDetails,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { AddCircleOutline, DragIndicator,ExpandMoreRounded } from "@material-ui/icons";
import {  FieldModal } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import { Formik } from "formik";

import { QuestionSchema } from "./AssessmentTestController";

const addIcon = require("../assets/addIcon.png");
const deleteIcon = require("../assets/ic-trash.svg");
const copyIcon = require("../assets/ic-copy.svg");
const uploadIcon = require("../assets/uploadIcon.png");
const helpIcon = require("../assets/helpIcon.png");
const trashIcon = require("../assets/trashIcon.png");
const cvsIcon = require("../assets/cvsIcon.png");
const removeIcon = require("../assets/removeIcon.png");
const addFileIcon = require("../assets/graphic-style.svg");
const addFileLibraryIcon = require("../assets/library.svg");
const dotIcon = require("../assets/dot_img.svg");
const imageClose = require("../assets/image_close.svg");


import { FocusableContainer, ScrollableSection } from "../../postcreation/src/PostCreation.web";
import FileUpload from "../../utilities/src/FileUpload.web";
import { NextButton } from "../../email-account-registration/src/EmailAccountRegistration.web";
import CustomLabel from "../../utilities/src/Label";
import CustomSwitch from "../../utilities/src/Switch";
import TextInput from "../../utilities/src/TextInput";
import CustomButton from "../../utilities/src/Button";
import IconButton from "../../utilities/src/IconButton";
import MuiSelect from "../../utilities/src/MuiSelect";
import RichTextEditor from "../../utilities/src/RichTextEditor";
import { Checkbox as CustomCheckbox }  from "../../../components/src/CustomCheckBox.web";

const webStyles = {
  icon: {
    width: "24px",
    height: "24px"
  }
}
// Customizable Area End

import AssessmentTestController, {
  Props,
} from "./AssessmentTestController";
import { AssessmentItemInterface, AttemptedAssessmentInterface } from "./IAssessment";
import { Row } from "antd";

export default class AssessmentTest extends AssessmentTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderUploadedFiles = (width: string, height: string) => {
    const { allQuestions, currentQuestionIndex } = this.state;
    const currentQuestion = allQuestions[currentQuestionIndex];
    if (!currentQuestion) return null;

    const mediaFiles = currentQuestion.media_files || [];
    const mediaFilesUrls = currentQuestion.media_files_urls?.map(item => item.url) || [];

    const renderMediaFile = (file: string | File, width: string, height: string) => {
        const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file);

        if (fileUrl.match(/\.(jpg|jpeg|png|gif|bmp)$/i)) {
            return <img src={fileUrl} alt="Uploaded" width={width} height={height} style={{ borderRadius: '13px' }} />;
        } else if (fileUrl.match(/\.(mp4|webm)$/i)) {
            return (
                <video width={width} height={height} controls style={{ borderRadius: '13px' }}>
                    <source src={fileUrl} />
                    Your browser does not support the video tag.
                </video>
            );
        } else if (file instanceof File) {
            if (file.type.startsWith("image/")) {
                return <img src={URL.createObjectURL(file)} alt="Uploaded" width={width} height={height} style={{ borderRadius: '13px' }} />;
            } else if (file.type.startsWith("video/")) {
                return (
                    <video width={width} height={height} controls style={{ borderRadius: '13px' }}>
                        <source src={URL.createObjectURL(file)} type={file.type} />
                        Your browser does not support the video tag.
                    </video>
                );
            }
        }
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: "8px" }}>
            {[...mediaFiles, ...mediaFilesUrls].map((file, fileIndex) => (
                <div key={fileIndex} style={{ position: 'relative', marginRight: '10px', width: width === "100%" ? "48%" : undefined, marginBottom: '10px' }}>
                    {renderMediaFile(file, width, height)}

                    <div data-test-id="removeBtn" onClick={() => this.handleRemoveMediaFile(currentQuestionIndex, fileIndex)} style={{
                        position: 'absolute',
                        top: '-10px',
                        right: '-10px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontSize: '16px' }}>
                            <img src={imageClose} alt="Remove" />
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

  
  successModal = () => (
    <FieldModal
      data-test-id="successModal"
      open={this.state.successModalOpen}
      onCancel={this.closeSuccessModal}
      centered
      closable={false}
      footer={null}
      destroyOnClose
      width={696}
      style={{ height: '540' }}
    >
      <div >
        <div style={{ marginBottom: "15px" }}>
        <CustomLabel style={{ fontWeight: 700 }} >upload  Video/Image</CustomLabel>
        </div>
        {this.renderUploadedFiles("80px","80px")}
        <label htmlFor="uploadedMedia" className="profile-upload-btn">
          <input
            id="uploadedMedia"
            data-test-id="coverImageBtn"
            type="file"
            multiple
            accept="image/*,video/*"
            style={{ display: "none" }}
            onChange={this.setFieldValue}
          />
          <FileUploadZone>
            <img src={addFileIcon} />
            <RowStack style={{ alignItems: "center" }}>
              <SupportText>Drag and drop image , or </SupportText>
              <BrowseButton onClick={this.handleBrowseClick}>
                browse
              </BrowseButton>
            </RowStack>
          </FileUploadZone>
        </label>
        <div style={{ marginTop: "18px" }}>
          <FileUploadZone>
            <img src={addFileLibraryIcon} />
            <RowStack style={{ gap: '3px' }}>
              <Button >Assign from library </Button>
            </RowStack>
          </FileUploadZone>
        </div>

      </div>
      <div style={{ marginTop: "28px", display: "flex", justifyContent: "space-between" }}>
        <Button
          className="cancelChapterTitle"
          data-test-id="cancelChapterTitle1"
          onClick={this.closeSuccessModal}
          style={{ width: "47%", borderRadius: "30px", border: "1px solid rgba(26, 70, 156, 1)", color: "rgba(26, 70, 156, 1)", fontWeight: 700 }}
        >
          Cancel
        </Button>
        <Button
          className="publishBg"
          data-test-id="newChapterAddedButton"
          onClick={this.closeSuccessModal2}
          style={{ width: "47%", borderRadius: "30px", backgroundColor: "rgba(26, 70, 156, 1)", color: "#FFFFFF", fontWeight: 700 }}
        >
          Save
        </Button>
      </div>
    </FieldModal>
  )

  renderTabSection = () => (
    <TabBox>
      {["Manage Questions", "Import Quiz"].map((tab, index) => (
        <TopMenuOption
          key={index} 
          onClick={() => this.handleQuizTabs(index)} 
          data-test-id={`quizTabs${index}`}
          style={{
            color: this.state.quizTabs === index ? "#1A469C" : "#475569",
            borderBottom: this.state.quizTabs === index ? "2px solid #1A469C" : "2px solid #D7D7D780",
            cursor: "pointer" 
          }}
        >{tab}</TopMenuOption>
      ))}
    </TabBox>
  )
  renderQuizPortions = () => (
    <ColStack style={{ gap: "13px" }}>
      {this.state.allQuestions.map((question, index) => {
        return (
          <Formik
            key={index}
            initialValues={question}
            validationSchema={QuestionSchema}
            onSubmit={this.handleUpdateQuestion}
            data-test-id="question-formik-new"
            innerRef={f => this.questionFormikRef.current = f}
          >
            {({ dirty, isValid, values, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <MainQuestionBox 
                  key={index} 
                  square 
                  elevation={0} 
                  expanded={this.state.expandedQuestionIndex === index}
                  onChange={this.handleChangeAccordionExpanding(index)}
                >
                  <QuestionBoxHeader expandIcon={<ExpandMoreRounded style={{ ...webStyles.icon , color:"#475569" }}/>}>
                    <QuestionBoxHeaderContent>
                      <RowStack style={{ alignItems: "center" }}>
                        <IconButton onClick={(event: { stopPropagation: () => void; }) => event.stopPropagation()}>
                          <img src={dotIcon} />
                        </IconButton>
                        {(this.state.isEdittingQuestion !== index )
                        ? (
                        <LessonHeading data-test-id="lessonId" onClick={(event) => {
                          this.stopPropagration(event)
                          this.setState({ isEdittingQuestion: index });
                        }}>
                            Question {index + 1}: {question.content}
                          </LessonHeading>
                        )
                        : ( 
                          <TextInput 
                            data-test-id="txtQuestionTitle" 
                            name="content" 
                            value={question.content} 
                            onClick={(event) => {
                              this.stopPropagration(event)
                            }}
                            onChange={(e)=>this.handleQuestionAddition(e,index)}
                            onBlur={() => this.setState({ isEdittingQuestion: null })} 
                          />
                        )}
                      </RowStack>
                      <RowStack style={{ gap: "9px" }}>
                        {
                          question.id && 
                          <IconButton onClick={this.handleCopyQuestion(index)}>
                            <CustomIcon src={copyIcon} />
                          </IconButton>
                        }
                        <IconButton data-test-id="deleteButton" onClick={(e: any) => this.deleteQuestion(index, question.quiz_id, question.id, e)}>
                          <CustomIcon src={deleteIcon} />
                        </IconButton>
                      </RowStack>             
                    </QuestionBoxHeaderContent>
                  </QuestionBoxHeader>
                  <QuestionBoxBody>
                    <ColStack style={{ gap: "29px" }}>
                      <QuestionForm>
                        <MuiSelect
                          id="question_type"
                          displayEmpty
                          autoFocus={false}
                          name="question_type"
                          key={question.question_type}
                          data-test-id="questionTypeSelection"
                          value={question.question_type}
                          onChange={(e)=>this.handleQuestionTypeChange(e,index)}
                          style={{ width: "184px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                zIndex: 1300,
                                marginTop: "10px", 
                                borderRadius: "8px",
                                backgroundColor: "rgb(255, 255, 255)",
                              },
                            },
                            MenuListProps: {
                              style: {
                                paddingTop: "0px", 
                              },         
                            },
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom', 
                              horizontal: 'center',
                            },
                            transformOrigin: {
                              vertical: 'top', 
                              horizontal: 'center', 
                            },
                          }}
                        >
                          <StyledMenuItem value="" style={{display:'none'}} disabled>
                            Question Type
                          </StyledMenuItem>
                          <StyledMenuItem value="single_choice">One Correct Answer</StyledMenuItem>
                          <StyledMenuItem value="multiple_choice">Multiple Answers</StyledMenuItem>
                        </MuiSelect>
                        <RichTextEditor
                          data-test-id="txtQuestion"
                          name="content"
                          placeholder="What is your question?"
                          value={question.description}
                          onChange={(content) => this.handleQuestionDescAddition(content, index)}
                        />
                        <RowStack style={{ justifyContent: "end" }}>
                          <StyledButton
                            variant="ghost"
                            size="sm"
                            onClick={this.openSuccess}
                            iconLeft={<img src={uploadIcon} style={{ width: "20px", height: "20px" }}/>}
                            style={{ padding: "revert" }}
                          >Video/image</StyledButton>
                        </RowStack>
                        {this.successModal()}
                        <div style={{ display: "flex", gap: "10px", marginTop: "10px", flexWrap:"wrap" }}>
                          {this.renderUploadedFiles("100%","203px")}
                        </div>
                      </QuestionForm>
                      <RadioGroup name={`question-${index}`} style={{ gap: "29px" }}>
                        {question.choices_attributes.map((choices, choice_index) => (
                          <EachChoiceBox key={choice_index}>
                            <RowStack style={{ gap: "10px" }}>
                              <Box style={{ cursor: "grab" }}>
                                <DragIndicator fontSize="small" />
                              </Box>                          
                              <LessonHeading data-test-id="choiceId">Choice {choice_index + 1}</LessonHeading>
                            </RowStack>
                            <RichTextEditor 
                              data-test-id="txtAnswer"
                              name="content" 
                              placeholder="Enter Choice"
                              value={choices.content}
                              formats={formats}
                              onChange={(content) => this.handleChoiceAddition(content,index,choice_index)}
                            />
                            <RowStack style={{ padding: "0 10px", alignItems: "center", gap: "9px" }}>
                              <StyledRadio 
                                data-test-id="radioSelection" 
                                value={choices.is_correct} 
                                checked={choices.is_correct} 
                                onClick={event => this.handleAnswerAddition(event, index, choice_index)}
                              />
                              <ChoiceLabel>This is the correct answer</ChoiceLabel>
                            </RowStack>
                          </EachChoiceBox>
                        ))}
                      </RadioGroup>
                        <StyledButton 
                        data-test-id="addChoiceTest" 
                        variant="outline"
                        size="sm"
                        style={{ width: "30%" }}
                        iconLeft={<AddCircleOutline />}
                        onClick={() => this.handleNewOptionAddition(index)}
                      >Add Choice</StyledButton>                     
                      <RowStack style={{ marginTop: "19px", justifyContent: "end" }}>
                        <StyledButton 
                          type="submit"
                          style={{ width: "25%" }}
                          disabled={!(
                            question.question_type &&
                            question.content &&
                            question.description &&
                            question.choices_attributes.every(choice => choice.content) &&
                            question.choices_attributes.length >= 2 &&
                            (question.question_type !== "multiple_choice" ||
                              question.choices_attributes.filter(choice => choice.is_correct).length >= 2) &&
                            (question.question_type !== "single_choice" ||
                              question.choices_attributes.some(choice => choice.is_correct))
                          )}>Save</StyledButton>
                      </RowStack>
                    </ColStack>
                  </QuestionBoxBody>
                </MainQuestionBox>
              </form>
            )}
          </Formik>
        )})}
    </ColStack>
  )
  renderFileImport=()=>{
    return (
    <FileImportBox>
      <QuestionBoxHeader style={{justifyContent:"space-between", border:"1px solid #CBD5E1", height:"71px",padding:"17px 31px 30px 31px", borderRadius:"8px"}}>
        <div style={{justifyContent:"space-between", display:"flex", width:"100%"}}>
          <div style={{display:"flex",flexDirection:"row",gap:"18px"}}>
            <img src={helpIcon} style={{width:"24px",height:"24px"}}/>
            <ImportTypo data-test-id="importSection">
              Bulk Import Questions
            </ImportTypo>
          </div>
        </div>
      </QuestionBoxHeader>    
      <CSVBox>
        <SelectTypo style={{marginTop:'47px'}}>Select CSV file to import</SelectTypo>
        <ImportBox style={{border:(this.state.fileData.fileName ? "none": "1px solid #CBD5E1" )}}>
          {this.state.fileData.fileName ? 
          (<>
            <FileBox>
              <img src={cvsIcon} style={{width:"31px",height:"39px"}}/>
              <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center"}}>
                <FileNameTypo>{this.state.fileData.fileName}</FileNameTypo>
                <FileSizeTypeTypo>{this.state.fileData.filesize}</FileSizeTypeTypo>
                <FileSizeTypeTypo>{this.state.fileData.fileType}</FileSizeTypeTypo>
              </div>
              <img  onClick={this.handleRemoveFile} src={removeIcon} style={{width:"23px",height:"23px"}}/>
            </FileBox>
          </>) :
          <NoTypo>No file selected</NoTypo>}
          <input
              data-test-id="csvLessonBtn"
              id="csvLesson"
              type="file"
              accept=".csv"
              ref={this.fileRef}
              style={{ display: "none" }}
              onChange={this.handleFileSelect}
            />
          {this.state.fileData.fileName ? <></>
           : 
          <SaveContinueButton data-test-id="csvfileUpload" onClick={(event)=>this.uploadCSVQuizFile(event)} style={{width:"204px",height:"44px"}}>Browse file</SaveContinueButton>
          }
        </ImportBox>
        {this.state.fileData.fileName ?
          <ImportButtonBox>
            <AddQuestionButton style={{width:"255.4px",height:"44px"}} data-test-id="browseFileButton" onClick={(event)=>this.uploadCSVQuizFile(event)}>Browse File</AddQuestionButton>
            <SaveContinueButton data-test-id="csvfileSend" style={{width:"255.4px",height:"44px"}} onClick={()=>this.sendFile()}>Import Questions</SaveContinueButton>
          </ImportButtonBox>
        : <></> }
 
        </CSVBox>
    </FileImportBox>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.openQuizSection && this.renderTabSection()}
        <Box>
          <QuizHeadingSection>
            <HeadingTypo>Quiz</HeadingTypo>
            <QuizHeadingDivider />
            <RowStack style={{ alignItems: "center", gap: "8px" }}>
              <CustomLabel style={{ fontWeight: 700, padding: 0 }}>Required</CustomLabel>
              <StyledSwitch checked={this.state.isRequired} onClick={this.requiredQuiz}/>
            </RowStack>
          </QuizHeadingSection>
          {this.state.quizTabs !== 2 && (
            <ScrollableSection style={{ marginTop: "20px", marginBottom: "25px", maxHeight: "870px", paddingRight: "17px" }}>
              {this.state.quizTabs === 0 && this.renderQuizPortions()}
              {this.state.quizTabs === 1 && this.renderFileImport()}
            </ScrollableSection>
          )}
          <RowStack style={{ height: this.state.quizTabs !== 2 ? "fit-content" : "200px", alignItems: "center", justifyContent: "center" }}>
          {this.state.quizTabs !== 1 &&
            <StyledButton
              data-test-id="addQuestionButton" 
              onClick={this.handleQuizSection}
              iconLeft={<img src={addIcon} style={{ width: "24px", height: "24px" }} />}
              variant="outline"
              fullWidth
            >Add Question</StyledButton>
          }
          </RowStack>
          <FocusableContainer style={{height: "fit-content", padding: "34px 18px 34px 0px", gap: "12px", display:"flex", flexDirection:"column"}}>
            <CustomCheckbox label={
              <span style={{ fontWeight: 700, color: "#475569" }}>
                This quiz requires a passing grade
              </span>
            } />
            <TextInput data-test-id="passingGrade" value={this.state.passingGrade} onChange={(e)=>this.handlePassingGrades(e.target.value)} label="Passing Grade" placeholder="1%" style={{ width: "200px" }} />
            <CustomCheckbox
              label={
                <span style={{ fontWeight: 700, color: "#475569" }}>
                  Randomized bank questions
                </span>
              }
            />            
            <RowStack style={{ alignItems: "center" }}>
              <SideBoxes>Use</SideBoxes>
              <TextInput 
                layoutStyle={{ flex: 0 }} 
                value={this.state.randomQuestion}
                onChange={(event) => this.handleRandomQuestionNumber(event.target.value)}
                style={{ width: "318px", borderRight: "0", borderLeft: "0", borderRadius: "0" }}
              />
              <SideBoxes>of {this.state.question_count} Questions</SideBoxes>
            </RowStack>
          </FocusableContainer>
          <RowStack style={{ marginTop: "32px", justifyContent: "flex-end", gap:"20px" }}>
            <StyledButton
              data-test-id="saveCloseQuestionButton"              
              variant="outline"
              disabled={this.state.allQuestions.length<=0}
              onClick={this.handleCallBack}
              style={{ width: '30%' }}
            >Save and Close</StyledButton>
            <StyledButton
              data-test-id="saveAdd"
              onClick={this.handleCallBack2}
              style={{ width: '30%' }}
            >Save and Add New Lesson Type</StyledButton>
          </RowStack>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const RowStack = styled(Box)({ display: "flex" })
const ColStack = styled(RowStack)({ flexDirection: "column" })
const StyledButton = styled(CustomButton)({
  borderRadius: "30px"
})
const CustomIcon = styled('img')({
  width: "24px",
  height: "24px",
  objectFit: "contain",
  objectPosition: "center"
})
const StyledSwitch = styled(CustomSwitch)({
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    background: '#059669',
  }
})
const ImportButtonBox = styled(Box)({
  width: "546px",
 height: "76px",
 padding: "0px 0px 32px 18px",
 gap: "10px",
 display:"flex",
 flexDirection:"row",
 alignItems:"center",
 justifyContent:"center",
 marginTop:"32px"
})
const BrowseButton = styled(Button)({
  padding: "0px", 
  textTransform:'none',
  color:"rgba(26, 70, 156, 1)",
 '&:hover': {
    backgroundColor: 'transparent',
    color: 'rgba(26, 70, 156, 1)', 
    textDecoration: 'none',
  },
  '&:focus': {
    outline: 'none',
  },
})

const FileNameTypo = styled(Typography)(({ theme }) => ({
  width: "415px",
  fontFamily: "Rubik",
  fontSize: "9.5px",
  fontWeight: 700,
  lineHeight: "14.25px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#0F172A"
  }))

const SupportText = styled('span')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '10px',
    lineHeight: '12px',
}))

const FileSizeTypeTypo = styled(Typography)(({ theme }) => ({
   fontFamily: "Rubik",
  fontSize: "7.5px",
  fontWeight: 400,
  lineHeight: "14.25px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155"
}))

const FileBox = styled(Box)({
  width: "526px",
  height: "72px",
  padding: "6.05px 0px 0px 0px",
  gap: "12.1px",
  borderRadius: "6.05px",
  opacity: "0px",
  background: "#FFFFFF",
  border: "0.76px solid #FFFFFF",
  boxShadow: "0px 2px 8px 0px #00000014",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"center"
})
const QuizHeadingSection = styled(RowStack)({
  alignItems:"center",
  justifyContent: "space-between",
})
const CommonTypo = styled(Typography)({ fontFamily: "Rubik" })
const HeadingTypo = styled(CommonTypo)({
  fontSize: "32px",
  fontWeight: 500,
  lineHeight: "38px",
  color: "#0F172A"
})
const QuizHeadingDivider = styled('hr')({
  width: "231px",
  height: "2px",
  backgroundColor: "#D7D7D7",
  opacity: "50%",
})
export const SwitchDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "20px",
  "& p": {
    marginLeft: '5px',
    marginTop: '2px',
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: '16px',
    color: theme.palette.text.primary
  },
  '& .MuiSwitch-root': {
    height: '28px',
    padding: 0,
    width: '48px'
  },
  '& .MuiIconButton-root': {
    padding: '2px !important'
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    background: '#E2E8F0',
    borderRadius: '40px',
  },
  "& .MuiSwitch-thumb": {
    height: 24,
    width: 24
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    opacity: 1
  },
  '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#059669"
  }
}));
const AddQuestionButton = styled(Button)(({ theme }) => ({
  height: "44px",
  cursor:"pointer",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  border: "1px solid #1A469C",
  textTransform:"none",
  "& .MuiButton-label":{
	fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#1A469C"  
}
}))

const TabBox = styled(RowStack)({
  width: "647px",
  marginBottom:"50px",
})
const SideBoxes = styled(Box)({
  padding: "10px 8px",
  borderLeft: "1px solid #E2E8F0",
  borderRadius: "6px",
  backgroundColor: "#E2E8F0",
})
const TopMenuOption = styled('span')({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '16px',
  textAlign: 'center',
  paddingBottom: "12px",
  width: "26.67%"
})
const MainQuestionBox = styled(Accordion)({
  borderRadius: "18px",
  border: "0.5px solid #E2E8F0",
  padding: "10px 20px",
  background: "#FFF",
  "&:before": { height: 0 },
  "& .ql-container": {
    borderRadius: '8px',
    height: "174px",
  },
  '& .quill': {
    position: 'relative',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
  },
  "& .ql-toolbar": {
    borderRadius: '8px',
    border:"none",
    position: "absolute",
    bottom: "10px",
    left: 10,
    transform: "translateY",
    zIndex: 1000,
    '& .ql-formats': {
      marginRight: 0
    },
    '& .ql-formats:first-child': {
      display: 'none'
    },
    '& .ql-formats button': {
      border: '1px solid #94A3B8',
      marginRight: 2
    },
  },
  "& .ql-editor": {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#0F172A"
  },
  "& .ql-editor.ql-blank::before": {
    color: "#0F172A",
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    fontSize: '14px'
  },
})
const QuestionBoxHeader = styled(AccordionSummary)({
  padding: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: 0,
  "& .MuiAccordionSummary-content": { margin: 0 }
})
const QuestionBoxHeaderContent = styled(RowStack)({
  width: "100%", 
  justifyContent: "space-between", 
  alignItems: "center", 
  gap: "9px"
})
const QuestionBoxBody = styled(AccordionDetails)({
  padding: 0,
  display: "block"
})
const QuestionForm = styled(ColStack)({
  padding: "0 8px 2px 0",
  gap: "8px"
})
const LessonHeading = styled(Typography)(({ theme }) => ({
 height: "24px",
 gap: "0px",
 opacity: "0px",
 fontFamily: "Rubik",
 fontSize: "20px",
 fontWeight: 500,
 lineHeight: "23.7px",
 textUnderlinePosition: "from-font",
 textDecorationSkipInk: "none",
 color: "#334155"
}))
const StyledMenuItem = styled(MenuItem)({
  borderRadius: "8px",
  padding: "8px 16px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#475569",
  "&.Mui-selected": {
      backgroundColor: "transparent"
  },
  "&.Mui-selected:hover":{
      backgroundColor: "transparent"
  },
  "&:hover":{
      backgroundColor: "transparent",
      color: "#1A469C"
  }
});
const FileUploadZone = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "22px",
  height: "121px",
  border: `1px dashed ${theme.palette.secondary.contrastText}`,
  backgroundColor: theme.palette.primary.main,
}))
const EachChoiceBox = styled(ColStack)({
  backgroundColor: "#F9F9F9",
  borderRadius: "9px",
  border: "1px solid #EDF1F6",
  padding: "17px 21px 29px 17px",
  gap: "8px"
})
const StyledRadio = styled(Radio)({
  "&.MuiRadio-root": {
    border: "1px solid #64748B",
    width: "20px",
    height: "20px",
    borderRadius: "16px",
    color: "#64748B",
    transition: "all 0.3s ease",
  },
  "&.Mui-checked": {
    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
      color: '#18408E',
      border: '8px solid #18408E',
      borderRadius: '50%'
    },
    '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
      color: 'white',
    },
  },
});
const ChoiceLabel = styled(Typography)(({ theme }) => ({
  width: "180px",
 height: "22px",
 fontFamily: "Rubik",
 fontSize: "14px",
 fontWeight: 400,
 lineHeight: "22px",
 textAlign: "left",
 textUnderlinePosition: "from-font",
 textDecorationSkipInk: "none",
 color: "#475569"
}))
const SaveContinueButton = styled(Button)(({ theme }) => ({
  width: "473px",
height: "44px",
padding: "10px 16px 10px 16px",
gap: "8px",
borderRadius: "30px",
opacity: "0px",
backgroundColor: "#1A469C",
textTransform:"none",
"& .MuiButton-label":{
width: "255px",
height: "24px",
fontFamily: "Rubik",
fontSize: "16px",
fontWeight: 700,
lineHeight: "24px",
textAlign: "left",
textUnderlinePosition: "from-font",
textDecorationSkipInk: "none",
color: "#F8FAFC"
},
"&:hover": {
  background: "#1A469C",  
  color: "#F8FAFC"
}
}))
const FileImportBox = styled(Box)(({ theme }) => ({
  width: "1053px",
  height: "282px",
  gap: "17px",
  borderRadius: "18px",
  backgroundColor: "#F9F9F9"
}))
const ImportTypo = styled(Typography)(({ theme }) => ({
  width: "214px",
  height: "24px",
  fontFamily: "Rubik",
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "23.7px",
  textAlign: "center",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#0F172A"
}))
const CSVBox = styled(Box)(({ theme }) => ({
  width: "1018px",
  height: "117px",
  padding: "0px 9px 0px 9px",
  gap: "5px"
}))
const SelectTypo = styled(Typography)(({ theme }) => ({
  width: "172px",
    height: "22px",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#334155"
  }))
  const ImportBox = styled(Box)(({ theme }) => ({
    width: "1000px",
    height: "90px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    background: "#FFFFFF",
    border: "1px solid #CBD5E1",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between",
  }))
  const NoTypo = styled(Typography)(({ theme }) => ({
    width: "968px",
    height: "24px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#475569"
  }))
const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];
const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
      light: "#F8FAFC",
      dark: "#FAFAF9",
      contrastText: "#DC2626",
    },
    secondary: {
      dark: "#808080",
      light: "#848484",
      main: "#000000",
      contrastText: "#94A3B8",
    },
    background: {
      paper: "#EDF1F6",
      default: "#FFFFFF",
    },
    text: {
      hint: "#E2E8F0",
      secondary: "#43536A",
      primary: "#334155",
      disabled: "#F4F4F5",
    },
    action: {
      focus: "#F9F9F9",
      selected: "#1A469C",
      active: "#F1F5F9",
      disabled: "#D7D7D7",
      hover: "#3F3F46",
      disabledBackground: "#27272A",
    },
    info: {
      light: "#475569",
      main: "#0F172A",
      contrastText: "#1A469C",
      dark: "#CBD5E1",
    },
    warning: {
      main: "#E8EDF5",
      light: "#059669",
      dark: "#D97706",
      contrastText: "#DDE1E6",
    },
    error: {
      main: "#FEE2E2",
      light: "#FEF3C7",
      dark: "#D1FAE5",
      contrastText: "#6683BD",
    },
    success: {
      main: "#475569",
      light: "#475569",
    },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#161616",
      light: "#0F172A",
      dark: "#121212",
      contrastText: "#F87171",
    },
    secondary: {
      main: "#FFFFFF",
      dark: "#18212E",
      light: "#99A7BB",
      contrastText: "#94A3B8",
    },
    background: {
      default: "#0D0D0D",
      paper: "#121B29",
    },
    text: {
      primary: "#909EB2",
      secondary: "#C4CFE1",
      hint: "#18212E",
      disabled: "#F7FBF7",
    },
    action: {
      active: "#424242",
      selected: "#12326F",
      focus: "#161616",
      disabled: "#D7D7D733",
      hover: "#393D42",
      disabledBackground: "#212529",
    },
    info: {
      main: "#D5DDF0",
      light: "#99A7BB",
      dark: "#273444",
      contrastText: "#70A1FF",
    },
    warning: {
      main: "#121B29",
      light: "#42EBB5",
      dark: "#F28407",
      contrastText: "#242A31",
    },
    error: {
      main: "#1C1C1C",
      light: "#514101",
      dark: "#074525",
      contrastText: "#4F6080",
    },
    success: {
      main: "#93A1B5",
      light: "#D0DBEB",
    },
  },
  typography: FontStyle
});
// Customizable Area End
