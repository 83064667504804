import { Box, styled } from "@material-ui/core";
import  axios from 'axios';
import { DeltaStatic, Sources } from "quill";
import React, { CSSProperties, useRef, useState } from "react";
import ReactQuill, { ReactQuillProps, UnprivilegedEditor } from "react-quill";
import 'react-quill/dist/quill.snow.css';

// const formats = ["bold", "underline", "title", "align", "bullet" ];

const modules = {
    toolbar: [
        ['bold', 'underline'],
        ['link'],
        [{ header: 1 }],
        [{ align: [] }],
        [{ list: 'bullet' }],
        ['clean'],
    ],
    
}

interface RichTextEditorProps extends ReactQuillProps {
    id?: string;
    name?: string;
    required?: boolean;
    label?: string;
    labelStyle?: CSSProperties;
    bgColor?: string;
    errorText?: string;
}

const RichTextEditor = (props: RichTextEditorProps) => {
    const {  id, required, label, labelStyle, bgColor, errorText, value, onChange, ...richTextEditorProps } = props;

    const quillRef = useRef<ReactQuill>(null);

    // const [error, setError] = useState(errorText);
    // const [selection, setSelection] = useState(null);
    // const [suggestions, setSuggestions] = useState([]);
    // const [suggestionsShown, setSuggestionsShown] = useState(false);
    // const [suggestionBoxPosition, setSuggestionBoxPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });

    const checkSpelling = async (content: string) => {
        try {
            const response = await axios.get('https://api.textgears.com/spelling', {
                params: {
                    key: 'yJ85cVqAAMVkya7O',
                    text: content
                }
            });
    
            const suggestions1 = response.data.response.errors.map((error: any) => ({
                word: error.bad,
                suggestions: error.better,
            }));

            // setSuggestions(suggestions1);
            return suggestions1;
        } catch (error) {
            console.log(error)
        }
    }
    const handleSelectionChange = (range: any) => {
        if (quillRef && range && range.length > 0) {
          const quill = quillRef?.current?.getEditor();
          if (quill) {
            const selectedText = quill.getText(range.index, range.length);
            checkSpelling(selectedText);
      
            // const bounds = quill.getBounds(range.index, range.length);
      
            // const suggestionBoxPosition = {
            //   top: bounds.top + bounds.height + window.scrollY + 290,
            //   left: bounds.left + window.scrollX + 150
            // };
            // setSelection(range);
            // setSuggestionsShown(true);
            // setSuggestionBoxPosition(suggestionBoxPosition)
          }
        // } else {
        //     setSuggestionsShown(false);
        // }
        }
    }
    // const handleChange = (
    //     value: string,
    //     deltaOp: DeltaStatic,
    //     sources: Sources,
    //     editor: UnprivilegedEditor,
    // ) => {
    //     const characterCount = editor.getLength() - 1;

    //     console.log(characterCount);
        
    //     // you can also choose to assign the character count with React useState like so:
    //     // setCount(characterCount)

    //     if (characterCount < 500) {
    //         setError(undefined);
    //         onChange && onChange(value, deltaOp, sources, editor);
    //     } else {
    //         setError("must be less than 50 characters");
    //     }
    // }
    return (
        <Container>
            {label && (
                <Label 
                    htmlFor={id} 
                    role="alert" 
                    style={labelStyle}
                >
                    {label} {required && <span style={{ fontSize: '8px' }}>(Required <span style={{ color: "#DC2626" }}>*</span>)</span>}
                </Label>)}
            <Box id="quill-container" onContextMenu={handleSelectionChange} style={{ backgroundColor: "#FFF" }}>
                {/* {open && (
                    <ModalBackdrop>
                        <ModalPanel>
                            <ReactQuill
                                ref={quillRef}                                
                                formats={formats}
                                modules={{ clipboard:{ matchVisual: false } }}
                                onChangeSelection={handleSelectionChange}
                                value={value}
                                onChange={onChange}
                                style={{ flexGrow: 1 }}
                                // className="modalTextEditor"
                            />
                            {suggestionsShown && (
                                <SuggestionBox style={{ top: suggestionBoxPosition.top, left: suggestionBoxPosition.left }}>
                                    <List>
                                    {suggestions.map((each: any, index: number) => (
                                        <>
                                            {each?.suggestions.map((suggestion: any, idx: number) => (
                                                <ListItem
                                                    key={index}
                                                    style={{
                                                        padding: '8px 12px',
                                                        // cursor: 'pointer',
                                                        borderBottom: '1px solid #eee'
                                                    }}
                                                    onMouseDown={() => {}}
                                                >
                                                    {suggestion}
                                                </ListItem>
                                            ))}
                                        </>
                                    ))}
                                    </List>
                                </SuggestionBox>
                            )}
                            <ModalFooter>
                                <Button variant="outline" onClick={() => setOpen(false)} style={{ borderRadius: '28.34px' }}>Cancel</Button>
                                <Button onClick={() => setOpen(false)} style={{ borderRadius: '28.34px' }}>Save</Button>
                            </ModalFooter>
                        </ModalPanel>
                    </ModalBackdrop>
                )} */}
                <ReactQuill
                    ref={quillRef}
                    // formats={formats}
                    modules={modules}
                    bounds="#quill-container"
                    style={{ backgroundColor: bgColor, border: !!errorText ? '1px solid #DC2626' : '' }}
                    value={value}
                    onChange={onChange}
                    {...richTextEditorProps}
                />
            </Box>
            {errorText && <HintText>{errorText}</HintText>}
        </Container>
    )
}


export default RichTextEditor

const Container = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '100%',
    "& .ql-container": {
        borderRadius: '8px',
        height: "174px",
        border: 'none'
    },
    '& .quill': {
        position: 'relative',
        borderRadius: '8px',
        paddingBottom: 55,
        border: `1px solid ${theme.palette.info.dark}`,
        // borderTop: `1px solid ${theme.palette.info.dark} !important`
    },
    "& .ql-toolbar": {
        borderRadius: '8px',
        border: `none`,
        position: "absolute",
        bottom: "10px",
        left: 10,
        width: "fit-content",
        transform: "translateY",
        zIndex: 1000,
        '& .ql-formats': {
        marginRight: 0
        },
        // '& .ql-formats:first-child': {
        //   display: 'none'
        // },
        '& .ql-formats button, & .ql-formats .ql-picker': {
        border: '1px solid #94A3B8',
        marginRight: 2
        },
    },
    "& .ql-editor": {
        background: theme.palette.background.default,
        color: theme.palette.secondary.main,
        borderRadius: 8,
        fontSize: '14px',
        fontFamily: 'Rubik'
    },
    "& .ql-editor.ql-blank::before": {
        color: theme.palette.info.light,
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontSize: '14px'
    },
}))
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const HintText = styled('p')({
    display: 'inline-block',
    marginTop: '2px',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#DC2626'
})